<template>
  <modal-component
    :dialog="dialog"
    @onClose="closePreviewDialog"
    :modalWidth="'440px'"
    :adjustHeight="'calc(100vh + 1699px);'"
  >
    <div class="pl-4 pr-4 pt-8">
      <div class="text-h6 primaryA--text font-weight-bold mb-4">
        {{
          $t("previewSupplierNotesModal.basicInformation", {
            name: supplier && supplier.name,
          })
        }}
      </div>
      <div>
        <div class="d-flex flex-column align-start">
          <span class="text-body-2 font-weight-bold mb-1">{{
            $t("previewSupplierNotesModal.timeForOrdering")
          }}</span>
          <span>{{ getNotes && getNotes.timeToOrder }}</span>
        </div>
        <v-divider class="mt-3 mb-3"></v-divider>
        <div class="d-flex flex-column align-start">
          <span class="text-body-2 font-weight-bold mb-1">{{
            $t("previewSupplierNotesModal.timeForDelivery")
          }}</span>
          <span>{{ getNotes && getNotes.timeToDeliver }}</span>
        </div>
        <v-divider class="mt-3 mb-3"></v-divider>
        <div class="d-flex flex-column align-start">
          <span class="text-body-2 font-weight-bold mb-1">{{
            $t("previewSupplierNotesModal.workingDays")
          }}</span>
          <div>
            <v-chip
              v-for="day in daysInWeek"
              :key="day.id"
              :color="day.color"
              >{{ day.label }}</v-chip
            >
          </div>
        </div>
        <v-divider class="mt-3 mb-3"></v-divider>
        <div
          v-if="getNotes && getNotes.note"
          class="d-flex flex-column align-start"
        >
          <span class="text-body-2 font-weight-bold mb-1">{{
            $t("previewSupplierNotesModal.additionalNote")
          }}</span>
          <span>{{ getNotes && getNotes.note }}</span>
        </div>
        <v-divider
          v-if="getNotes && getNotes.note"
          class="mt-3 mb-3"
        ></v-divider>
      </div>
      <div>
        <v-checkbox
          v-model="showNoMore"
          @change="handleShowNoMoreChange"
          :label="$t('previewSupplierNotesModal.showNoMore')"
        ></v-checkbox>
      </div>
      <v-btn color="accent" @click="closePreviewDialog">{{
        $t("common.close")
      }}</v-btn>
    </div>
  </modal-component>
</template>
<script>
// components
import ModalComponent from "../../../components/ModalComponent.vue";
import LocalStorageService from "../../../services/local-storage.service";
export default {
  name: "PreviewNotesModal",
  components: {
    ModalComponent,
  },
  props: {
    dialog: {},
    supplier: {},
    allSupplierNotes: {},
  },
  data: () => ({
    showDialog: false,
    daysInWeek: [],
    showNoMore: false,
  }),
  created() {
    this.showDialog = this.dialog;
  },
  mounted() {
    const showInfo = LocalStorageService.getLocalStorageData(
      "showNoMorePreviewSupplierNotes"
    );
    this.showNoMore = showInfo;
    this.processNotesData();
    this.daysInWeek = [
      {
        id: 0,
        label: this.$t("daysInWeekShort.monday"),
        value: "monday",
        color: "tertiaryDark",
        selected: false,
      },
      {
        id: 1,
        label: this.$t("daysInWeekShort.tuesday"),
        value: "tuesday",
        color: "tertiaryDark",
        selected: false,
      },
      {
        id: 2,
        label: this.$t("daysInWeekShort.wednesday"),
        value: "wednesday",
        color: "tertiaryDark",
        selected: false,
      },
      {
        id: 3,
        label: this.$t("daysInWeekShort.thursday"),
        value: "thursday",
        color: "tertiaryDark",
        selected: false,
      },
      {
        id: 4,
        label: this.$t("daysInWeekShort.friday"),
        value: "friday",
        color: "tertiaryDark",
        selected: false,
      },
      {
        id: 5,
        label: this.$t("daysInWeekShort.saturday"),
        value: "saturday",
        color: "tertiaryDark",
        selected: false,
      },
      {
        id: 6,
        label: this.$t("daysInWeekShort.sunday"),
        value: "sunday",
        color: "tertiaryDark",
        selected: false,
      },
    ];
  },
  methods: {
    closePreviewDialog() {
      this.$emit("onClose");
    },
    processNotesData() {
      if (this.allSupplierNotes) {
        const daysTemp = JSON.parse(this.allSupplierNotes.daysInWeek);
        // ovo nekad dodje kao string
        this.daysInWeek.forEach((day) => {
          daysTemp.forEach((dayTemp) => {
            if (dayTemp.id === day.id) {
              this.$set(day, "color", "accent");
              this.$set(day, "selected", true);
            }
          });
        });
      }
    },
    handleShowNoMoreChange(event) {
      this.showNoMore = event;
      LocalStorageService.setLocalStorageData(
        "showNoMorePreviewSupplierNotes",
        event ? "true" : "false"
      );
    },
  },
  computed: {
    getIsMobile() {
      return this.$isMobile;
    },
    getNotes() {
      return this.allSupplierNotes;
    },
  },
  watch: {
    dialog() {
      this.showDialog = this.dialog;
      this.processNotesData();
    },
  },
};
</script>
<style scoped lang="scss">
.dialog-style {
  background-color: #ffffff;
  padding: 24px;
  @media screen and (max-width: 959px) {
    height: 100%;
  }
}
.close-icon {
  cursor: pointer;
}
</style>
