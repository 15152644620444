import Vue from "vue";
import VueRouter from "vue-router";
import WelcomeView from "../views/WelcomeView.vue";
import Dashboard from "../views/Dashboard.vue";
import CurrentOrders from "../views/CurrentOrders/CurrentOrders.vue";
import NewOrder from "../views/NewOrder.vue";
import ArticlesDashboard from "../views/Articles/ArticlesDashboard.vue";
import SuppliersPage from "../views/SuppliersPage.vue";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "Welcome",
      component: WelcomeView,
      meta: { title: 'Welcome Page' }
    },
    {
      path: "/dashboard",
      name: "Dashboard",
      component: Dashboard,
      meta: { title: 'Dashboard Page' }
    },
    {
      path: "/:departmentID/current-orders",
      name: "CurrentOrders",
      component: CurrentOrders,
      meta: { title: 'Current Orders Page' }
    },
    {
      path: "/:departmentID/new-order",
      name: "NewOrder",
      component: NewOrder,
      meta: { title: 'New Order Page' }
    },
    {
      path: "/articles",
      name: "ArticlesDashboard",
      component: ArticlesDashboard,
      meta: { title: 'Articles Page' }
    },
    {
      path: "/suppliers",
      name: "SuppliersPage",
      component: SuppliersPage,
      meta: { title: 'Suppliers Page' }
    },
  ],
});
router.afterEach((to, from) => {
  // This will be called after each route change
  Vue.nextTick(() => {
    document.title = to.meta.title || 'SupplyMe Demo'; // Set title
    Vue.$ga.page({ 
      page: to.path,
      title: to.meta.title || 'SupplyMe Demo'
    });
  });
});

export default router;
