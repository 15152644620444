var render = function render(){var _vm=this,_c=_vm._self._c;return _c('modal-component',{attrs:{"dialog":_vm.showDialog,"modalWidth":'540px'},on:{"onClose":function($event){return _vm.$emit('onClose')}}},[(_vm.data)?_c('div',{staticClass:"preview-modal"},[_c('div',{staticStyle:{"text-align":"left"}},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t("common.listProducts"))+" ")]),(_vm.isPartiallyDelivered)?_c('div',{staticClass:"mt-3 mb-3"},[_c('v-alert',{attrs:{"text":"","dense":"","color":"blue","elevation":"0","type":"info"}},[_vm._v(_vm._s(_vm.$t("common.partiallyDeliveredNote")))])],1):_vm._e(),_c('div',{staticClass:"text-body-2 mt-3"},[_vm._v(" "+_vm._s(_vm.$t("common.supplier"))+": "),_c('strong',[_vm._v(_vm._s(_vm.data.isCustomOrder ? _vm.$t("articles.market") : _vm.data.supplier.name))])]),_c('div',{staticClass:"text-body-2"},[_vm._v(" "+_vm._s(_vm.$t("common.dateForDelivering"))+": "),_c('strong',[_vm._v(_vm._s(_vm.getFormattedDate(_vm.data.deadline)))])]),_c('div',{staticClass:"text-body-2 mt-1 d-flex"},[_vm._v(" "+_vm._s(_vm.$t("common.orderStatus"))+": "),_c('span',{staticClass:"status-tag",style:({
            background: _vm.getOrderStatusColor(_vm.data && _vm.data.status),
          })},[_c('strong',[_vm._v(_vm._s(_vm.getOrderStatusText(_vm.data && _vm.data.status)))])])])]),_c('v-simple-table',{staticClass:"mt-3",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t("common.name")))]),_c('th',[_vm._v(_vm._s(_vm.$t("common.qty")))]),(_vm.data.status !== 'OPEN')?_c('th',[_vm._v(" "+_vm._s(_vm.$t("common.statusItem"))+" ")]):_vm._e()])]),_c('tbody',_vm._l((_vm.data.products),function(item){return _c('tr',{key:item.id,staticStyle:{"text-align":"left"}},[(item.note)?_c('td',[_c('div',{staticClass:"d-flex flex-column align-start"},[_c('span',[_vm._v(_vm._s(item.name))]),_c('span',{staticClass:"text-caption secondaryDark2--text"},[_vm._v(_vm._s(_vm.$t("currentOrders.orderNote"))+" "+_vm._s(item.note))])])]):_c('td',[_vm._v(_vm._s(item.name))]),_c('td',[(item.deliveredAmount)?_c('span',{staticClass:"text-caption"},[_vm._v("x "+_vm._s(item.deliveredAmount)+" ("+_vm._s(_vm.$t("currentOrders.partiallyOrdered", { num: item.amount }))+")")]):_c('span',[_vm._v("x "+_vm._s(item.amount)+" ")])]),(_vm.data.status !== 'OPEN')?_c('td',[_c('span',{style:({
                  color: _vm.getItemColor(item.status),
                })},[_vm._v(" "+_vm._s(_vm.getItemStatusText(item.status))+" ")]),(item.move_to_order_id)?_c('span',{staticClass:"cursor-pointer font-weight-bold text-decoration-underline",style:({
                  color: _vm.getItemColor(item.status),
                }),on:{"click":function($event){return _vm.goToOrder(item.move_to_order_id)}}},[_vm._v("#"+_vm._s(item.move_to_order_id.toUpperCase()))]):_vm._e()]):_vm._e()])}),0)]},proxy:true}],null,false,228745778)}),_c('v-divider'),(_vm.data.orderNote)?_c('span',{staticClass:"d-flex justify-start text-body-2 mt-4"},[_vm._v(_vm._s(_vm.$t("currentOrders.orderNote"))+": "),_c('span',{staticClass:"ml-1 order-created-date-text"},[_c('strong',[_vm._v(_vm._s(_vm.data.orderNote))])])]):_vm._e(),(!_vm.data.isCustomOrder)?_c('span',{staticClass:"d-flex justify-start text-body-2 mt-3"},[_vm._v(_vm._s(_vm.$t("newOrder.paymentMetod"))+": "),_c('span',{staticClass:"ml-1 order-created-date-text"},[_c('strong',[_vm._v(_vm._s(_vm.getPaymentMethodText(_vm.data.paymentMethod)))])])]):_vm._e(),(_vm.data)?_c('div',{staticClass:"mt-4 d-flex flex-column align-start",staticStyle:{"text-align":"left"}},[_c('span',{staticClass:"order-created-text text-body-2"},[_vm._v(_vm._s(_vm.$t("dashboard.createdBy"))+" "),_c('span',{staticClass:"order-created-date-text"},[_c('strong',[_vm._v(_vm._s(_vm.data.createdBy.name))]),_vm._v(" - "),_c('span',[_vm._v(_vm._s(_vm._f("moment")(_vm.data.createdAt,"DD.MM.YYYY")))]),_vm._v(" u "),_c('span',[_vm._v(_vm._s(_vm._f("moment")(_vm.data.createdAt,"HH:mm")))])])]),_c('span',{staticClass:"order-created-text text-body-2"},[_vm._v(_vm._s(_vm.$t( _vm.data.status === "COMPLETED" ? "dashboard.receivedBy" : "dashboard.lastUpdatedBy" ))+" "),_c('span',{staticClass:"order-created-date-text"},[_c('strong',[_vm._v(_vm._s(_vm.data.lastUpdatedBy.name))]),_vm._v(" - "),_c('span',[_vm._v(_vm._s(_vm._f("moment")(_vm.data.lastUpdatedAt,"DD.MM.YYYY")))]),_vm._v(" u "),_c('span',[_vm._v(_vm._s(_vm._f("moment")(_vm.data.lastUpdatedAt,"HH:mm")))])])])]):_vm._e()],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }