const suppliersForDepartmentOne = [
  {
    id: "5716a290-f009-4331-a641-949a14a71df2",
    name: "Dobavljač 1",
    pib: "123123222",
    address: "Test Adresa 11",
    isMarket: false,
    supplierNotes: {
      id: "4a9b3864-efb9-45ce-a657-74ca1f67049d",
      updatedAt: "2023-12-12T20:34:12.972Z",
      timeToOrder: "10:00",
      timeToDeliver: "15:00",
      daysInWeek:
        '[{"id":0,"label":"Pon","value":"monday","color":"accent","selected":true},{"id":1,"label":"Uto","value":"tuesday","color":"accent","selected":true}]',
      note: "Test napomena",
    },
    contacts: [
      {
        id: "51f5f756-f51d-447e-9d34-33da4571c0bb",
        updatedAt: "2023-06-14T19:57:57.025Z",
        name: "Contact 1",
        email: "contact1@mail.com",
        phone: "641234567",
        supplier: {
          id: "5716a290-f009-4331-a641-949a14a71df2",
          name: "Dobavljač 1",
          pib: "123123222",
          address: "Test Adresa 11",
          isMarket: false,
        },
      },
    ],
  },
  {
    id: "3d927cf4-18cf-48e0-a850-78c23859f56a",
    name: "Dobavljač 2",
    pib: "0987654321",
    address: "Test Adresa 12",
    supplierNotes: {
      id: "4a9b3864-efb9-123-4444-55sasa22232",
      updatedAt: "2024-01-12T20:34:12.972Z",
      timeToOrder: "12:00",
      timeToDeliver: "16:00",
      daysInWeek:
        '[{"id":1,"label":"Uto","value":"monday","color":"accent","selected":true},{"id":2,"label":"Sre","value":"tuesday","color":"accent","selected":true}]',
      note: "Test",
    },
    contacts: [
      {
        id: "51f5f756-f51d-447e-9d34-33da4571c0dd",
        updatedAt: "2023-06-14T19:57:57.025Z",
        name: "Contact 2",
        email: "contact2@mail.com",
        phone: "64987654",
        supplier: {
          id: "3d927cf4-18cf-48e0-a850-78c23859f56a",
          name: "Dobavljač 2",
          pib: "0987654321",
          address: "Test Adresa 12",
          isMarket: false,
        },
      },
    ],
    isMarket: false,
  },
  {
    id: null,
    name: "Magacin",
    pib: null,
    address: null,
    isMarket: true,
    supplierNotes: {},
  },
];

const suppliersForDepartmentTwo = [
  {
    id: "5716a290-f009-4331-a641-949a14a71df2",
    name: "Dobavljač 1",
    pib: "123123222",
    address: "Test Adresa 11",
    isMarket: false,
    supplierNotes: {
      id: "4a9b3864-efb9-45ce-a657-74ca1f67049d",
      updatedAt: "2023-12-12T20:34:12.972Z",
      timeToOrder: "00:00",
      timeToDeliver: "00:00",
      daysInWeek:
        '[{"id":0,"label":"Pon","value":"monday","color":"accent","selected":true},{"id":2,"label":"Sre","value":"wednesday","color":"accent","selected":true}]',
      note: "Test napomena",
    },
    contacts: [
      {
        id: "51f5f756-f51d-447e-9d34-33da4571c0bb",
        updatedAt: "2023-06-14T19:57:57.025Z",
        name: "Contact 1",
        email: "contact1@mail.com",
        phone: "641234567",
        supplier: {
          id: "5716a290-f009-4331-a641-949a14a71df2",
          name: "Dobavljač 1",
          pib: "123123222",
          address: "Test Adresa 11",
          isMarket: false,
        },
      },
    ],
  },
  {
    id: null,
    name: "Magacin",
    pib: null,
    address: null,
    isMarket: true,
    supplierNotes: {},
  },
];

const allSuppliersCategories = [
  {
    supplierId: "3d927cf4-18cf-48e0-a850-78c23859f56a",
    categories: [
      {
        id: "95527dc5-8687-485d-999b-36a95a6a7b61",
        category: "Voće i povrće",
      },
      {
        id: "e8870346-691a-4b7c-8d43-580e2adcb234",
        category: "Konzervirani proizvodi",
      },
    ],
  },
  {
    supplierId: "5716a290-f009-4331-a641-949a14a71df2",
    categories: [
      {
        id: "95527dc5-8687-485d-999b-36a95a6a7b61",
        category: "Voće i povrće",
      },
      {
        id: "27fa7b70-3deb-4709-bc80-72f60dbed02d",
        category: "Bezalkoholna piće",
      },
    ],
  },
];
const marketCategories = [
  {
    id: "95527dc5-8687-485d-999b-36a95a6a7b61",
    category: "Voće i povrće",
  },
  {
    id: "27fa7b70-3deb-4709-bc80-72f60dbed02d",
    category: "Bezalkoholna piće",
  },
  {
    id: "e8870346-691a-4b7c-8d43-580e2adcb234",
    category: "Konzervirani proizvodi",
  },
];

export default {
  suppliersForDepartmentOne,
  suppliersForDepartmentTwo,
  allSuppliersCategories,
  marketCategories,
};
