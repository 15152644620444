<template>
  <div class="text-center">
    <v-dialog
      v-model="showDialog"
      width="626"
      class="dialog-style"
      persistent
      :fullscreen="getIsMobile"
    >
      <div class="dialog-style">
        <div class="header">
          <div id="step-10" class="d-flex justify-space-between align-center">
            <span class="text-h5 primaryA--text font-weight-bold">{{
              $t("receiveOrderDialog.title", {
                category: orderReceiveObject.category,
                supplier: orderReceiveObject.isCustomOrder
                  ? $t("articles.market")
                  : orderReceiveObject.supplier
                  ? orderReceiveObject.supplier.name
                  : $t("articles.market"),
              })
            }}</span>
            <v-img
              @click="closeDialog"
              class="close-icon"
              max-width="20px"
              :src="require('../assets/img/CloseIcon.svg')"
            >
            </v-img>
          </div>
          <div class="mb-6">
            <span class="accent--text text-subtitle-1 font-weight-bold"
              >{{ $t("receiveOrderDialog.expectedOrderDate") }}:</span
            >
            <span class="accent--text text-subtitle-1 font-weight-bold">
              {{ orderReceiveObject.deadline | moment("DD.MM.YYYY") }}
            </span>
          </div>
        </div>
        <v-divider></v-divider>
        <div>
          <div class="pt-5" v-for="article in orderProducts" :key="article.id">
            <div class="d-flex align-center">
              <span
                class="text-subtitle-1 primaryA--text font-weight-bold mr-2"
                >{{ article.name }}</span
              >

              <span class="ml-1 text-subtitle-2 secondaryDark--text"
                >x {{ article.amount }}</span
              >
            </div>
            <span v-if="article.note">
              {{ $t("currentOrders.orderNote") }}: {{ article.note }}</span
            >
            <div>
              <!-- for regular screens -->
              <v-radio-group
                class="d-flex"
                v-model="article.status"
                row
                @change="resolutionChange($event, article)"
                :disabled="article.move_to_order_id !== null"
              >
                <v-radio
                  id="step-11"
                  :label="$t('receiveOrderDialog.arrived')"
                  value="DELIVERED"
                ></v-radio>
                <v-radio
                  id="step-12"
                  :label="$t('receiveOrderDialog.notArrived')"
                  value="NOT_DELIVERED"
                ></v-radio>
                <v-radio
                  id="step-13"
                  :label="$t('receiveOrderDialog.partiallyArrived')"
                  value="PARTIALLY_ARRIVED"
                ></v-radio>
              </v-radio-group>
            </div>
            <div
              v-if="
                article.orderResolutionStatus === 'NOT_DELIVERED' &&
                !orderReceiveObject.isCustomOrder
              "
              class="d-flex"
            >
              <v-select
                :value="article.notDeliveredReason"
                class="select-width"
                :items="resolutionOptions"
                item-value="value"
                item-text="text"
                outlined
                filled
                color="accent"
                :label="$t('receiveOrderDialog.chooseOption')"
                persistent-hint
                dense
                @change="onResolutionSelected($event, article)"
                :disabled="article.move_to_order_id !== null"
                :item-disabled="(item) => item.disabled"
              ></v-select>
            </div>
            <div
              v-if="article.orderResolutionStatus === 'PARTIALLY_ARRIVED'"
              class="d-flex"
            >
              <v-text-field
                class="select-width"
                name="qtyArrived"
                :label="$t('receiveOrderDialog.amountDelivered')"
                id="qtyArrived"
                type="text"
                :placeholder="$t('receiveOrderDialog.enterDeliveredAmount')"
                outlined
                dense
                color="accent"
                v-model="article.deliveredAmount"
                @keydown="nameKeydown($event)"
                @input="validateInput($event, article)"
                :rules="article.amountError"
                width="200px"
              ></v-text-field>
              <tooltip-component
                :icon="'info'"
                :color="'accent'"
                :text="$t('receiveOrderDialog.deliveredAmountTooltip')"
                :className="'mt-2 ml-2'"
              ></tooltip-component>
            </div>
            <v-divider></v-divider>
          </div>
        </div>
        <div
          v-if="orderReceiveObject.orderNote"
          class="d-flex flex-column align-start mt-3"
        >
          <span class="text-subtitle-2 primaryA--text"
            >{{ $t("previewSupplierNotesModal.additionalNote") }}:</span
          >
          <span class="text-subtitle-2 secondaryDark2--text">{{
            orderReceiveObject.orderNote
          }}</span>
        </div>
        <div>
          <div class="d-flex mt-4 justify-space-between align-center flex-wrap">
            <div class="d-flex mobile-arrange-buttons">
              <v-btn
                id="step-14"
                s
                color="tertiaryDark"
                @click="onAllArticlesArrived"
                >{{ $t("receiveOrderDialog.allArticlesArrived") }}</v-btn
              >
              <v-btn
                class="ml-4"
                :disabled="!isFormValid"
                :color="someOrdersNotArriveOrArriveLater ? 'warning' : 'accent'"
                @click="resolveOrder"
                >{{ $t("receiveOrderDialog.acceptOrder") }}</v-btn
              >
            </div>
            <div class="d-flex mobile-arrange-buttons mobile-margin-adjust">
              <v-btn @click="onCancel">{{
                $t("receiveOrderDialog.cancel")
              }}</v-btn>
            </div>
          </div>
          <div class="info-text mt-2">
            <span
              class="text-subtitle-2 secondaryDark--text"
              v-if="allOrdersArrived"
            >
              {{ $t("receiveOrderDialog.allOrdersArrived") }}
            </span>
            <span
              class="text-subtitle-2 secondaryDark--text"
              v-if="someOrdersNotArriveOrArriveLater"
            >
              {{ $t("receiveOrderDialog.orderNotComplete") }}
            </span>
          </div>
        </div>
      </div>
    </v-dialog>
    <v-dialog
      v-model="showWarningModal"
      persistent
      :fullscreen="getIsMobile"
      width="33vw"
      class="dialog-style-warning"
    >
      <div class="dialog-style-warning">
        <span
          >{{ $t("receiveOrderDialog.allProductsArrivedWarning") }}
          <strong>{{ $t("receiveOrderDialog.arrivedWarning") }}</strong></span
        >
        <div class="d-flex mt-7 justify-center">
          <v-btn color="accent" @click="onConfirmWarning">{{
            $t("common.confirm")
          }}</v-btn>
          <v-btn class="ml-4" @click="onCancelWarning">{{
            $t("common.cancel")
          }}</v-btn>
        </div>
      </div>
    </v-dialog>
    <v-tour
      name="myTourReceiveOrders"
      :steps="steps"
      :options="myOptions"
      :callbacks="myCallbacks"
    ></v-tour>
  </div>
</template>
<script>
import _ from "lodash";
import TooltipComponent from "../components/TooltipComponent.vue";
// constants
import { orderStatuses } from "../shared/statuses";
import { mapGetters } from "vuex";
import LocalStorageService from "../services/local-storage.service";
export default {
  name: "ReceiveOrderDialog",
  components: { TooltipComponent },
  props: { dialog: {}, orderReceiveObject: {}, venueId: {}, stationID: {} },
  data: () => ({
    showDialog: false,
    resolutionOptionsSelect: [],
    showWarningModal: false,
    resolutionOptions: [],
    todayDate: "",
    orderProducts: [],
    rules: [],
    invalidInputForm: false,
    steps: [],
    myOptions: {},
    myCallbacks: {},
  }),
  created() {
    this.loadTourSteps();
    this.todayDate = new Date().getTime();
    this.todayDate = new Date(this.todayDate).toISOString();
    this.showDialog = this.dialog;
    this.resolutionOptionsSelect = [
      {
        id: 0,
        value: orderStatuses.DELIVERED,
        text: this.$t("receiveOrderDialog.arrived"),
      },
      {
        id: 1,
        value: orderStatuses.NOT_DELIVERED,
        text: this.$t("receiveOrderDialog.notArrived"),
      },
    ];
    this.resolutionOptions = [
      {
        id: 0,
        value: orderStatuses.MOVE_TO_NEW,
        text: this.$t("receiveOrderDialog.moveToNewOrder"),
        disabled: true,
      },
      {
        id: 1,
        value: "NOT_AVAILABLE",
        text: this.$t("receiveOrderDialog.ignoreOrder"),
        disabled: false,
      },
      {
        id: 2,
        value: "ARRIVE_LATER",
        text: this.$t("receiveOrderDialog.arriveLater"),
        disabled: true,
      },
    ];
    this.myCallbacks = { onSkip: this.handleOnSkip };
  },
  mounted() {
    if (this.orderReceiveObject.products) {
      const products = _.cloneDeep(this.orderReceiveObject.products);
      this.orderProducts = products;
    }
    setTimeout(() => {
      const isDisabled =
        LocalStorageService.getLocalStorageData("tourDisabled");
      this.$store.dispatch("allState/disableTour", isDisabled);
      if (isDisabled) {
        this.$tours["myTourReceiveOrders"].stop();
      } else {
        this.$tours["myTourReceiveOrders"].start();
      }
    }, 200);
  },
  methods: {
    nameKeydown(e) {
      if (e.key === ",") {
        e.preventDefault();
      }
    },
    validateInput(event, article) {
      if (this.orderProducts?.length > 0) {
        this.orderProducts.forEach((product) => {
          if (
            product.deliveredAmount &&
            product.deliveredAmount.startsWith(".")
          ) {
            product.deliveredAmount = "0" + product.deliveredAmount;
          }
          const valueValidated =
            product.deliveredAmount !== null
              ? this.validate(product.deliveredAmount)
              : true;
          const valueEnterredFloat = parseFloat(product.deliveredAmount);

          if (!valueValidated || valueEnterredFloat < 0.1) {
            this.invalidInputForm = true;
            this.$set(product, "amountError", [""]);
          } else {
            this.invalidInputForm = false;
            this.$set(product, "amountError", []);
          }
        });
      }
      if (
        article.status === orderStatuses.PARTIALLY_ARRIVED &&
        (article.deliveredAmount === null ||
          article.deliveredAmount === "" ||
          article.amountError.length > 0)
      ) {
        this.invalidInputForm = true;
        this.$set(article, "amountError", [""]);
      }
    },
    validate(s) {
      let rgx = /^[0-9]*\.?[0-9]*$/;
      return rgx.test(s);
    },
    closeDialog() {
      this.orderProducts = this.orderReceiveObject.products;
      this.$emit("onClose");
    },
    resolveOrder() {
      const { productsDeepCopy } = this.processSelectedArticles();

      productsDeepCopy.forEach((p) => {
        if (p.status === orderStatuses.PARTIALLY_ARRIVED) {
          this.$set(p, "status", orderStatuses.DELIVERED);
        }
        if (p.deliveredAmount === "0.00") {
          delete p.deliveredAmount;
        }
      });
      this.$store.dispatch(
        this.orderReceiveObject.isCustomOrder
          ? "allState/completeCustomOrder"
          : "allState/completeOrder",
        {
          departmentId: this.stationID,
          orderId: this.orderReceiveObject.id,
          products: productsDeepCopy,
          order: this.orderReceiveObject,
        }
      );
      window.gtag("event", "received_order", {
        event_category: "Orders",
        event_label: "Receive order",
      });
      this.$emit("allOrdersArrived");
    },
    resolutionChange(event, value) {
      this.$set(value, "orderResolutionStatus", event);
    },
    onAllArticlesArrived() {
      this.showWarningModal = true;
    },
    onConfirmWarning() {
      this.orderProducts.forEach((product) => {
        this.$set(product, "status", orderStatuses.DELIVERED);
      });
      this.resolveOrder();
    },
    onCancelWarning() {
      this.showWarningModal = false;
    },
    onResolutionSelected(event, article) {
      switch (event) {
        case orderStatuses.MOVE_TO_NEW:
          this.$set(article, "notDeliveredReason", orderStatuses.MOVE_TO_NEW);
          break;
        case "NOT_AVAILABLE":
          this.$set(article, "notDeliveredReason", "NOT_AVAILABLE");
          break;
        case "ARRIVE_LATER":
          this.$set(article, "notDeliveredReason", "ARRIVE_LATER");
          break;
      }
    },
    processSelectedArticles() {
      let moveArticlesToNew = [];
      let ignoreAndInformArticles = [];
      let arriveLaterArticles = [];
      let productsDeepCopy = _.cloneDeep(this.orderProducts);
      productsDeepCopy.forEach((product) => {
        switch (product.notDeliveredReason) {
          case orderStatuses.MOVE_TO_NEW:
            moveArticlesToNew.push(product);
            break;
          case "NOT_AVAILABLE":
            ignoreAndInformArticles.push(product);
            break;
          case "ARRIVE_LATER":
            if (product.orderResolutionStatus === orderStatuses.DELIVERED) {
              this.$set(product, "notDeliveredReason", "");
            } else {
              this.$set(product, "status", orderStatuses.INCOMPLETE);
            }

            arriveLaterArticles.push(product);
            break;
        }
      });
      return {
        moveArticlesToNew,
        ignoreAndInformArticles,
        arriveLaterArticles,
        productsDeepCopy,
      };
    },
    onCancel() {
      this.orderProducts = this.orderReceiveObject.products;
      this.closeDialog();
    },
    updateOrder() {
      const order = {
        date: this.todayDate,
        orderNote: this.orderReceiveObject.orderNote,
        paymentMethod: this.orderReceiveObject.paymentMethod,
        articles: this.orderReceiveObject.products,
        supplier: this.orderReceiveObject.supplier,
      };

      this.$store
        .dispatch(
          this.orderReceiveObject.supplier.isMarket
            ? "orders/updateCustomOrder"
            : "orders/updateOrder",
          {
            orderId: this.orderReceiveObject.id,
            order: order,
          }
        )
        .then(() => this.$emit("onOrderArriveLaterUpdated"));
    },
    handleOnSkip() {
      this.$store.dispatch("allState/disableTour", true);
    },
    loadTourSteps() {
      this.steps = [
        {
          target: "#step-10",
          header: {
            title: this.$t("tourSteps.step10Title"),
          },
          content: this.$t("tourSteps.step10Content"),
        },
        {
          target: "#step-11",
          header: {
            title: this.$t("tourSteps.step11Title"),
          },
          content: this.$t("tourSteps.step11Content"),
        },
        {
          target: "#step-12",
          header: {
            title: this.$t("tourSteps.step12Title"),
          },
          content: this.$t("tourSteps.step12Content"),
        },
        {
          target: "#step-13",
          header: {
            title: this.$t("tourSteps.step13Title"),
          },
          content: this.$t("tourSteps.step13Content"),
        },
        {
          target: "#step-14",
          header: {
            title: this.$t("tourSteps.step14Title"),
          },
          content: this.$t("tourSteps.step14Content"),
        },
      ];
      this.myOptions = {
        useKeyboardNavigation: true,
        labels: {
          buttonSkip: this.$t("tourSteps.labelSkip"),
          buttonPrevious: this.$t("tourSteps.labelPrevious"),
          buttonNext: this.$t("tourSteps.labelNext"),
          buttonStop: this.$t("tourSteps.labelStop"),
        },
      };
    },
  },
  computed: {
    ...mapGetters({
      getIsTourDisabled: "allState/getIsTourDisabled",
      user: "allState/getUser",
    }),
    isFormValid() {
      return (
        this.orderProducts?.filter((f) => f.orderResolutionStatus).length ===
          this.orderProducts.length && !this.invalidInputForm
      );
    },
    allOrdersArrived() {
      return (
        this.orderProducts?.filter((f) => f.status === orderStatuses.DELIVERED)
          .length === this.orderProducts.length
      );
    },
    someOrdersNotArriveOrArriveLater() {
      let temp = false;
      this.orderProducts?.forEach((val) => {
        if (val.status === orderStatuses.NOT_DELIVERED) {
          temp = true;
        }
      });
      return temp;
    },
    getIsMobile() {
      return this.$isMobile;
    },
    someItemsArriveLAter() {
      return this.orderProducts?.find(
        (product) => product.notDeliveredReason === "ARRIVE_LATER"
      );
    },
    getIsInputInvalid() {
      return this.invalidInputForm;
    },
  },
  watch: {
    dialog() {
      this.showDialog = this.dialog;
      const products = _.cloneDeep(this.orderReceiveObject.products);
      this.orderProducts = products;
    },
    orderReceiveObject: {
      handler() {
        if (this.orderReceiveObject) {
          this.orderProducts.forEach((product) => {
            if (product.status === orderStatuses.NOT_DELIVERED) {
              product.orderResolutionStatus = orderStatuses.NOT_DELIVERED;
            }
            if (product.status === orderStatuses.DELIVERED) {
              product.orderResolutionStatus = orderStatuses.DELIVERED;
            }
            if (product.status === orderStatuses.INCOMPLETE) {
              product.orderResolutionStatus = orderStatuses.NOT_DELIVERED;
              product.status = orderStatuses.NOT_DELIVERED;
            }
            if (product.status === orderStatuses.MOVE_TO_NEW) {
              product.orderResolutionStatus = orderStatuses.NOT_DELIVERED;
              product.status = orderStatuses.NOT_DELIVERED;
            }
          });
        }
      },
      deep: true,
    },
    getIsTourDisabled() {
      setTimeout(() => {
        if (this.getIsTourDisabled) {
          this.$tours["myTourReceiveOrders"].stop();
        } else {
          this.$tours["myTourReceiveOrders"].start();
        }
      }, 200);
    },
    user: {
      handler() {
        this.loadTourSteps();
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.dialog-style {
  background-color: #ffffff;
  padding: 24px;
  min-height: 100%;
}
.dialog-style-warning {
  background-color: #ffffff;
  padding: 24px;
  height: 100%;
}
.close-icon {
  cursor: pointer;
}
.info-text {
  text-align: center;
}
.order-note-width {
  flex: 60%;
}
.amount-width {
  flex: 40%;
}
.select-width {
  max-width: 315px;
  @media screen and (max-width: 575px) {
    max-width: 60%;
  }
}
.mobile-arrange-buttons {
  @media screen and (max-width: 575px) {
    width: 100%;
    justify-content: center;
  }
}
.mobile-margin-adjust {
  @media screen and (max-width: 575px) {
    margin-top: 16px;
  }
}
</style>
