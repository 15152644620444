<template>
  <div class="padding-adjust">
    <div class="d-flex flex-column justify-start">
      <span class="text-h5 font-weight-bold primaryA--text">{{
        $t("suppliers.suppliers")
      }}</span>
      <div v-if="stations && stations.length > 0" class="d-flex mt-8">
        <div id="step-26" class="select-wrapper">
          <v-select
            :value="selectedStationId"
            class="select-width"
            :items="stations"
            item-value="id"
            item-text="name"
            outlined
            filled
            color="accent"
            :label="$t('dashboard.chooseStation')"
            persistent-hint
            dense
            @change="onStationSelected"
          ></v-select>
        </div>
      </div>
    </div>
    <div class="mt-11">
      <data-table
        v-if="allSuppliers && allSuppliers.length > 0"
        :headers="headers"
        :data="allSuppliers"
        :isDataLoading="isDataLoading"
        @onAddContact="handleOnAddContact"
        @onPreviewContacts="handleOnPreviewContacts"
        @onPreviewSupplierNotes="handleOnPreviewSupplierNotes"
        :mobileBreakpoint="600"
        :isAdmin="isAdmin"
        :isBasicPlan="isBasicPlan"
        :totalCount="getTotalCountSuppliers"
      ></data-table>
      <no-results-content
        v-else
        :noResultsText="$t('suppliers.noSuppliersAdded')"
        :page="'suppliers'"
      ></no-results-content>
    </div>
    <add-supplier-contact-modal
      :dialog="showAddSupplierContactModal"
      @onClose="handleCloseAddSupplierContactModal"
      :selectedSupplier="selectedSupplier"
      @onAddedSupplierContact="handleAddedSupplierContact"
    ></add-supplier-contact-modal>
    <preview-supplier-contacts-modal
      v-if="showPreviewContactsModal"
      :dialog="showPreviewContactsModal"
      @onClose="handleCloseSupplierContactsModal"
      :supplier="selectedSupplier"
      :isAdmin="isAdmin"
      :selectedStationId="selectedStationId"
    ></preview-supplier-contacts-modal>
    <preview-supplier-notes-modal
      v-if="showPreviewSupplierNotesModal"
      :dialog="showPreviewSupplierNotesModal"
      @onClose="onClosePreviewSupplierNotes"
      :supplier="selectedSupplier"
      :user="user"
      :venue="getVenue"
    ></preview-supplier-notes-modal>
    <v-tour
      name="myTourSuppliers"
      :steps="steps"
      :options="myOptions"
      :callbacks="myCallbacks"
      :myCallbacks="myCallbacks"
    ></v-tour>
  </div>
</template>
<script>
//vuex
import { mapGetters } from "vuex";
//Components
import NoResultsContent from "../components/NoResultsContent.vue";
import DataTable from "../components/DataTable.vue";
import AddSupplierContactModal from "../components/AddSupplierContactModal.vue";
import PreviewSupplierContactsModal from "../components/PreviewSupplierContactsModal.vue";
import PreviewSupplierNotesModal from "../components/PreviewSupplierNotesModal.vue";
//Event bus
import EventBus from "../shared/EventBus";
import LocalStorageService from "../services/local-storage.service";

export default {
  name: "SuppliersPage",
  components: {
    NoResultsContent,
    DataTable,
    AddSupplierContactModal,
    PreviewSupplierContactsModal,
    PreviewSupplierNotesModal,
  },
  props: { user: {} },
  data: () => ({
    headers: [],
    showAddSupplierContactModal: false,
    selectedSupplier: null,
    showPreviewContactsModal: false,
    showPreviewSupplierNotesModal: false,
    stationsOptions: [],
    selectedStationId: "d162cffb-acd5-4f8a-94fe-f5e026f99307",
    allSuppliers: [],
    steps: [],
    myOptions: {},
    myCallbacks: {},
  }),
  created() {
    this.loadTourSteps();
    this.headers = [
      { text: this.$t("common.pib"), value: "pib", sortable: false },
      {
        text: this.$t("suppliers.name"),
        value: "supplersName",
        sortable: false,
      },
      {
        text: this.$t("suppliers.address"),
        value: "address",
        sortable: false,
      },
      {
        text: this.$t("suppliers.contacts"),
        value: "add_contact",
        sortable: false,
      },
    ];
    this.myCallbacks = { onSkip: this.handleOnSkip };
  },
  mounted() {
    this.$store.dispatch("allState/getSuppliersForDepartment", {
      departmentId: this.selectedStationId,
    });
    setTimeout(() => {
      const isDisabled =
        LocalStorageService.getLocalStorageData("tourDisabled");
      this.$store.dispatch("allState/disableTour", isDisabled);
      if (isDisabled) {
        this.$tours["myTourSuppliers"].stop();
      } else {
        this.$tours["myTourSuppliers"].start();
      }
    }, 500);
    this.$store.dispatch("allState/getDepartments");
  },
  methods: {
    loadTourSteps() {
      this.steps = [
        {
          target: "#step-26",
          header: {
            title: this.$t("tourSteps.step26Title"),
          },
          content: this.$t("tourSteps.step26Content"),
        },
        {
          target: "#step-27",
          header: {
            title: this.$t("tourSteps.step27Title"),
          },
          content: this.$t("tourSteps.step27Content"),
        },
        {
          target: "#step-28",
          header: {
            title: this.$t("tourSteps.step28Title"),
          },
          content: this.$t("tourSteps.step28Content"),
        },
        {
          target: "#step-29",
          header: {
            title: this.$t("tourSteps.step29Title"),
          },
          content: this.$t("tourSteps.step29Content"),
        },
      ];
      this.myOptions = {
        useKeyboardNavigation: true,
        labels: {
          buttonSkip: this.$t("tourSteps.labelSkip"),
          buttonPrevious: this.$t("tourSteps.labelPrevious"),
          buttonNext: this.$t("tourSteps.labelNext"),
          buttonStop: this.$t("tourSteps.labelStop"),
        },
      };
    },
    handleOnSkip() {
      this.$store.dispatch("allState/disableTour", true);
    },
    handleAddedSupplierContact() {
      this.showAddSupplierContactModal = false;
      EventBus.dispatch("showSnackbar", {
        text: this.$t("notifications.addedContactSupplier"),
      });
      this.$store.dispatch("allState/getSuppliersForDepartment", {
        departmentId: this.selectedStationId,
      });
    },
    handleOnAddContact(event) {
      this.selectedSupplier = event;
      this.showAddSupplierContactModal = true;
    },
    handleCloseAddSupplierContactModal() {
      this.showAddSupplierContactModal = false;
    },
    handleOnPreviewContacts(event) {
      this.selectedSupplier = event;
      this.showPreviewContactsModal = true;
    },
    handleCloseSupplierContactsModal() {
      this.selectedSupplier = null;
      this.showPreviewContactsModal = false;
    },
    handleOnPreviewSupplierNotes(supplier) {
      this.selectedSupplier = supplier;

      this.$store.dispatch("allState/getSupplierNotes", {
        supplierId: supplier.id,
      });
      this.showPreviewSupplierNotesModal = true;
    },
    onClosePreviewSupplierNotes() {
      this.showPreviewSupplierNotesModal = false;
      this.selectedSupplier = null;
      this.$store.dispatch("allState/getSuppliersForDepartment", {
        departmentId: this.selectedStationId,
      });
    },
    onStationSelected(station) {
      this.selectedStationId = station;
      this.$store.dispatch("allState/getSuppliersForDepartment", {
        departmentId: this.selectedStationId,
      });
    },
  },
  computed: {
    ...mapGetters({
      getSuppliers: "allState/getDepartmentSuppliers",
      getIsArticleLoading: "allState/getIsArticleLoading",
      getVenue: "allState/getVenue",
      getStations: "allState/getDepartments",
      getIsTourDisabled: "allState/getIsTourDisabled",
      user: "allState/getUser",
    }),
    suppliers() {
      return this.getSuppliers;
    },
    isDataLoading() {
      return this.getIsArticleLoading;
    },
    isAdmin() {
      return true;
    },
    isBasicPlan() {
      return false;
    },
    stations() {
      return this.getStations;
    },
    getTotalCountSuppliers() {
      return this.allSuppliers.length;
    },
  },
  watch: {
    stations() {
      if (this.stations && this.stations.length > 0) {
        this.stations.filter((f) => this.stationsOptions.push(f));
      }
    },
    isDataLoading() {
      this.allSuppliers = this.suppliers;
    },
    getIsTourDisabled() {
      if (this.getIsTourDisabled) {
        this.$tours["myTourSuppliers"].stop();
      } else {
        this.$tours["myTourSuppliers"].start();
      }
    },
    user: {
      handler() {
        this.loadTourSteps();
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.text-fix {
  text-transform: unset !important;
}
.padding-adjust {
  padding: 32px;
  margin-top: 32px;
  @media screen and (max-width: 575px) {
    padding: 8px;
  }
}
</style>
