<template>
  <div class="sidebar">
    <div class="sidebar-links" v-if="!getIsMobile">
      <div
        id="step-0"
        class="nav-item accent--text"
        :class="{ activeNav: link.path === selectedPath }"
        v-for="(link, index) in getLinks"
        :key="link.id"
        @click="goTo(link.path)"
      >
        <v-img class="mr-3" max-width="14" :src="sidebarImages[index]"> </v-img>
        <a class="nav-link">
          {{ link.name }}
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import HomeSideBarIcon from "../assets/img/HomeSideBarIcon.svg";
import NewOrderSideBarIcon from "../assets/img/NewOrderSideBarIcon.svg";
import CurrentOrderSideBarIcon from "../assets/img/CurrentOrderSideBarIcon.svg";
export default {
  name: "Sidebar",
  props: { links: {} },
  data: () => ({
    HomeSideBarIcon: HomeSideBarIcon,
    NewOrderSideBarIcon: NewOrderSideBarIcon,
    CurrentOrderSideBarIcon: CurrentOrderSideBarIcon,
    sidebarImages: [HomeSideBarIcon, NewOrderSideBarIcon],
  }),
  methods: {
    goTo(val) {
      this.$router.push(val);
    },
  },
  watch: {
    isSuperAdmin() {
      this.$forceUpdate();
    },
  },
  computed: {
    selectedPath() {
      return this.$route.path;
    },
    getLinks() {
      return this.links?.filter((link) => link.show);
    },
    getIsMobile() {
      return this.$isMobile;
    },
  },
};
</script>
<style type="scss" scoped>
.sidebar {
  height: calc(100% - 56px);
  box-shadow: 1px 0px 1px rgba(0, 0, 0, 0.1);
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 284px;
  background-color: #ffffff;
  position: fixed;
  left: 0;
  top: 61px;
  bottom: 0;
}
.sidebar-links {
  display: flex;
  flex-direction: column;
  margin-top: 35px;
  /* margin-top: 53px; */
  padding: 0 24px;
}
.nav-item {
  padding: 9px 32px;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.nav-item:hover {
  background: #f5f5f5;
}
.nav-link {
  font-size: 16px;
  line-height: 22px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}
.activeNav {
  background: #f5f5f5;
  pointer-events: none;
}
</style>
