<template>
  <div class="station-card">
    <v-card
      class="elevation-0 card-wrap pa-8 cursor-pointer margin-adjust"
      @click="goToStationsAllOrders(station)"
    >
      <v-card-title class="pa-0 station-name-text">{{
        station.name
      }}</v-card-title>

      <div class="order-blocks">
        <div
          id="step-2"
          class="block block-width"
          @click="goToStationOrders('open', station)"
        >
          <span class="orders-type-text primaryA--text">{{
            $t("dashboard.activeOrders")
          }}</span>
          <div class="block-order-info">
            <span class="accent--text num-of-orders-text">{{
              station.numberOfOpenOrders
            }}</span>
            <div class="arrow-img-wrap">
              <v-img
                max-width="16"
                :src="require('../assets/img/ArrowRightWhite.svg')"
              ></v-img>
            </div>
          </div>
        </div>
        <div
          id="step-3"
          class="block"
          @click="goToStationOrders('expected', station)"
        >
          <span class="orders-type-text primaryA--text">{{
            $t("dashboard.expectedOrders")
          }}</span>
          <div class="block-order-info">
            <span class="accent--text num-of-orders-text">{{
              station.numberOfExpectedOrders
            }}</span>
            <div class="arrow-img-wrap">
              <v-img
                :max-width="getIsMobile ? '12px' : '24px'"
                :src="require('../assets/img/ArrowRightWhite.svg')"
              ></v-img>
            </div>
          </div>
        </div>
      </div>
    </v-card>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "StationCard",
  props: {
    station: {},
    newStation: {},
    stations: {},
    index: {},
  },
  data() {
    return {
      newStationName: "",
      sameStationNameViolated: false,
      rules: {
        stationName: [
          (val) => (val || "").length > 0 || this.$t("common.requiredField"),
        ],
      },
    };
  },
  methods: {
    goToStationOrders(type, station) {
      switch (type) {
        case "open":
          window.gtag("event", "open_orders_view", {
            event_category: "Orders",
            event_label: "View open orders",
          });
          this.$router.push(`/${station.id}/current-orders?tab=1`);
          break;
        case "expected":
          window.gtag("event", "expected_orders_view", {
            event_category: "Orders",
            event_label: "View expected orders",
          });
          this.$router.push(`/${station.id}/current-orders?tab=2`);
          break;
      }
    },

    goToStationsAllOrders(station) {
      this.$router.push(`/${station.id}/current-orders`);
    },
  },
  computed: {
    ...mapGetters({}),
    getIsMobile() {
      return this.$isMobile;
    },
  },
};
</script>
<style lang="scss" scoped>
.card-wrap {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 4px;
  @media screen and (max-width: 959px) {
    padding: 24px !important;
  }
  @media screen and (max-width: 575px) {
    padding: 12px !important;
  }
}
.order-blocks {
  display: flex;
  flex-wrap: wrap;
}
.arrow-img-wrap {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: #276ef1;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 575px) {
    width: 24px !important;
    height: 24px !important;
  }
}
.block-width {
  width: calc(50% - 24px) !important;
  margin-right: 24px;
  @media screen and (max-width: 959px) {
    margin-right: 0 !important;
  }
}
.block {
  display: flex;
  flex-direction: column;
  background: #f6f7f8;
  border-radius: 4px;
  height: 144px;
  width: 50%;
  padding: 18px 18px 24px 12px;
  @media screen and (min-width: 576px) and (max-width: 959px) {
    width: 295px !important;
  }
  @media screen and (max-width: 575px) {
    width: 48% !important;
    height: 85px !important;
    margin-right: 6px !important;
    margin-top: 0px !important;
    padding: 12px !important;
  }
  margin-top: 24px;
  cursor: pointer;
  &:hover {
    box-shadow: 1px 1px 15px 1px #e0e0e0;
  }
}
.block-order-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 34px;
  @media screen and (max-width: 575px) {
    margin-top: 10px !important;
  }
}
.station-card {
  width: calc(100% / 2 - 12px);
  min-width: 327px;
  margin-right: 24px;
  @media screen and (max-width: 575px) {
    width: 100% !important;
    margin-right: 0 !important;
  }
  @media screen and (min-width: 906px) {
    &:nth-child(even) {
      margin-right: 0 !important;
    }
  }
  @media screen and(min-width:960px) and (max-width: 1300px) {
    width: 100% !important;
    margin-right: 0 !important;
  }
  @media screen and (max-width: 959px) {
    margin: auto !important;
    &:nth-child(even) {
      margin-right: auto !important;
    }
  }
}
.newStationCard {
  min-height: 267px;
  display: flex;
  flex-direction: column;
}
.newCard {
  width: 646px;
  @media screen and (max-width: 575px) {
    width: 100% !important;
    margin-right: 0 !important;
  }
  @media screen and(min-width:960px) and (max-width: 1300px) {
    width: 100% !important;
    margin-right: 0 !important;
  }
}
.cursor-pointer {
  cursor: pointer;
}
.num-of-orders-text {
  font-size: 2.125rem;
  @media screen and (max-width: 575px) {
    font-size: 1.5rem !important;
  }
}
.orders-type-text {
  font-size: 1rem;
  @media screen and (max-width: 575px) {
    font-size: 0.8rem !important;
  }
}
.station-name-text {
  font-size: 1.25rem;
  @media screen and (max-width: 575px) {
    font-size: 1rem !important;
  }
}
.margin-adjust {
  margin-top: 24px !important;
  @media screen and (max-width: 575px) {
    margin-top: 8px !important;
  }
}
</style>
