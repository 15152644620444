<template>
  <div>
    <v-dialog
      v-model="showDialog"
      width="526"
      class="dialog-style"
      persistent
      :fullscreen="getIsMobile"
    >
      <div class="dialog-style">
        <div class="header">
          <v-img
            @click="closeDialog"
            class="close-icon"
            max-width="20px"
            :src="require('../../assets/img/CloseIcon.svg')"
          >
          </v-img>
          <div class="d-flex align-center flex-wrap justify-center">
            <span class="text-h5 primaryA--text font-weight-bold">
              {{
                $t(
                  warninModalType === "saving"
                    ? "newOrder.saveOrderConfirmTitle"
                    : "newOrder.cancelOrderConfirmTitle"
                )
              }} </span
            ><span
              v-if="warninModalType !== 'saving'"
              class="text-body-1 primaryA--text mt-5"
            >
              {{ $t("newOrder.cancelOrderConfirmSubTitle") }}
            </span>
          </div>
        </div>
        <div class="mt-8 d-flex align-center flex-wrap">
          <v-btn
            :disabled="noValidOrder"
            class="remove-uppercase"
            :width="getIsMobile ? '100%' : 'auto'"
            color="accent"
            @click="saveOrder"
            >{{ $t("newOrder.save") }}</v-btn
          >
          <v-btn
            :width="getIsMobile ? '100%' : 'auto'"
            class="ml-5 remove-uppercase"
            color="tertiaryDark"
            :class="{
              'margin-adjust-button': getIsMobile,
            }"
            @click="cancelOrder"
            >{{ $t("common.closeOut") }}</v-btn
          >
        </div>
      </div>
    </v-dialog>
  </div>
</template>
<script>
import LocalStorageService from "../../services/local-storage.service";
export default {
  name: "WarningModal",
  props: {
    dialog: {},
    warninModalType: {},
    departmentID: {},
    noValidOrder: {},
    order: {},
  },
  data: () => ({
    showDialog: false,
  }),
  watch: {
    dialog() {
      this.showDialog = this.dialog;
    },
  },
  created() {
    this.showDialog = this.dialog;
  },
  mounted() {},
  methods: {
    closeDialog() {
      this.$emit("onClose");
    },
    cancelOrder() {
      LocalStorageService.deleteLocalStorageItem("newOrderDraft");
      LocalStorageService.deleteLocalStorageItem("orderChanged");
      LocalStorageService.deleteLocalStorageItem("mergeOrderData");
      if (this.warninModalType !== "saving") {
        this.$emit("onClose");
        this.$router.push(`/${this.departmentID}/current-orders`);
      } else {
        this.$emit("onClose");
      }
    },
    saveOrder() {
      this.$emit("onSavingOrder");
    },
  },
  computed: {
    getIsMobile() {
      return this.$isMobile;
    },
  },
};
</script>
<style scoped lang="scss">
.dialog-style {
  background-color: #ffffff;
  padding: 40px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 959px) {
    height: 100%;
  }
}
.close-icon {
  cursor: pointer;
  position: absolute;
  top: -12px;
  right: -24px;
}
.info-text {
  text-align: center;
}
.header {
  position: relative;
}
.margin-adjust-button {
  margin-left: 0 !important;
  margin-top: 12px;
}
.remove-uppercase {
  text-transform: none !important;
}
</style>
