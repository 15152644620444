import Vue from "vue";

import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import vuetify from "./plugins/vuetify";
import store from "./store";
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import '@mdi/font/css/materialdesignicons.css';
import { isMobile } from "mobile-device-detect";
import i18n from "./i18n";
import VueTour from 'vue-tour';
import 'vue-tour/dist/vue-tour.css';
import VueAnalytics from 'vue-analytics';
import "./assets/global.css";


const moment = require("moment");
require("moment/locale/sr");
Vue.prototype.$isMobile = isMobile;
Vue.config.productionTip = false;

Vue.use(VueTour);

Vue.use(require("vue-moment"), {
  moment,
});

Vue.use(VueAnalytics, {
  id: 'G-WDSNXJ7G89',
  router
});

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
