<template>
  <v-dialog v-model="dialog" max-width="600" :fullscreen="getIsMobile">
    <div class="dialog-style">
      <v-card elevation="0" style="overflow: hidden">
        <div class="d-flex justify-end mt-2">
          <v-btn
            @click="selectedLanguage = 'sr'"
            v-if="selectedLanguage === 'sr'"
            color="accent"
            small
            >{{ selectedLanguage === "sr" ? "Srpski" : "Serbian" }}</v-btn
          >
          <v-btn
            @click="selectedLanguage = 'sr'"
            v-else
            color="accent"
            plain
            small
            >{{ selectedLanguage === "sr" ? "Srpski" : "Serbian" }}</v-btn
          >
          <v-btn
            @click="selectedLanguage = 'en'"
            v-if="selectedLanguage === 'en'"
            color="accent"
            small
            >{{ selectedLanguage === "sr" ? "Engleski" : "English" }}</v-btn
          >
          <v-btn
            @click="selectedLanguage = 'en'"
            v-else
            color="accent"
            plain
            small
            >{{ selectedLanguage === "sr" ? "Engleski" : "English" }}</v-btn
          >
        </div>
        <div class="d-flex flex-column">
          <v-card-title class="pa-0 word-break">{{ getTitle }}</v-card-title>
        </div>
        <div
          class="pt-2 word-break maxHeight"
          :class="{ textWrapper: getIsMobile }"
          v-html="getText"
        ></div>
        <div
          class="text-caption font-weight-light d-flex align-center flex-column justify-start"
        >
          <v-btn color="primary" text @click="$emit('onClose')">{{
            this.selectedLanguage === "sr" ? "Zatvori" : "Close"
          }}</v-btn>
          <span>{{
            this.selectedLanguage === "sr"
              ? "Klikom na link ispod možete da uključite ili isključite praćenje aktivnosti od strane Google Analytics:"
              : "By clicking the link below you can opt out from Google Analytics tracking:"
          }}</span>
          <v-btn
            class="text-caption"
            @click="toggleTracking"
            v-if="trackingDisabled"
            small
            plain
            >{{
              this.selectedLanguage === "sr"
                ? "Uključi praćenje aktivnosti"
                : "Enable Tracking"
            }}</v-btn
          >
          <v-btn
            class="text-caption"
            @click="toggleTracking"
            v-else
            small
            plain
            >{{
              this.selectedLanguage === "sr"
                ? "Iskljući praćenje aktivnosti"
                : "Disable Tracking"
            }}</v-btn
          >
        </div>
      </v-card>
    </div>
  </v-dialog>
</template>
<script>
export default {
  name: "TermsAndPoliciesModal",
  props: {
    dialog: {},
    type: {},
  },
  data: () => ({
    title: "",
    text: "",
    selectedLanguage: "sr",
    trackingDisabled: false,
  }),
  created() {
    this.selectedLanguage = "sr";
  },
  mounted() {
    this.trackingDisabled = localStorage.getItem("trackingDisabled") === "true";
  },
  methods: {
    toggleTracking() {
      if (this.trackingDisabled) {
        localStorage.setItem("trackingDisabled", "false");
        this.$ga.enable();
        alert("Tracking enabled");
        this.trackingDisabled = false;
      } else {
        localStorage.setItem("trackingDisabled", "true");
        this.$ga.disable();
        alert("Tracking disabled");
        this.trackingDisabled = true;
      }
    },
    getTermsTextSer() {
      return `
     <p>
         Saglasnost za korišćenje kolačića i prikupljanje podataka u analitičke svrhe
    </p>
    <ul>
        <li>Cenimo vašu privatnost i posvećeni smo transparentnosti o tome kako koristimo vaše podatke. Ova veb lokacija koristi kolačiće i slične tehnologije da poboljša vaše korisničko iskustvo, analizira kako se usluga koristi i prilagodi našu interakciju sa vama.</li>
        <p><br>Koje podatke prikupljamo:<br></p>
        <li>Kolačići i podaci o korišćenju:
            Koristimo kolačiće da bismo pratili vaše aktivnosti na našem sajtu i čuvali određene informacije. Ovo uključuje opšte podatke o korišćenju kao što su posećene stranice, vreme provedeno na stranicama i interakcija sa različitim elementima sajta.</li>
        <li>Analitički podaci: Prikupljamo anonimne podatke za analitiku, kao što su saobraćaj na veb lokaciji i obrasci ponašanja korisnika, da bismo poboljšali naše usluge.</li>
        <p><br>Zašto prikupljamo ove podatke:<br></p>
        <li>Za poboljšanje funkcionalnosti i efikasnosti veb stranice.</li>
        <li>Da steknemo uvid u to kako se naše usluge koriste, što nam omogućava da optimizujemo korisničko iskustvo.</li>
        <li>Da informišemo o našim marketinškim i reklamnim strategijama, osiguravajući da su relevantne i prilagođene vašim interesovanjima.</li>
        <p><br>Vaš izbor i saglasnost:<br></p>
        <li>Saglasnost: Klikom na checkmark, pristajete na našu upotrebu kolačića i drugih tehnologija za praćenje u gore opisane svrhe</li>
        <li>Ne prati: Naš sistem ne odgovara na zahteve pretraživača Ne prati.</li>
        <li>Povlačenje saglasnosti: Možete se predomisliti u bilo kom trenutku prilagođavanjem podešavanja pretraživača tako da odbija kolačiće ili klikom na link ispod - Isključi praćenje aktivnosti.</li>

        <p>Prihvatanjem, potvrđujete da ste pročitali i razumeli ovaj formular za saglasnost.</p>
    </ul>
    <p>
        Korišćenjem SuppliMe, saglasni ste da budete obavezani ovim Uslovima i odredbama. Ako imate bilo kakvih pitanja ili nedoumica u vezi sa ovim uslovima, kontaktirajte nas na <a href="mailto:office@supplyme.rs">office@supplyme.rs</a>.
    </p>
    <p>
        Poslednja izmena: 1.1.2024.
    </p>
        `;
    },
    getTermsTextEng() {
      return `
    <p>
         Consent to Use Cookies and Collect Data for Analytical Purposes
    </p>
    <ul>
        <li>We value your privacy and are committed to being transparent about how we use your information. This website uses cookies and similar technologies to improve your user experience, analyze how the service is used, and tailor our interactions with you.</li>
        <p><br>What Data We Collect:<br></p>
        <li>Cookies and Usage Data:
            We use cookies to track your activity on our site and hold certain information. This includes general usage data such as pages visited, time spent on pages, and interaction with various elements of the site.</li>
        <li>Analytical Data:
            We gather anonymous data for analytics, such as website traffic and user behavior patterns, to improve our services.</li>
        <p><br>Why We Collect This Data:<br></p>
        <li>To enhance website functionality and efficiency.</li>
        <li>To gain insights into how our services are being used, enabling us to optimize user experience.</li>
        <li>To inform our marketing and advertising strategies, ensuring they are relevant and tailored to your interests.</li>
        <p><br>Your Choices and Consent:<br></p>
        <li>Consent: By clicking checkbox you consent to our use of cookies and other tracking technologies for the purposes described above.</li>
        <li>Do Not Track:Our system does not respond to Do Not Track requests from browsers.</li>
        <li>Withdraw Consent: You can change your mind at any time by adjusting your browser settings to refuse cookies or by clicking the below link - Disable tracking.</li>

        <p>By accepting, you acknowledge that you have read and understood this consent form.</p>
    </ul>
    <p>
        By using SupplyMe, you agree to be bound by these Terms and Conditions. If you have any questions or concerns about these terms, please contact us at <a href="mailto:office@supplyme.rs">office@supplyme.rs</a>.
    </p>
    <p>
        Last updated: 1.1.2024.
    </p>
        `;
    },
  },
  computed: {
    getText() {
      if (this.type === "termsAndConditions") {
        return this.selectedLanguage === "sr"
          ? this.getTermsTextSer()
          : this.getTermsTextEng();
      }
      return "";
    },
    getTitle() {
      if (this.type === "termsAndConditions") {
        return this.selectedLanguage === "sr"
          ? "Kolačići i prikupljanje podataka u analitičke svrhe"
          : "Cookies and Data collection for Analytical Purposes";
      }
      return "";
    },
    getIsMobile() {
      return this.$isMobile;
    },
  },
};
</script>
<style scoped lang="scss">
.textWrapper {
  max-height: 80vh;
  overflow-x: hidden;
  overflow-y: auto;
}
.dialog-style {
  background-color: #ffffff;
  padding: 12px 24px 12px 24px;
  text-align: left;
  overflow: hidden;
}
.word-break {
  word-break: normal;
}
.maxHeight {
  height: 65vh;
  overflow: auto;
}
</style>
