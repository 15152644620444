<template>
  <div class="width-max" :class="[getIsMobile ? 'mt-2' : 'mt-10']">
    <div class="d-flex justify-center">
      <span
        class="text-h5 primaryA--text font-weight-bold"
        :class="{ 'text-h6': getIsMobile }"
        >{{ $t("newOrder.chooseDate") }}</span
      >
    </div>
    <div class="mt-5">
      <v-menu
        v-model="menu2"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            class="date-picker"
            :value="getDate()"
            :label="$t('common.date')"
            outlined
            readonly
            v-bind="attrs"
            v-on="on"
            :disabled="disableDateField"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="date"
          :first-day-of-week="1"
          locale="sr-Latn-RS"
          @input="menu2 = false"
          :allowed-dates="allowedDates"
          @change="onDatePicked"
          format="ampm"
        ></v-date-picker>
      </v-menu>
    </div>
    <div>
      <v-select
        :items="allSuppliers"
        :label="$t('common.supplier')"
        v-model="selectedSupplier"
        item-text="name"
        item-value="pib"
        outlined
        @change="onSupplierSelected"
        :disabled="isEdit"
      >
      </v-select>
    </div>
    <div class="d-flex justify-center">
      <v-btn
        :disabled="!date || !selectedSupplier"
        class="continue-button"
        color="accent"
        @click="onContinueClick"
        >{{ $t("newOrder.continue") }}</v-btn
      >
    </div>
    <modal-component
      :dialog="showSameDayWarningModal"
      @close="onCloseSameDayWarningModal"
      :hideClosing="true"
    >
      <div>
        <div class="text-h6 font-weight-black">
          {{ $t("newOrder.sameDayOrderTitle") }}
        </div>
        <div
          class="text-subtitle-2 secondaryDark2--text pt-0 pb-0 pl-8 pr-8 mt-3"
        >
          {{ $t("newOrder.sameDayOrderDesc") }}
        </div>

        <v-btn
          class="mt-6"
          color="accent"
          @click="onCloseSameDayWarningModal"
          >{{ $t("newOrder.thatIsFine") }}</v-btn
        >
      </div>
    </modal-component>
  </div>
</template>
<script>
//services
import LocalStorageService from "../../services/local-storage.service";
//components
import ModalComponent from "../../components/ModalComponent.vue";
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  name: "StepOneDateSupplier",
  components: { ModalComponent },
  props: {
    step: {},
    order: {},
    isEdit: {},
    allSteps: {},
  },
  data: () => ({
    date: "",
    menu2: false,
    selectedSupplier: "",
    todaysDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    showSameDayWarningModal: false,
    tomorrowsDate: "",
    disableDateField: false,
  }),
  created() {
    let data = LocalStorageService.getLocalStorageData("mergeOrderData");
    if (data && this.isEdit) {
      this.disableDateField = true;
    }
    let today = new Date();
    let tomorrow = new Date(today);
    tomorrow.setDate(new Date().getDate() + 1);
    this.tomorrowsDate = tomorrow.toISOString().substr(0, 10);
    this.selectedSupplier = (this.order && this.order.supplier) || "";
    this.processLocalStorageData();
  },
  mounted() {
    this.setLocalStorage();
    // this.$store.dispatch("userProfile/getUserProfile");
    this.$store.dispatch("allState/getSuppliersForDepartment", {
      departmentId: this.$route.params.departmentID,
    });
  },
  methods: {
    onSupplierSelected(event) {
      LocalStorageService.deleteLocalStorageItem("newOrderDraft");
      // this.$store.dispatch("orders/clearSelectedArticles", true);

      this.selectedSupplier = this.allSuppliers.filter(
        (f) => f.pib === event
      )[0];
      if (!this.selectedSupplier.isMarket) {
        this.$store.dispatch("allState/getAllSupplierContacts", {
          supplierId: this.selectedSupplier.id,
        });
      }
      this.setLocalStorage();
    },
    onContinueClick() {
      this.setLocalStorage();
      this.$emit("onStepChange", this.step + 1);
      this.$emit("onOrderUpdate", {
        date: this.date,
        supplier: this.selectedSupplier,
      });
    },
    setLocalStorage() {
      LocalStorageService.setLocalStorageData("newOrderDraft", {
        orderObj: {
          date: this.date,
          supplier: this.selectedSupplier,
        },
        allSteps: this.allSteps,
        currentStep: this.step,
      });
    },
    processLocalStorageData() {
      let data = LocalStorageService.getLocalStorageData("newOrderDraft");
      let dateFromLS = null;
      if (data) {
        dateFromLS = data.orderObj.deadline;
      }
      this.date = this.order.deadline
        ? new Date(this.order.deadline).toISOString().slice(0, 10)
        : dateFromLS
        ? new Date(dateFromLS).toISOString().slice(0, 10)
        : this.tomorrowsDate;
    },
    allowedDates(val) {
      return val >= this.todaysDate;
    },
    onDatePicked(event) {
      LocalStorageService.setLocalStorageData("orderChanged", true);
      if (event === this.todaysDate) {
        this.showSameDayWarningModal = true;
      }
    },
    onCloseSameDayWarningModal() {
      this.showSameDayWarningModal = false;
    },
    onCancelOrder() {},
    getDate() {
      return moment.utc(this.date).format("DD.MM.YYYY");
    },
  },
  computed: {
    ...mapGetters({
      getSuppliers: "allState/getDepartmentSuppliers",
      getVenue: "allState/getVenue",
    }),
    venue() {
      return this.getVenue;
    },
    suppliers() {
      return this.getSuppliers;
    },
    allSuppliers() {
      if (!this.suppliers) return [];

      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.suppliers.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
    },
    getIsMobile() {
      return this.$isMobile;
    },
  },
  watch: {
    order() {
      this.selectedSupplier = this.order.supplier || "";

      this.date = this.order.deadline
        ? new Date(this.order.deadline).toISOString().slice(0, 10)
        : this.tomorrowsDate;
      if (this.order.deadline < this.todaysDate) {
        this.date = "";
      }
      this.setLocalStorage();
    },
    date() {
      this.setLocalStorage();
    },
  },
};
</script>
<style lang="scss" scoped>
.width-max {
  max-width: 357px;
  margin: auto;
}
.continue-button {
  text-transform: none !important;
  width: 100%;
}
</style>
