<template>
  <v-app class="main-app">
    <top-nav
      v-if="showTopNav"
      :getUser="user"
      :venueId="getVenueData && getVenueData.id"
    ></top-nav>
    <div>
      <side-bar
        class="d-none d-md-block"
        v-if="!getCurrentPagePath"
        :links="sidebarLinks"
      ></side-bar>
      <v-main
        class="content"
        :class="{
          contentWithSidebar: !getCurrentPagePath,
        }"
      >
        <router-view
          :user="user"
          :userProfile="userProfile"
          :venue="getVenueData"
        ></router-view>
      </v-main>
    </div>
    <loader-component v-if="showLoader"></loader-component>
    <notification-component
      :showSnackbar="snackbar"
      :text="getSnackBarText"
      :timeout="snackBarTimeout"
      @close="snackbar = false"
    ></notification-component>
  </v-app>
</template>

<script>
import TopNav from "./components/TopNav.vue";
import SideBar from "./components/Sidebar.vue";
import EventBus from "./shared/EventBus";
import LoaderComponent from "./components/LoaderComponent.vue";
import NotificationComponent from "./components/NotificationComponent.vue";
import { mapGetters } from "vuex";

export default {
  name: "App",
  components: {
    SideBar,
    TopNav,
    LoaderComponent,
    NotificationComponent,
  },
  data() {
    return {
      sidebarLinks: [],
      snackbar: false,
      snackBarText: "",
      snackBarTimeout: 3000,
      restaurantID: null,
    };
  },
  created() {
    this.setSideBarLinks();
  },
  mounted() {
    this.restaurantID = 1;
    EventBus.on("showSnackbar", (data) => {
      this.showSnackBarAction(data);
    });
  },

  methods: {
    showSnackBarAction(data) {
      this.snackBarText = data.text;
      this.snackBarTimeout = data.timeout || 3000;
      this.snackbar = true;
    },
    setSideBarLinks() {
      this.sidebarLinks = [
        {
          id: 0,
          name: this.$t("sidebarLinks.home"),
          path: "/dashboard",
          show: true,
        },
        // {
        //   id: 1,
        //   name: this.getIsUserAdmin
        //     ? this.$t("sidebarLinks.adminRestaurant")
        //     : this.$t("sidebarLinks.adminRestaurants"),
        //   path: this.getIsUserAdmin
        //     ? `/admin/${this.getVenueData.id}/restaurant`
        //     : "/admin/restaurants",
        //   show:
        //     this.isRouteAdmin &&
        //     (this.getIsUserAdmin || this.getIsUserSuperAdmin),
        // },
        // {
        //   id: 2,
        //   name: this.$t("sidebarLinks.adminSuppliers"),
        //   path: "/admin/suppliers",
        //   show: this.isRouteAdmin && this.isUserSuperAdmin,
        // },
        // {
        //   id: 3,
        //   name: this.$t("sidebarLinks.adminUsers"),
        //   path: `/admin/users`,
        //   show:
        //     this.isRouteAdmin &&
        //     (this.getIsUserSuperAdmin || this.getIsUserAdmin),
        // },
        // {
        //   id: 4,
        //   name: this.$t("sidebarLinks.adminStations"),
        //   path: `/admin/${this.getVenueData && this.getVenueData.id}/stations`,
        //   show: this.isRouteAdmin && this.getIsUserAdmin,
        // },
        // {
        //   id: 5,
        //   name: this.$t("sidebarLinks.adminArticles"),
        //   path: `/admin/articles`,
        //   show: this.isRouteAdmin && this.getIsUserSuperAdmin,
        // },
        // {
        //   id: 6,
        //   name: this.$t("sidebarLinks.adminReports"),
        //   path: `/admin/reports`,
        //   show: this.isRouteAdmin && this.getIsUserAdmin,
        // },
      ];
    },
  },

  computed: {
    ...mapGetters({
      getVenue: "allState/getVenue",
      user: "allState/getUser",
    }),
    getCurrentPagePath() {
      return (
        this.$route.path.includes("new-order") ||
        this.$route.path.includes("edit-order") ||
        this.$route.name.includes("Welcome")
      );
    },

    showTopNav() {
      if (
        (this.$isMobile && this.getCurrentPagePath) ||
        this.$route.name.includes("Welcome")
      ) {
        return false;
      }

      return true;
    },
    showLoader() {
      return this.isLoading;
    },
    getSnackBarText() {
      return this.snackBarText;
    },
    getVenueData() {
      return this.getVenue;
    },

    userProfile() {
      return {};
    },
  },
  watch: {
    user: {
      handler() {
        this.setSideBarLinks();
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.main-app {
  display: flex;
  flex-direction: column;
}
@media screen and(max-width: 959px) {
  .content {
    width: 100% !important;
    margin-left: 0 !important;
  }
}
.content {
  min-height: 100vh;
  background-color: #ffffff;
  position: relative;
}
.contentWithSidebar {
  min-height: 100vh;
  background-color: #ffffff;
  position: relative;
  width: calc(100% - 284px);
  margin-left: 284px;
  padding: 0 !important;
  padding: 32px 0 !important;
}
</style>
