import { render, staticRenderFns } from "./ArticlesPage.vue?vue&type=template&id=74ec7304&scoped=true"
import script from "./ArticlesPage.vue?vue&type=script&lang=js"
export * from "./ArticlesPage.vue?vue&type=script&lang=js"
import style0 from "./ArticlesPage.vue?vue&type=style&index=0&id=74ec7304&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74ec7304",
  null
  
)

export default component.exports