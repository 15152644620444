<template>
  <div class="padding-adjust">
    <div>
      <span class="text-h5 primaryA--text font-weight-bold">{{
        $t("currentOrders.currentOrders")
      }}</span>
      <div class="d-flex justify-space-between align-center">
        <v-col :cols="getIsMobile ? '9' : '2'" class="mt-4">
          <v-select
            id="step-7"
            :items="suppliers"
            v-model="selectedSupplier"
            item-text="name"
            item-value="id"
            @change="onSelectSupplier($event)"
            :label="$t('currentOrders.filterBySupplier')"
            outlined
            dense
          ></v-select>
        </v-col>
        <v-btn
          class="d-none d-md-block create-button"
          color="accent"
          @click="createNewOrder"
          >{{ $t("currentOrders.createNewOrder") }}</v-btn
        >
        <div class="d-flex d-md-none button-plus" @click="createNewOrder">
          <v-img
            max-width="14"
            :src="require('../../assets/img/Plus.svg')"
          ></v-img>
        </div>
      </div>
    </div>
    <v-alert
      id="step-9"
      v-if="allOrdersInProgressAndDueDate.length > 0"
      dense
      dismissible
      type="warning"
      border="bottom"
      color="orange"
    >
      <v-row align="center">
        <v-col class="grow">
          {{ $t("orderDueWarning.orderDueWarning") }}
        </v-col>
        <v-col class="shrink">
          <v-btn @click="showPassDueWarningModal = true">{{
            $t("orderDueWarning.more")
          }}</v-btn>
        </v-col>
      </v-row>
    </v-alert>
    <div class="mt-4">
      <tabs :navItems="navItems" @onTabSelected="handleTabSelected"></tabs>
    </div>
    <div
      class="d-flex justify-left align-center"
      style="width: 1px; height: 1px"
      id="step-6"
    ></div>
    <div class="mt-8 currentOrdersDataTable">
      <data-table
        v-if="!noOrders"
        :headers="headers"
        :data="getAllOrders"
        :isDataLoading="isDataLoading"
        @onReceiveOrder="handleReceiveOrder"
        @onPreviewOrderItem="handlePreviewOrderItem"
        @onDeleteOrder="handleOnDeleteOrder"
        @onRepeatOrder="handleRepeatOrder"
        @onCopySMSOrder="handleOnCopySMSOrder"
        :getIsMobile="getIsMobile"
        :isBasicPlan="isBasicPlan"
        :isCurrentOrders="true"
        :currentPage="page"
        :currentPageSize="pageSize"
        :totalCount="totalOrdersCount"
        @onPageChange="handleOnPageChange"
        @itemsPerPageChange="handleItemsPerPageChange"
      ></data-table>
      <no-results-content
        v-else
        :tabName="{ currentTabSelected }"
        :page="'orders'"
      ></no-results-content>
    </div>
    <receive-order-dialog
      v-if="showReceiveOrderModal"
      :orderReceiveObject="orderReceiveObject"
      :dialog="showReceiveOrderModal"
      :venueId="getVenue.id"
      @onClose="showReceiveOrderModal = false"
      @allOrdersArrived="handleAllOrdersArrived"
      @allOrdersArrivedMoveToNew="handleOrdersArrivedMoveToNew"
      :isBasicPlan="isBasicPlan"
      @onOrderArriveLaterUpdated="handleOnOrderArrivedLaterUpdated"
      :stationID="stationID"
    ></receive-order-dialog>
    <preview-order-modal
      v-if="showPreviewOrderModal"
      :data="orderForPreview"
      :showDialog="showPreviewOrderModal"
      @onClose="onClosePreviewModal"
      @onPreviewNewOrder="handlePreviewNewOrder"
    >
    </preview-order-modal>
    <modal-component
      :dialog="showDeleteWarningModal"
      @close="onCloseDeleteWarningModal"
    >
      <div>
        <div class="text-h6 font-weight-black">
          {{ $t("newOrder.sureToDelete") }}
        </div>
        <div
          class="text-subtitle-2 secondaryDark2--text pt-0 pb-0 pl-8 pr-8 mt-3"
        >
          {{ $t("newOrder.sureToDeleteDescription") }}
        </div>

        <div class="d-flex justify-center align-center mt-6">
          <v-btn color="accent" @click="onConfirmDeleteOrder">{{
            $t("newOrder.yes")
          }}</v-btn>
          <v-btn class="ml-4" @click="onCloseDeleteWarningModal">{{
            $t("newOrder.cancel")
          }}</v-btn>
        </div>
      </div>
    </modal-component>
    <order-due-warning-modal
      :dialog="showPassDueWarningModal"
      :ordersDue="allOrdersInProgressAndDueDate"
      @handleOrder="onHandleOrder"
      @onChangeOrderDate="handleChangeOrderDate"
      @onClose="handleOnCloseOrderDueWarningModal"
    ></order-due-warning-modal>
    <change-order-date-modal
      :departmentId="stationID"
      :orderToChangeDate="orderToChangeDate"
      :dialog="isChangeOrderDateModalOpened"
      @onClose="handleOnCloseChangeOrderModal"
      @onUpdatedOrderDate="handleOnUpdatedOrderDate"
    ></change-order-date-modal>
    <div class="hidden-field">
      <textarea readonly :value="smsTextToCopy" />
    </div>
    <v-tour
      name="myTourCurrentOrders"
      :steps="steps"
      :options="myOptions"
      :callbacks="myCallbacks"
    ></v-tour>
  </div>
</template>
<script>
//components
import DataTable from "../../components/DataTable.vue";
import Tabs from "../../components/Tabs.vue";
import ReceiveOrderDialog from "../../components/ReceiveOrderDialog.vue";
import NoResultsContent from "../../components/NoResultsContent.vue";
import PreviewOrderModal from "../../components/PreviewOrderModal.vue";
import ModalComponent from "../../components/ModalComponent.vue";
import OrderDueWarningModal from "../../components/OrderDueWarningModal.vue";
import ChangeOrderDateModal from "../../components/ChangeOrderDateModal.vue";
//vuex
import { mapGetters } from "vuex";

//services
import EventBus from "../../shared/EventBus";
import LocalStorageService from "../../services/local-storage.service";
// helpers
import { formatDate, generateSMSText } from "../../helpers/helper";
import { orderStatuses } from "../../shared/statuses";

export default {
  name: "CurrentOrders",
  components: {
    DataTable,
    Tabs,
    ReceiveOrderDialog,
    NoResultsContent,
    PreviewOrderModal,
    ModalComponent,
    OrderDueWarningModal,
    ChangeOrderDateModal,
  },
  data: () => ({
    headers: [],
    navItems: [],
    isDataLoading: true,
    showReceiveOrderModal: false,
    orderReceiveObject: {},
    showWarningModal: false,
    currentTabSelected: {},
    stationID: null,
    orderForPreview: null,
    showPreviewOrderModal: false,
    getAllOrders: [],
    yesterdayDate: null,
    tabIndex: null,
    allOrdersImmutable: [],
    componentMounted: false,
    showDeleteWarningModal: false,
    orderDeleteId: null,
    showRepeatOrderModal: false,
    orderToRepeat: null,
    selectedSupplier: "",
    suppliers: [],
    smsTextToCopy: "",
    todayDate: "",
    orderToBeDeleted: null,
    page: 0,
    pageSize: 10,
    orderStatusSelected: orderStatuses.OPEN,
    showPassDueWarningModal: false,
    orderToChangeDate: null,
    isChangeOrderDateModalOpened: false,
    steps: [],
    myOptions: {},
    myCallbacks: {},
  }),
  created() {
    this.loadTourSteps();
    this.todayDate = new Date().getTime();
    this.todayDate = new Date(this.todayDate).toISOString();
    this.setYesterdayDate();
    this.setTabNames();
    this.setTableHeaders();
    this.stationID = this.$route.params?.departmentID;
    this.myCallbacks = { onSkip: this.handleOnSkip };
  },
  mounted() {
    const isDisabled = LocalStorageService.getLocalStorageData("tourDisabled");
    this.$store.dispatch("allState/disableTour", isDisabled);
    if (isDisabled) {
      this.$tours["myTourCurrentOrders"].stop();
    } else {
      this.$tours["myTourCurrentOrders"].start();
    }
    setTimeout(() => {
      this.isDataLoading = false;
    }, 1000);
    this.$store.dispatch("allState/getOrders", {
      departmentId: this.stationID,
      status: this.orderStatusSelected,
    });
    if (this.$route.query && this.$route.query.tab) {
      this.tabIndex = parseInt(this.$route.query.tab) - 1;
      const value = this.navItems[this.tabIndex];
      this.handleTabSelected(value);

      const el =
        this.tabIndex === 0
          ? document.getElementById("tab-0")
          : document.getElementById("tab-1");
      el.click();
    } else {
      this.$store.dispatch("allState/getOrders", {
        departmentId: this.stationID,
        status: this.orderStatusSelected,
      });
    }
    this.componentMounted = true;
  },
  methods: {
    setTabNames() {
      this.navItems = [
        {
          id: 0,
          name: this.$t("navigation.noRealized"),
          code: orderStatuses.OPEN,
        },
        {
          id: 1,
          name: this.$t("navigation.currentOrders"),
          code: orderStatuses.IN_PROGRESS,
        },
        {
          id: 2,
          name: this.$t("navigation.acceptedOrders"),
          code: orderStatuses.COMPLETED,
        },
      ];
    },
    setTableHeaders() {
      this.headers = [
        { text: this.$t("common.date"), value: "deadline", sortable: true },
        {
          text: this.$t("common.supplier"),
          value: "supplierOrders",
          sortable: false,
        },
        // { text: this.$t("common.id"), value: "id" },
        { text: this.$t("common.status"), value: "statusText", sortable: true },
        { text: "", value: "actions", sortable: false },
        { text: "", value: "options", sortable: false },
      ];
    },
    handleOnSkip() {
      this.$store.dispatch("allState/disableTour", true);
    },
    createNewOrder() {
      window.gtag("event", "new_order_init_current_orders", {
        event_category: "Orders",
        event_label: "Create Order",
      });
      LocalStorageService.deleteLocalStorageItem("newOrderDraft");
      LocalStorageService.deleteLocalStorageItem("orderChanged");
      this.$router.push(`/${this.stationID}/new-order`);
    },
    handleReceiveOrder(item) {
      this.orderReceiveObject = item;
      this.showReceiveOrderModal = true;
    },
    handleTabSelected(val) {
      this.$store.dispatch("allState/getOrders", {
        departmentId: this.stationID,
        status: val.code,
      });
      this.orderStatusSelected = val.code;
      this.currentTabSelected = val;
    },
    handleAllOrdersArrived() {
      this.showReceiveOrderModal = false;
      this.showPassDueWarningModal = false;
      this.$store.dispatch("allState/getOrders", {
        departmentId: this.stationID,
        status: this.orderStatusSelected,
      });
      // window.location.reload();
    },

    handlePreviewOrderItem(value) {
      this.orderForPreview = value;
      this.showPreviewOrderModal = true;
    },
    onClosePreviewModal() {
      this.orderForPreview = null;
      this.showPreviewOrderModal = false;
    },
    handleOnDeleteOrder(value) {
      this.orderDeleteId = value.id;
      this.orderToBeDeleted = value;
      this.showDeleteWarningModal = true;
    },
    onCloseDeleteWarningModal() {
      this.showDeleteWarningModal = false;
    },
    onConfirmDeleteOrder() {
      this.$store.dispatch(
        this.orderToBeDeleted.isCustomOrder
          ? "allState/deleteCustomOrder"
          : "allState/deleteOrder",
        { orderId: this.orderDeleteId, departmentId: this.stationID }
      );
      this.showDeleteWarningModal = false;
      this.$store.dispatch("allState/getOrders", {
        departmentId: this.stationID,
        status: this.orderStatusSelected,
      });
      EventBus.dispatch("showSnackbar", {
        text: this.$t("notifications.orderDeletedSuccess"),
      });
      window.gtag("event", "deleted_order", {
        event_category: "Orders",
        event_label: "Delete order",
      });
    },
    setYesterdayDate() {
      const timestamp = new Date().getTime();
      const yesterdayTimeStamp = timestamp - 24 * 60 * 60 * 1000;
      this.yesterdayDate = new Date(yesterdayTimeStamp).toISOString();
    },
    handleRepeatOrder(order) {
      this.orderToRepeat = order;
      this.showRepeatOrderModal = true;
    },
    handleCloseRepeatOrderModal() {
      this.orderToRepeat = null;
      this.showRepeatOrderModal = false;
    },
    handleOnCloseSent() {
      this.orderToRepeat = null;
      this.showRepeatOrderModal = false;
      this.$store.dispatch("allState/getOrders", {
        departmentId: this.stationID,
        page: 0,
        pageSize: 10,
        status: this.orderStatusSelected,
      });
    },
    onSelectSupplier(event) {
      if (event === 999) {
        this.getAllOrders = this.getOrders;
      } else if (event === 10000) {
        this.getAllOrders = this.getOrders.filter((f) => f.isCustomOrder);
      } else {
        this.getAllOrders = this.getOrders.filter(
          (f) => !f.isCustomOrder && f.supplier.id === event
        );
      }

      if (this.currentTabSelected) {
        switch (this.currentTabSelected.id) {
          case 0:
            this.allOrders = this.getAllOrders;
            break;
          case 1:
            this.allOrders =
              this.getAllOrders &&
              this.getAllOrders.filter((f) => f.status === orderStatuses.OPEN);
            break;
          case 2:
            this.allOrders =
              this.getAllOrders &&
              this.getAllOrders.filter(
                (f) =>
                  f.status === orderStatuses.IN_PROGRESS ||
                  f.status === orderStatuses.INCOMPLETE
              );
            break;
          case 3:
            this.allOrders =
              this.getAllOrders &&
              this.getAllOrders.filter(
                (f) => f.status === orderStatuses.COMPLETED
              );
            break;
        }
      }
    },
    processOrderSuppliers() {
      this.suppliers = [];
      this.getAllOrders.forEach((order) => {
        let i =
          this.suppliers.filter(
            (s) => !order.isCustomOrder && s?.id === order.supplier?.id
          ).length > 0;
        !i && this.suppliers.push(order.supplier);
      });
      this.suppliers.unshift({
        id: 999,
        name: this.$t("currentOrders.allSuppliers"),
      });
      this.suppliers.push({ id: 10000, name: this.$t("articles.market") });
    },
    handleOnCopySMSOrder(order) {
      let textarea;
      let result;
      this.smsTextToCopy = this.getGeneratedSMSText(order);

      try {
        textarea = document.createElement("textarea");
        textarea.setAttribute("readonly", true);
        textarea.setAttribute("contenteditable", true);
        textarea.style.position = "fixed"; // prevent scroll from jumping to the bottom when focus is set.
        textarea.value = this.smsTextToCopy;

        document.body.appendChild(textarea);

        textarea.focus();
        textarea.select();

        const range = document.createRange();
        range.selectNodeContents(textarea);

        const sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);

        textarea.setSelectionRange(0, textarea.value.length);
        result = document.execCommand("copy");
      } catch (err) {
        console.error(err);
        result = null;
      } finally {
        document.body.removeChild(textarea);
      }

      // manual copy fallback using prompt
      if (!result) {
        const isMac = navigator.platform.toUpperCase().indexOf("MAC") >= 0;
        const copyHotkey = isMac ? "⌘C" : "CTRL+C";
        result = prompt(`Press ${copyHotkey}`, string); // eslint-disable-line no-alert
        if (!result) {
          return false;
        }
      }
      setTimeout(() => {
        EventBus.dispatch("showSnackbar", {
          text: this.$t("notifications.smsCopyConfirmation"),
        });
      }, 1000);
      return true;
    },
    getGeneratedSMSText(order) {
      return (
        order &&
        order.products &&
        generateSMSText(
          order.products,
          this.venue.bussiness_name,
          this.venue.address,
          this.getFormattedDate(order.deadline),
          order.orderNote,
          order.paymentMethod,
          null,
          null,
          null,
          null,
          order.isCustomOrder,
          order.department
        )
      );
    },
    getFormattedDate(value) {
      return formatDate(value);
    },
    handleOrdersArrivedMoveToNew(data) {
      this.showReceiveOrderModal = false;
      this.$router.push(
        `/${this.$route.params.departmentID}/edit-order/${data.newOrderId}`
      );
    },
    handlePreviewNewOrder(newOrderNumber) {
      this.showPreviewOrderModal = false;
      this.orderForPreview = this.allOrders.filter(
        (order) => order.order_number === newOrderNumber
      )[0];
      this.showPreviewOrderModal = true;
    },
    handleOnPageChange(page) {
      this.page = page - 1;
      this.$store.dispatch("allState/getOrders", {
        departmentId: this.stationID,
        status: this.orderStatusSelected,
      });
    },
    handleItemsPerPageChange(pageSize) {
      this.pageSize = pageSize;
      this.$store.dispatch("allState/getOrders", {
        departmentId: this.stationID,
        status: this.orderStatusSelected,
      });
    },
    onHandleOrder(order) {
      switch (order.status) {
        case orderStatuses.OPEN:
          order.isCustomOrder
            ? this.$store.dispatch("orders/setCustomOrderToEdit", order)
            : this.$store.dispatch("orders/setCustomOrderToEdit", null);

          this.$router.push(`/${order.department.id}/edit-order/${order.id}`);
          break;
        case orderStatuses.IN_PROGRESS:
        case orderStatuses.INCOMPLETE:
          this.$store.dispatch(
            order.isCustomOrder
              ? "allState/getCustomOrder"
              : "allState/getOrder",
            order.id
          );

          this.orderReceiveObject = order;
          this.showReceiveOrderModal = true;
          break;
      }
    },
    handleChangeOrderDate(order) {
      this.orderToChangeDate = order;
      this.isChangeOrderDateModalOpened = true;
      // this.$store
      //   .dispatch(
      //     order.isCustomOrder ? "orders/getCustomOrder" : "orders/getOrder",
      //     order.id
      //   )
      //   .then((response) => {
      //     const responseOrder = response.data;
      //     if (order.isCustomOrder) {
      //       responseOrder.isCustomOrder = true;
      //     }
      //     this.orderToChangeDate = responseOrder;
      //     this.isChangeOrderDateModalOpened = true;
      //   });
    },
    handleOnCloseChangeOrderModal() {
      this.orderToChangeDate = null;
      this.isChangeOrderDateModalOpened = false;
    },
    handleOnUpdatedOrderDate() {
      this.showPassDueWarningModal = false;
      this.$store.dispatch("allState/getOrders", {
        departmentId: this.stationID,
        status: this.orderStatusSelected,
      });
      setTimeout(() => {
        this.orderToChangeDate = null;
        this.isChangeOrderDateModalOpened = false;
        this.showPassDueWarningModal = false;
        EventBus.dispatch("showSnackbar", {
          text: this.$t("notifications.orderDateSuccessfullyUpdated"),
        });
      }, 200);
    },
    handleOnCloseOrderDueWarningModal() {
      this.showPassDueWarningModal = false;
    },
    handleOnOrderArrivedLaterUpdated() {
      this.showReceiveOrderModal = false;
      this.showPassDueWarningModal = false;
      this.$store.dispatch("allState/getOrders", {
        departmentId: this.stationID,
        status: this.orderStatusSelected,
      });
    },
    loadTourSteps() {
      this.steps = [
        {
          target: "#step-6",
          header: {
            title: this.$t("tourSteps.step6Title"),
          },
          content: this.$t("tourSteps.step6Content"),
        },
        {
          target: "#step-7",
          header: {
            title: this.$t("tourSteps.step7Title"),
          },
          content: this.$t("tourSteps.step7Content"),
        },
        {
          target: "#step-8",
          header: {
            title: this.$t("tourSteps.step8Title"),
          },
          content: this.$t("tourSteps.step8Content"),
        },
        {
          target: "#step-9",
          header: {
            title: this.$t("tourSteps.step9Title"),
          },
          content: this.$t("tourSteps.step9Content"),
        },
      ];
      this.myOptions = {
        useKeyboardNavigation: true,
        labels: {
          buttonSkip: this.$t("tourSteps.labelSkip"),
          buttonPrevious: this.$t("tourSteps.labelPrevious"),
          buttonNext: this.$t("tourSteps.labelNext"),
          buttonStop: this.$t("tourSteps.labelStop"),
        },
      };
    },
  },
  computed: {
    ...mapGetters({
      getOrders: "allState/getAllOrdersState",
      getVenue: "allState/getVenue",
      totalOrdersCount: "allState/getTotalOrdersCount",
      getAllOrdersInProgressDueDate: "allState/getAllOrdersInProgressDueDate",
      getIsOrdersDataLoading: "allState/getIsOrdersDataLoading",
      getIsTourDisabled: "allState/getIsTourDisabled",
      user: "allState/getUser",
    }),
    getOrdersState() {
      return this.getOrders;
    },
    allOrders: {
      get: function () {
        return this.getAllOrders;
      },
      set: function (newValue) {
        this.getAllOrders = newValue;
      },
    },
    allOrdersInProgressAndDueDate() {
      return this.getAllOrdersInProgressDueDate;
    },
    noOrders() {
      return this.totalOrdersCount === 0;
    },

    getTabIndex() {
      return this.tabIndex;
    },
    getIsMobile() {
      return this.$isMobile;
    },
    venue() {
      return this.getVenue;
    },
    isBasicPlan() {
      return this.$attrs.isBasicPlan;
    },
  },
  watch: {
    getIsOrdersDataLoading() {
      this.getAllOrders = this.getOrdersState;
      this.processOrderSuppliers();
    },
    getTabIndex() {
      if (this.getTabIndex === 1) {
        this.headers = this.headers.filter((f) => f.value !== "statusText");
        this.allOrders =
          this.getOrders &&
          this.getOrders.filter((f) => f.status === orderStatuses.OPEN);
      } else if (this.getTabIndex === 2) {
        this.headers = this.headers.filter((f) => f.value !== "statusText");
        this.allOrders =
          this.getOrders &&
          this.getOrders.filter(
            (f) =>
              f.status === orderStatuses.IN_PROGRESS ||
              f.status === orderStatuses.INCOMPLETE
          );
      }
      this.$forceUpdate();
    },
    getIsTourDisabled() {
      if (this.getIsTourDisabled) {
        setTimeout(() => {
          this.$tours["myTourCurrentOrders"].stop();
        }, 300);
      } else {
        setTimeout(() => {
          this.$tours["myTourCurrentOrders"].start();
        }, 300);
      }
    },
    user: {
      handler() {
        this.setTableHeaders();
        this.setTabNames();
        this.$store.dispatch("allState/getOrders", {
          departmentId: this.stationID,
          status: this.orderStatusSelected,
        });
        this.loadTourSteps();
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.root {
  padding: 0 32px 32px 32px;
  @media screen and (max-width: 959px) {
    padding: 8px;
  }
}
.create-button {
  text-transform: none !important;
}
.button-plus {
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  background-color: #276ef1;
  cursor: pointer;
  border-radius: 50%;
  &:hover {
    box-shadow: 1px 1px 11px 1px #276ef1;
  }
}
.padding-adjust {
  padding: 32px;
  margin-top: 32px;
  @media screen and (max-width: 575px) {
    padding: 8px;
  }
}
.hidden-field {
  position: absolute;
  opacity: 0;
}
</style>
