<template>
  <div class="root">
    <v-overlay :value="true" color="secondaryDark">
      <v-progress-circular
        :size="70"
        :width="7"
        color="accent"
        indeterminate
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
export default {
  name: "LoaderComponent",
};
</script>
<style lang="scss" scoped>
.root {
  width: 100%;
  position: absolute;
  z-index: 9999999;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 45vh;
}
</style>
