<template>
  <v-container>
    <v-img
      class="ma-auto mb-118 mt-8"
      max-width="227"
      :src="require('../assets/img/NavLogo.png')"
    >
    </v-img>
    <v-row justify="center">
      <v-col cols="12" sm="8" md="6">
        <v-card class="pa-5" elevation="2">
          <v-card-title class="headline">
            {{ $t("welcomeView.title") }}
          </v-card-title>
          <v-card-text>
            {{ $t("welcomeView.description") }}
          </v-card-text>
          <div class="d-flex justify-center align-center">
            <v-checkbox v-model="checkedTerms"> </v-checkbox>
            <span>{{ $t("registerForm.accept") }}</span>
            <v-btn
              style="margin-left: -8px; margin-bottom: 2px"
              color="accent"
              plain
              text
              @click="dialog = true"
              >{{ $t("registerForm.termsAndConditions") }}</v-btn
            >
          </div>

          <v-card-actions>
            <v-row justify="center">
              <v-btn
                :disabled="!checkedTerms"
                color="primary"
                @click="exploreApp"
                >{{ $t("welcomeView.enter") }}</v-btn
              ></v-row
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <terms-and-policies-modal
      :dialog="dialog"
      @onClose="dialog = false"
      :type="'termsAndConditions'"
    ></terms-and-policies-modal>
  </v-container>
</template>
<script>
import TermsAndPoliciesModal from "../components/TermsAndPoliciesModal.vue";
import emailValidator from "../shared/Shared";
export default {
  name: "WelcomePage",
  components: { TermsAndPoliciesModal },
  data() {
    return {
      loginLoading: false,
      checkedTerms: false,
      dialog: false,
      emailRules: [],
      email: "",
    };
  },
  created() {},
  methods: {
    exploreApp() {
      // if (this.email && this.emailRules.length === 0) {
      //   window.gtag("event", "user_inserted_email", {
      //     event_category: "Users",
      //     event_label: "Email address",
      //     value: this.email,
      //   });
      // }
      this.$router.push({ name: "Dashboard" });
    },
  },
  computed: {
    emailValue() {
      return this.email;
    },
  },
  watch: {
    emailValue() {
      const invalidEmail = emailValidator(this.emailValue);
      this.emailRules =
        this.emailValue.length > 0 && invalidEmail
          ? []
          : this.emailValue.length === 0
          ? []
          : [this.$t("common.invalidEmailInput")];
    },
  },
};
</script>

<style type="scss" scoped>
.mb-118 {
  margin-bottom: 118px !important;
}
.mt-50 {
  margin-top: 50px !important;
}
.forgot-pass-link {
  cursor: pointer;
}
.text-align-center {
  text-align: center;
}
.forgot-pass-link:hover {
  text-decoration: underline;
}
.register-here-link {
  cursor: pointer;
}
.register-here-link:hover {
  text-decoration: underline;
}
.noAccountTextWrapper {
  position: relative;
}
.noAccountTextWrapper:before {
  position: absolute;
  content: "";
  left: 0;
  top: 10px;
  height: 1px;
  background: #e0e0e0;
  width: 75px;
}
.noAccountTextWrapper:after {
  position: absolute;
  content: "";
  right: 0;
  top: 10px;
  height: 1px;
  background: #e0e0e0;
  width: 75px;
}
.mt-32 {
  margin-top: 32px !important;
}
.email-success-text-wrap {
  text-align: center;
}
</style>
