<template>
  <v-dialog
    v-model="showDialog"
    width="626"
    class="dialog-style"
    persistent
    :fullscreen="getIsMobile"
  >
    <div class="dialog-style-content">
      <div class="header mb-6">
        <div class="d-flex justify-space-between align-center">
          <span class="text-h5 font-weight-black">{{
            $t(
              ordersDue && ordersDue.length > 1
                ? "orderDueWarning.titlePlural"
                : "orderDueWarning.title"
            )
          }}</span>
          <v-img
            @click="closePreviewDialog"
            class="close-icon"
            max-width="20px"
            :src="require('../assets/img/CloseIcon.svg')"
          >
          </v-img>
        </div>
      </div>

      <span class="text-caption mt-2 secondaryDark--text font-weight-black">{{
        $t("orderDueWarning.description")
      }}</span>
      <div v-if="ordersDue" class="mt-5 width100">
        <v-data-table
          :headers="headers"
          :items="ordersDue"
          :items-per-page="5"
          :disable-pagination="true"
          :hide-default-footer="true"
          class="elevation-1"
        >
          <template v-slot:item.departmentName="{ item }">
            <span>{{ item.department.name }}</span>
          </template>
          <template v-slot:item.supplierName="{ item }">
            <span>{{
              item.supplier ? item.supplier.name : $t("articles.market")
            }}</span>
          </template>
          <template v-slot:item.createdByName="{ item }">
            <span>{{ item.createdBy.name }}</span>
          </template>
          <template v-slot:item.action="{ item }">
            <div class="d-flex pt-1" style="width: 100%">
              <v-btn
                small
                dark
                color="accent"
                @click="handleChangeOrderDate(item)"
                class="mr-2"
                >{{ $t("orderDueWarning.changeShippingOrderDate") }}</v-btn
              >
              <v-btn small dark color="accent" @click="handleOrder(item)">{{
                $t("orderDueWarning.handle")
              }}</v-btn>
            </div>
          </template>
        </v-data-table>
      </div>
    </div>
  </v-dialog>
</template>
<script>
import ModalComponent from "../components/ModalComponent.vue";
export default {
  name: "OrderDueWarningModal",
  components: { ModalComponent },
  props: {
    dialog: {},
    ordersDue: {},
  },
  data: () => ({
    headers: [],
    showDialog: false,
  }),
  created() {
    this.showDialog = this.dialog;
  },
  mounted() {
    this.headers = [
      {
        text: this.$t("orderDueWarning.station"),
        value: "departmentName",
        sortable: false,
      },
      {
        text: this.$t("orderDueWarning.supplier"),
        value: "supplierName",
        sortable: false,
      },
      {
        text: this.$t("orderDueWarning.createdBy"),
        value: "createdByName",
        sortable: false,
      },
      {
        text: "",
        value: "action",
        sortable: false,
      },
    ];
  },
  methods: {
    closePreviewDialog() {
      this.$emit("onClose");
    },
    handleOrder(order) {
      this.$emit("handleOrder", order);
    },
    cancelOrder() {
      this.$emit("onClose");
    },
    handleChangeOrderDate(order) {
      this.$emit("onChangeOrderDate", order);
    },
  },
  computed: {
    getIsMobile() {
      return this.$isMobile;
    },
  },
  watch: {
    dialog() {
      this.showDialog = this.dialog;
    },
  },
};
</script>
<style scoped lang="scss">
.dialog-style {
  background-color: #ffffff;
  padding: 24px;
  @media screen and (max-width: 959px) {
    height: 100%;
  }
}
.dialog-style-content {
  background-color: #ffffff;
  padding: 24px;
  height: 100%;
}
.info-text {
  text-align: center;
}
.header {
  position: relative;
}
.margin-adjust-button {
  margin-left: 0 !important;
  margin-top: 12px;
}
.remove-uppercase {
  text-transform: none !important;
}
.width100 {
  width: 100%;
}
.close-icon {
  cursor: pointer;
  width: 18px;
}
</style>
