<template>
  <nav>
    <!-- app bar (big screens) -->
    <v-app-bar
      v-if="!getIsMobile"
      app
      height="61"
      color="white"
      class="app-bar-box-shadow d-none d-md-block"
    >
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        class="hidden-md-and-up"
      ></v-app-bar-nav-icon>
      <v-img
        style="cursor: pointer"
        @click="goDashboard"
        max-width="122"
        :src="require('../assets/img/NavLogo.png')"
      >
      </v-img>
      <span class="text-h6 font-weight-black primaryDark--text mt-1 ml-1"
        >- DEMO</span
      >
      <v-spacer></v-spacer>
      <div class="d-flex justify-right align-center">
        <v-btn @click="handleLanguageChange" text x-small>{{
          selectedLanguage === "en"
            ? $t("languages.serbian")
            : $t("languages.english")
        }}</v-btn>
        <v-btn id="step-31" icon @click="goToRealApp">
          <v-icon>mdi-login</v-icon>
        </v-btn>
        <v-btn
          id="step-30"
          v-if="!getIsTourDisabled"
          icon
          @click="onDisableTour"
        >
          <v-icon>mdi-toggle-switch</v-icon>
        </v-btn>
        <v-btn id="step-30" v-else icon @click="onEnableTour">
          <v-icon>mdi-toggle-switch-off-outline</v-icon>
        </v-btn>
      </div>
      <span class="text-subtitle-2 mr-5">{{ profile && profile.name }}</span>
      <v-toolbar-items class="hidden-sm-and-down">
        <profile-menu :items="navDropDownOptions"></profile-menu>
      </v-toolbar-items>
    </v-app-bar>
    <!-- END app bar (big screens) -->
    <!-- app bar user mobile -->
    <v-app-bar
      v-else
      app
      height="61"
      color="white"
      class="app-bar-box-shadow d-block d-md-none"
    >
      <div class="d-flex align-center justify-space-between width100">
        <div class="d-flex align-center">
          <v-img
            id="step-0"
            @click="goDashboard"
            max-width="122"
            :src="require('../assets/img/NavLogo.png')"
            class="adjust-logo-margin"
          ></v-img>
        </div>
        <v-spacer></v-spacer>
        <div class="d-flex align-center">
          <v-btn @click="handleLanguageChange" text x-small>{{
            selectedLanguage === "en"
              ? $t("languages.serbian")
              : $t("languages.english")
          }}</v-btn>
          <v-btn id="step-31" icon @click="goToRealApp">
            <v-icon>mdi-login</v-icon>
          </v-btn>
          <v-btn
            id="step-30"
            v-if="!getIsTourDisabled"
            icon
            @click="onDisableTour"
          >
            <v-icon>mdi-toggle-switch</v-icon>
          </v-btn>
          <v-btn id="step-30" v-else icon @click="onEnableTour">
            <v-icon>mdi-toggle-switch-off-outline</v-icon>
          </v-btn>
        </div>
        <v-app-bar-nav-icon
          id="step-5"
          @click.stop="drawer = !drawer"
          class="hidden-md-and-up"
        ></v-app-bar-nav-icon>
      </div>
    </v-app-bar>
    <!-- END app bar user mobile -->

    <!-- Start of mobile side menu -->
    <v-navigation-drawer app v-model="drawer" right class="drawer-class">
      <v-list>
        <v-list-item>
          <v-list-item-content
            class="d-flex align-center justify-center text-subtitle-2 secondaryDark2--text border-bottom"
          >
            {{ profile && profile.name }}
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          class="d-flex align-center"
          v-for="(item, index) in getNavDropDownOptions"
          :key="item.id"
          :to="item.path"
          :disabled="item.disabled"
          :active="!item.disabled"
        >
          <v-list-item-icon class="mr-0 icon-margin-adjust">
            <v-img max-width="12" :src="sidebarImages[index]"> </v-img>
          </v-list-item-icon>
          <v-list-item-content class="text-subtitle-1 font-weight-bold">{{
            item.name
          }}</v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <!-- End of mobile side menu -->
  </nav>
</template>
<script>
import ProfileMenu from "./ProfileMenu.vue";
import HomeSideBarIcon from "../assets/img/HomeSideBarIcon.svg";
import NewOrderSideBarIcon from "../assets/img/NewOrderSideBarIcon.svg";
import CurrentOrderSideBarIcon from "../assets/img/CurrentOrderSideBarIcon.svg";
import { mapGetters } from "vuex";

export default {
  name: "TopNav",
  props: {
    isAuthenticated: Boolean,
    getUser: {},
    venueId: {},
  },
  components: { ProfileMenu },

  data() {
    return {
      drawer: false, // Hide mobile side menu by default
      navDropDownOptionsMobile: [],
      navDropDownOptions: [],
      HomeSideBarIcon: HomeSideBarIcon,
      NewOrderSideBarIcon: NewOrderSideBarIcon,
      CurrentOrderSideBarIcon: CurrentOrderSideBarIcon,
      sidebarImages: [
        HomeSideBarIcon,
        NewOrderSideBarIcon,
        CurrentOrderSideBarIcon,
      ],
      tourDisabled: false,
      selectedLanguage: "ser",
    };
  },
  created() {
    this.setNavDropDownOptions();
  },
  mounted() {},
  methods: {
    handleLanguageChange() {
      this.selectedLanguage = this.selectedLanguage === "en" ? "ser" : "en";
      this.$root.$i18n.locale = this.selectedLanguage;
      this.$store.dispatch(
        "allState/setSelectedLanguage",
        this.selectedLanguage
      );
    },
    goToRealApp() {
      window.gtag("event", "went_to_full_aplication", {
        event_category: "Navigation",
        event_label: "To full application",
      });

      window.open("https://app.supplyme.rs/sign-up", "_blank");
    },
    onDisableTour() {
      window.gtag("event", "disabled_tour", {
        event_category: "Navigation",
        event_label: "Disabled tour",
      });
      this.$store.dispatch("allState/disableTour", true);
    },
    onEnableTour() {
      window.gtag("event", "enabled_tour", {
        event_category: "Navigation",
        event_label: "Enabled tour",
      });
      this.$store.dispatch("allState/disableTour", false);
    },
    logout() {
      // console.log("logout");
    },
    setNavDropDownOptions() {
      this.navDropDownOptions = [
        {
          id: 0,
          name: this.$t("navDropDown.home"),
          path: "/dashboard",
          show: true,
        },
        {
          id: 1,
          name: this.$t("navDropDown.admin"),
          path: this.getAdminRoute,
          show: false,
          disabled: true,
        },
        {
          id: 2,
          name: this.isAdmin
            ? this.$t("sidebarLinks.adminRestaurant")
            : this.$t("sidebarLinks.adminRestaurants"),
          path: this.isAdmin
            ? `/admin/${this.venueId}/restaurant`
            : "/admin/restaurants",
          show: false,
        },
        {
          id: 3,
          name: this.$t("sidebarLinks.adminSuppliers"),
          path: "/admin/suppliers",
          show: false,
        },
        {
          id: 4,
          name: this.$t("navDropDown.userProfile"),
          path: "/profile",
          show: false,
        },
        {
          id: 5,
          name: this.$t("sidebarLinks.adminUsers"),
          path: `/admin/users`,
          show: false,
        },
        {
          id: 6,
          name: this.$t("sidebarLinks.adminStations"),
          path: `/admin/${this.venueId}/stations`,
          show: false,
        },
        {
          id: 7,
          name: this.$t("sidebarLinks.adminArticles"),
          path: `/admin/articles`,
          show: false,
        },
        {
          id: 8,
          name: this.$t("navDropDown.articles"),
          path: "/articles",
          show: true,
        },
        {
          id: 9,
          name: this.$t("navDropDown.suppliers"),
          path: "/suppliers",
          show: true,
        },
        {
          id: 10,
          name: this.$t("navDropDown.help"),
          path: "/help",
          show: false,
          disabled: true,
        },
      ];
    },
    goDashboard() {
      this.$router.push("/");
    },
  },
  computed: {
    ...mapGetters({
      getIsTourDisabled: "allState/getIsTourDisabled",
      user: "allState/getUser",
    }),
    profile() {
      return {};
    },
    getNavDropDownOptions() {
      return this.navDropDownOptions?.filter((item) => item.show);
    },
    getIsMobile() {
      return this.$isMobile;
    },
  },
  watch: {
    getIsTourDisabled() {
      this.tourDisabled = this.getIsTourDisabled;
    },
    user: {
      handler() {
        this.setNavDropDownOptions();
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.app-bar-box-shadow {
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.04) !important;
}
.adjust-logo-margin {
}
.icon-margin-adjust {
  margin-top: 18px;
}
.logout-item {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
}
.text-align-center {
  justify-content: center;
}
.width100 {
  width: 100%;
}
.border-bottom {
  border-bottom: 1px solid #a6a6a6;
  margin-bottom: 8px;
}
</style>
