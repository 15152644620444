<template>
  <div class="padding-adjust">
    <div class="position-relative">
      <div class="restaurant-name-wrap">
        <span class="restaurant-name">{{
          $t("dashboard.restaurant", { name: venue && venue.name })
        }}</span>
      </div>
      <div class="restaurant-name-wrap-mobile">
        <span class="restaurant-name-mobile">{{
          $t("dashboard.restaurant", { name: venue && venue.name })
        }}</span>
      </div>
      <div
        v-if="stations && stations.length > 0"
        class="d-flex pl-8 mt-8 on-mobile"
      >
        <div class="select-wrapper">
          <span class="text-h6 primaryDark--text create-new-order-text"
            >{{ $t("currentOrders.createNewOrder") }}:</span
          >
          <v-select
            id="step-4"
            class="select-width"
            :items="stations"
            item-value="id"
            item-text="name"
            outlined
            filled
            color="accent"
            :label="$t('dashboard.chooseStation')"
            persistent-hint
            dense
            @change="onStationSelected"
          ></v-select>
        </div>
      </div>
      <div>
        <div class="mt-64 dashboard-title d-flex align-center">
          <span
            class="text-h4 font-weight-bold primaryA--text ml-8 small-screen-margin"
            >{{ $t("dashboard.categories") }}</span
          >
        </div>
        <v-layout>
          <div
            id="step-1"
            class="d-flex flex-wrap pl-8 pr-8 p-bottom-80 width-100 padding-mobile"
          >
            <station-card
              v-for="(station, index) in stations"
              :index="index"
              :station="station"
              :key="station.id"
            ></station-card>
          </div>
        </v-layout>
      </div>
    </div>
    <v-tour
      name="myTourDashboard"
      :steps="steps"
      :options="myOptions"
      :callbacks="myCallbacks"
    ></v-tour>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
//Components
import StationCard from "../components/StationCard.vue";
import LocalStorageService from "../services/local-storage.service";

export default {
  name: "DashboardPage",
  components: {
    StationCard,
  },
  data() {
    return {
      ordersDue: [],
      orderReceiveObject: {},
      timeout: null,
      venueExists: false,
      orderToRepeat: null,
      noStationsCreated: false,
      steps: [],
      myOptions: {},
      myCallbacks: {
        onSkip: this.handleOnSkip,
      },
    };
  },

  mounted() {
    this.loadTourSteps();
    setTimeout(() => {
      const isDisabled =
        LocalStorageService.getLocalStorageData("tourDisabled");
      this.$store.dispatch("allState/disableTour", isDisabled);
      if (isDisabled) {
        this.$tours["myTourDashboard"].stop();
      } else {
        this.$tours["myTourDashboard"].start();
      }
    }, 200);
  },
  created() {},
  computed: {
    ...mapGetters({
      getVenue: "allState/getVenue",
      getDepartments: "allState/getDepartments",
      getOrders: "allState/getAllOrdersState",
      getIsTourDisabled: "allState/getIsTourDisabled",
      user: "allState/getUser",
    }),
    venue() {
      return this.getVenue;
    },
    profile() {
      return {};
    },
    stations() {
      return this.getDepartments;
    },
  },
  methods: {
    handleOnSkip() {
      this.$store.dispatch("allState/disableTour", true);
    },
    onStationSelected(event) {
      window.gtag("event", "new_order_init_dashboard", {
        event_category: "Orders",
        event_label: "Create Order",
      });
      LocalStorageService.deleteLocalStorageItem("newOrderDraft");
      LocalStorageService.deleteLocalStorageItem("orderChanged");
      this.$router.push(`/${event}/new-order`);
    },
    loadTourSteps() {
      this.steps = [
        {
          target: "#step-0",
          header: {
            title: this.$t("tourSteps.step0Title"),
          },
          content: this.$t("tourSteps.step0Content"),
        },
        {
          target: "#step-1",
          header: {
            title: this.$t("tourSteps.step1Title"),
          },
          content: this.$t("tourSteps.step1Content"),
        },
        {
          target: "#step-2",
          header: {
            title: this.$t("tourSteps.step2Title"),
          },
          content: this.$t("tourSteps.step2Content"),
        },
        {
          target: "#step-3",
          header: {
            title: this.$t("tourSteps.step3Title"),
          },
          content: this.$t("tourSteps.step3Content"),
        },
        {
          target: "#step-4",
          header: {
            title: this.$t("tourSteps.step4Title"),
          },
          content: this.$t("tourSteps.step4Content"),
        },
        {
          target: "#step-5",
          header: {
            title: this.$t("tourSteps.step5Title"),
          },
          content: this.$t("tourSteps.step5Content"),
        },
        {
          target: "#step-30",
          header: {
            title: this.$t("tourSteps.step30Title"),
          },
          content: this.$t("tourSteps.step30Content"),
        },
        {
          target: "#step-31",
          header: {
            title: this.$t("tourSteps.step31Title"),
          },
          content: this.$t("tourSteps.step31Content"),
        },
      ];
      this.myOptions = {
        useKeyboardNavigation: true,
        labels: {
          buttonSkip: this.$t("tourSteps.labelSkip"),
          buttonPrevious: this.$t("tourSteps.labelPrevious"),
          buttonNext: this.$t("tourSteps.labelNext"),
          buttonStop: this.$t("tourSteps.labelStop"),
        },
      };
    },
  },
  watch: {
    getIsTourDisabled() {
      if (this.getIsTourDisabled) {
        this.$tours["myTourDashboard"].stop();
      } else {
        this.$tours["myTourDashboard"].start();
      }
    },
    user: {
      handler() {
        this.loadTourSteps();
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.mt-64 {
  margin-top: 64px;
  @media screen and (max-width: 960px) {
    margin-top: 0 !important;
  }
}
.v-calendar-daily__body {
  display: none !important;
}
.calendar-wrap {
  background: #fafafa;
  padding: 32px;
  @media screen and (max-width: 960px) {
    padding: 8px !important;
  }
}
.p-bottom-80 {
  padding-bottom: 80px;
}
.dashboard-title {
  white-space: nowrap;
  margin-left: 14px;
  @media screen and (min-width: 960px) {
    margin-left: 0 !important;
  }
  @media screen and (max-width: 959px) {
    margin-left: 0 !important;
  }
}
.small-screen-margin {
  @media screen and (max-width: 960px) {
    margin-left: 8px !important;
    font-size: 18px !important;
  }
}
.no-venue-wrap {
  text-align: center;
  @media screen and(min-width:1023px) {
    margin: 0 auto;
    padding: 0 400px;
  }
}
.width-100 {
  width: 100%;
}
.add-new-btn {
  background: #276ef1;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.position-relative {
  position: relative;
}
.restaurant-name-wrap {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 4px;
  @media screen and (max-width: 1023px) {
    display: none;
  }
}
.restaurant-name {
  position: absolute;
  right: 32px;
  font-size: 32px;
  font-weight: 700;
  color: #565961;
  opacity: 0.5;
  @media screen and (max-width: 575px) {
    left: 32px;
  }
}
.restaurant-name-wrap-mobile {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: -32px;
  margin-bottom: 32px;
  @media screen and (min-width: 1024px) {
    display: none;
  }
}
.restaurant-name-mobile {
  // position: absolute;
  font-size: 20px;
  font-weight: 500;
  color: #565961;
  opacity: 0.7;
  // top: 0;
  // left: 16px;
}
.padding-mobile {
  @media screen and (max-width: 959px) {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
}
.select-width {
  max-width: 315px;
  @media screen and (max-width: 575px) {
    max-width: 60%;
  }
}
.logo-cart-img {
  opacity: 0.7;
}
.logo-img-wrapper {
  width: 160px;
  height: 160px;
  border-radius: 50%;
  border: 10px solid #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 1px 1px 11px 4px #616b77b8;
}
.padding-adjust {
  padding: 32px 0 !important;
  @media screen and (max-width: 959px) {
    padding: 64px 0 !important;
  }
}
.select-wrapper {
  width: 100%;
}
.cursor-pointer {
  cursor: pointer;
}
.on-mobile {
  @media screen and (max-width: 959px) {
    padding-left: 8px !important;
    margin-top: 8px !important;
  }
}
.create-new-order-text {
  font-size: 1.25rem;
  @media screen and (max-width: 575px) {
    font-size: 1.1rem !important;
  }
}
</style>
