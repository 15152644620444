const allArticles = [
  {
    departmentId: "d162cffb-acd5-4f8a-94fe-f5e026f99307",
    suppliers: [
      {
        supplierId: "3d927cf4-18cf-48e0-a850-78c23859f56a",
        articles: [
          {
            id: "71ff3c13-5533-487a-b82d-cb7fedff36a4",
            sku: "GRE-H-VEI-AK-2",
            name: "ANANAS KG",
            category: {
              id: "95527dc5-8687-485d-999b-36a95a6a7b61",
              name: "Voće i povrće",
            },
            supplier: {
              id: "3d927cf4-18cf-48e0-a850-78c23859f56a",
              name: "Dobavljač 2",
              pib: "123412412",
              address: "alfj",
              postNumber: "lkjdf",
              city: "lksjlskdjgl",
              email: "sdsg",
              isRestaurant: null,
              phone: "lksdgjs",
              registrationNumber: "12312314",
              bankAccountNumber: "slkdgjs",
              hasData: true,
            },
            updatedAt: "2023-03-02T22:07:36.024Z",
            isFavorite: false,
          },
          {
            id: "fcbcc852-1776-4b8b-be2f-0056d8f38d4d",
            sku: "GRE-H-VEI-AKK-3",
            name: "ANIS-KOMORAC KG",
            category: {
              id: "95527dc5-8687-485d-999b-36a95a6a7b61",
              name: "Voće i povrće",
            },
            supplier: {
              id: "3d927cf4-18cf-48e0-a850-78c23859f56a",
              name: "Dobavljač 2",
              pib: "123412412",
              address: "alfj",
              postNumber: "lkjdf",
              city: "lksjlskdjgl",
              email: "sdsg",
              isRestaurant: null,
              phone: "lksdgjs",
              registrationNumber: "12312314",
              bankAccountNumber: "slkdgjs",
              hasData: true,
            },
            updatedAt: "2023-03-02T22:07:36.017Z",
            isFavorite: false,
          },
          {
            id: "89c0b751-ef90-4c74-ba4d-735200ac183b",
            sku: "GRE-H-VEI-AK-4",
            name: "AVOKADO KG",
            category: {
              id: "95527dc5-8687-485d-999b-36a95a6a7b61",
              name: "Voće i povrće",
            },
            supplier: {
              id: "3d927cf4-18cf-48e0-a850-78c23859f56a",
              name: "Dobavljač 2",
              pib: "123412412",
              address: "alfj",
              postNumber: "lkjdf",
              city: "lksjlskdjgl",
              email: "sdsg",
              isRestaurant: null,
              phone: "lksdgjs",
              registrationNumber: "12312314",
              bankAccountNumber: "slkdgjs",
              hasData: true,
            },
            updatedAt: "2023-03-02T22:07:36.025Z",
            isFavorite: false,
          },
          {
            id: "ca7cd305-5733-4bbd-9780-0bd5d0a4d7a5",
            sku: "GRE-H-VEI-BSK-5",
            name: "BABY SPANAC KG",
            category: {
              id: "95527dc5-8687-485d-999b-36a95a6a7b61",
              name: "Voće i povrće",
            },
            supplier: {
              id: "3d927cf4-18cf-48e0-a850-78c23859f56a",
              name: "Dobavljač 2",
              pib: "123412412",
              address: "alfj",
              postNumber: "lkjdf",
              city: "lksjlskdjgl",
              email: "sdsg",
              isRestaurant: null,
              phone: "lksdgjs",
              registrationNumber: "12312314",
              bankAccountNumber: "slkdgjs",
              hasData: true,
            },
            updatedAt: "2023-03-02T22:07:36.015Z",
            isFavorite: false,
          },
          {
            id: "d345709b-b94b-418c-a9d7-16af8e10f606",
            sku: "GRE-H-VEI-BK-6",
            name: "BANANA KG",
            category: {
              id: "95527dc5-8687-485d-999b-36a95a6a7b61",
              name: "Voće i povrće",
            },
            supplier: {
              id: "3d927cf4-18cf-48e0-a850-78c23859f56a",
              name: "Dobavljač 2",
              pib: "123412412",
              address: "alfj",
              postNumber: "lkjdf",
              city: "lksjlskdjgl",
              email: "sdsg",
              isRestaurant: null,
              phone: "lksdgjs",
              registrationNumber: "12312314",
              bankAccountNumber: "slkdgjs",
              hasData: true,
            },
            updatedAt: "2023-03-02T22:07:36.013Z",
            isFavorite: false,
          },
          {
            id: "c4769efd-60e0-4e1b-89d2-a48512f44757",
            sku: "GRE-H-VEI-BK-7",
            name: "BATAT KG",
            category: {
              id: "95527dc5-8687-485d-999b-36a95a6a7b61",
              name: "Voće i povrće",
            },
            supplier: {
              id: "3d927cf4-18cf-48e0-a850-78c23859f56a",
              name: "Dobavljač 2",
              pib: "123412412",
              address: "alfj",
              postNumber: "lkjdf",
              city: "lksjlskdjgl",
              email: "sdsg",
              isRestaurant: null,
              phone: "lksdgjs",
              registrationNumber: "12312314",
              bankAccountNumber: "slkdgjs",
              hasData: true,
            },
            updatedAt: "2023-03-02T22:07:36.037Z",
            isFavorite: false,
          },
          {
            id: "6959ba04-cd32-4f91-99bc-b61fcda3c2cf",
            sku: "GRE-H-VEI-BZK-8",
            name: "BELA ZELEN KG",
            category: {
              id: "95527dc5-8687-485d-999b-36a95a6a7b61",
              name: "Voće i povrće",
            },
            supplier: {
              id: "3d927cf4-18cf-48e0-a850-78c23859f56a",
              name: "Dobavljač 2",
              pib: "123412412",
              address: "alfj",
              postNumber: "lkjdf",
              city: "lksjlskdjgl",
              email: "sdsg",
              isRestaurant: null,
              phone: "lksdgjs",
              registrationNumber: "12312314",
              bankAccountNumber: "slkdgjs",
              hasData: true,
            },
            updatedAt: "2023-03-02T22:07:36.015Z",
            isFavorite: false,
          },
          {
            id: "b345d3a9-c469-4b1f-98f4-cef6e35b7b22",
            sku: "GRE-H-VEI-BVK-9",
            name: "BLITVA VEZA KOM",
            category: {
              id: "95527dc5-8687-485d-999b-36a95a6a7b61",
              name: "Voće i povrće",
            },
            supplier: {
              id: "3d927cf4-18cf-48e0-a850-78c23859f56a",
              name: "Dobavljač 2",
              pib: "123412412",
              address: "alfj",
              postNumber: "lkjdf",
              city: "lksjlskdjgl",
              email: "sdsg",
              isRestaurant: null,
              phone: "lksdgjs",
              registrationNumber: "12312314",
              bankAccountNumber: "slkdgjs",
              hasData: true,
            },
            updatedAt: "2023-03-02T22:07:36.014Z",
            isFavorite: false,
          },
          {
            id: "808e0245-3019-4e2b-99c6-1d99ceeff115",
            sku: "GRE-H-VEI-BK-10",
            name: "BOSILJAK KG",
            category: {
              id: "95527dc5-8687-485d-999b-36a95a6a7b61",
              name: "Voće i povrće",
            },
            supplier: {
              id: "3d927cf4-18cf-48e0-a850-78c23859f56a",
              name: "Dobavljač 2",
              pib: "123412412",
              address: "alfj",
              postNumber: "lkjdf",
              city: "lksjlskdjgl",
              email: "sdsg",
              isRestaurant: null,
              phone: "lksdgjs",
              registrationNumber: "12312314",
              bankAccountNumber: "slkdgjs",
              hasData: true,
            },
            updatedAt: "2023-03-02T22:07:35.993Z",
            isFavorite: false,
          },
          {
            id: "803a3c9e-f836-4f83-b1de-de45431c494f",
            sku: "GRE-H-VEI-BK-11",
            name: "BRESKVA KG",
            category: {
              id: "95527dc5-8687-485d-999b-36a95a6a7b61",
              name: "Voće i povrće",
            },
            supplier: {
              id: "3d927cf4-18cf-48e0-a850-78c23859f56a",
              name: "Dobavljač 2",
              pib: "123412412",
              address: "alfj",
              postNumber: "lkjdf",
              city: "lksjlskdjgl",
              email: "sdsg",
              isRestaurant: null,
              phone: "lksdgjs",
              registrationNumber: "12312314",
              bankAccountNumber: "slkdgjs",
              hasData: true,
            },
            updatedAt: "2023-03-02T22:07:35.991Z",
            isFavorite: false,
          },
          {
            id: "38af96e5-e604-419b-a0b7-7bc1bd65d81f",
            sku: "GRE-H-VEI-BK-12",
            name: "BROKOLI KG",
            category: {
              id: "95527dc5-8687-485d-999b-36a95a6a7b61",
              name: "Voće i povrće",
            },
            supplier: {
              id: "3d927cf4-18cf-48e0-a850-78c23859f56a",
              name: "Dobavljač 2",
              pib: "123412412",
              address: "alfj",
              postNumber: "lkjdf",
              city: "lksjlskdjgl",
              email: "sdsg",
              isRestaurant: null,
              phone: "lksdgjs",
              registrationNumber: "12312314",
              bankAccountNumber: "slkdgjs",
              hasData: true,
            },
            updatedAt: "2023-03-02T22:07:35.989Z",
            isFavorite: false,
          },
          {
            id: "94f5fbde-7f80-4199-b3eb-4c97a8b38772",
            sku: "GRE-H-VEI-B4GK-14",
            name: "BUKOVACA 400 GR. KOM",
            category: {
              id: "95527dc5-8687-485d-999b-36a95a6a7b61",
              name: "Voće i povrće",
            },
            supplier: {
              id: "3d927cf4-18cf-48e0-a850-78c23859f56a",
              name: "Dobavljač 2",
              pib: "123412412",
              address: "alfj",
              postNumber: "lkjdf",
              city: "lksjlskdjgl",
              email: "sdsg",
              isRestaurant: null,
              phone: "lksdgjs",
              registrationNumber: "12312314",
              bankAccountNumber: "slkdgjs",
              hasData: true,
            },
            updatedAt: "2023-03-02T22:07:35.994Z",
            isFavorite: false,
          },
          {
            id: "d452245f-b9a8-4cb8-9755-6286a2b2e349",
            sku: "GRE-H-VEI-BK-13",
            name: "BUKOVACA KG",
            category: {
              id: "95527dc5-8687-485d-999b-36a95a6a7b61",
              name: "Voće i povrće",
            },
            supplier: {
              id: "3d927cf4-18cf-48e0-a850-78c23859f56a",
              name: "Dobavljač 2",
              pib: "123412412",
              address: "alfj",
              postNumber: "lkjdf",
              city: "lksjlskdjgl",
              email: "sdsg",
              isRestaurant: null,
              phone: "lksdgjs",
              registrationNumber: "12312314",
              bankAccountNumber: "slkdgjs",
              hasData: true,
            },
            updatedAt: "2023-03-02T22:07:35.992Z",
            isFavorite: false,
          },
          {
            id: "88389dcd-b6b6-40ee-b67a-1f0dbe21845b",
            sku: "GRE-H-VEI-CK-15",
            name: "CELER KG",
            category: {
              id: "95527dc5-8687-485d-999b-36a95a6a7b61",
              name: "Voće i povrće",
            },
            supplier: {
              id: "3d927cf4-18cf-48e0-a850-78c23859f56a",
              name: "Dobavljač 2",
              pib: "123412412",
              address: "alfj",
              postNumber: "lkjdf",
              city: "lksjlskdjgl",
              email: "sdsg",
              isRestaurant: null,
              phone: "lksdgjs",
              registrationNumber: "12312314",
              bankAccountNumber: "slkdgjs",
              hasData: true,
            },
            updatedAt: "2023-03-02T22:07:35.990Z",
            isFavorite: false,
          },
          {
            id: "381214fa-c582-475e-a0f7-71ee2186ae20",
            sku: "GRE-H-VEI-CLK-16",
            name: "CELER LIST KG",
            category: {
              id: "95527dc5-8687-485d-999b-36a95a6a7b61",
              name: "Voće i povrće",
            },
            supplier: {
              id: "3d927cf4-18cf-48e0-a850-78c23859f56a",
              name: "Dobavljač 2",
              pib: "123412412",
              address: "alfj",
              postNumber: "lkjdf",
              city: "lksjlskdjgl",
              email: "sdsg",
              isRestaurant: null,
              phone: "lksdgjs",
              registrationNumber: "12312314",
              bankAccountNumber: "slkdgjs",
              hasData: true,
            },
            updatedAt: "2023-03-02T22:07:35.990Z",
            isFavorite: false,
          },
          {
            id: "fdf5e914-e89c-49dc-b5cc-2be61b4160b6",
            sku: "GRE-H-VEI-CDK-17",
            name: "CRVENI DELISES KG",
            category: {
              id: "95527dc5-8687-485d-999b-36a95a6a7b61",
              name: "Voće i povrće",
            },
            supplier: {
              id: "3d927cf4-18cf-48e0-a850-78c23859f56a",
              name: "Dobavljač 2",
              pib: "123412412",
              address: "alfj",
              postNumber: "lkjdf",
              city: "lksjlskdjgl",
              email: "sdsg",
              isRestaurant: null,
              phone: "lksdgjs",
              registrationNumber: "12312314",
              bankAccountNumber: "slkdgjs",
              hasData: true,
            },
            updatedAt: "2023-03-02T22:07:35.995Z",
            isFavorite: false,
          },
          {
            id: "3c659987-10f4-49d5-9651-18f72f79c9b0",
            sku: "GRE-H-VEI-CK-18",
            name: "CVEKLA KG",
            category: {
              id: "95527dc5-8687-485d-999b-36a95a6a7b61",
              name: "Voće i povrće",
            },
            supplier: {
              id: "3d927cf4-18cf-48e0-a850-78c23859f56a",
              name: "Dobavljač 2",
              pib: "123412412",
              address: "alfj",
              postNumber: "lkjdf",
              city: "lksjlskdjgl",
              email: "sdsg",
              isRestaurant: null,
              phone: "lksdgjs",
              registrationNumber: "12312314",
              bankAccountNumber: "slkdgjs",
              hasData: true,
            },
            updatedAt: "2023-03-02T22:07:35.997Z",
            isFavorite: false,
          },
          {
            id: "97e5fb97-53e0-42fb-b716-fe9262735fd8",
            sku: "GRE-H-VEI-DK-19",
            name: "DINJA KG",
            category: {
              id: "95527dc5-8687-485d-999b-36a95a6a7b61",
              name: "Voće i povrće",
            },
            supplier: {
              id: "3d927cf4-18cf-48e0-a850-78c23859f56a",
              name: "Dobavljač 2",
              pib: "123412412",
              address: "alfj",
              postNumber: "lkjdf",
              city: "lksjlskdjgl",
              email: "sdsg",
              isRestaurant: null,
              phone: "lksdgjs",
              registrationNumber: "12312314",
              bankAccountNumber: "slkdgjs",
              hasData: true,
            },
            updatedAt: "2023-03-02T22:07:35.999Z",
            isFavorite: false,
          },
          {
            id: "32c74c9f-edcb-45e1-8b1a-2c2728611f7e",
            sku: "GRE-H-VEI-DK-20",
            name: "DJUMBIR KG",
            category: {
              id: "95527dc5-8687-485d-999b-36a95a6a7b61",
              name: "Voće i povrće",
            },
            supplier: {
              id: "3d927cf4-18cf-48e0-a850-78c23859f56a",
              name: "Dobavljač 2",
              pib: "123412412",
              address: "alfj",
              postNumber: "lkjdf",
              city: "lksjlskdjgl",
              email: "sdsg",
              isRestaurant: null,
              phone: "lksdgjs",
              registrationNumber: "12312314",
              bankAccountNumber: "slkdgjs",
              hasData: true,
            },
            updatedAt: "2023-03-02T22:07:35.998Z",
            isFavorite: false,
          },
          {
            id: "750f43ab-feaa-4e0e-a21b-b1c91e79756c",
            sku: "GRE-H-VEI-GCK-21",
            name: "GREJPFRUT CRVENI KG",
            category: {
              id: "95527dc5-8687-485d-999b-36a95a6a7b61",
              name: "Voće i povrće",
            },
            supplier: {
              id: "3d927cf4-18cf-48e0-a850-78c23859f56a",
              name: "Dobavljač 2",
              pib: "123412412",
              address: "alfj",
              postNumber: "lkjdf",
              city: "lksjlskdjgl",
              email: "sdsg",
              isRestaurant: null,
              phone: "lksdgjs",
              registrationNumber: "12312314",
              bankAccountNumber: "slkdgjs",
              hasData: true,
            },
            updatedAt: "2023-03-02T22:07:35.999Z",
            isFavorite: false,
          },
        ],
      },
      {
        supplierId: "5716a290-f009-4331-a641-949a14a71df2",
        articles: [
          {
            id: "06b46921-348b-4d5d-9b62-919c0d27156b",
            sku: "SIL-H-VEI-LCK-32",
            name: "LUK CRNI KG",
            category: {
              id: "95527dc5-8687-485d-999b-36a95a6a7b61",
              name: "Voće i povrće",
            },
            supplier: {
              id: "5716a290-f009-4331-a641-949a14a71df2",
              name: "Dobavljač 1",
              pib: "123123222",
              address: "Antifasisticke borbe 12",
              postNumber: "36000",
              city: "Kraljevo",
              email: "nikola@gmail.com",
              isRestaurant: null,
              phone: "01112312323",
              registrationNumber: "12312212",
              bankAccountNumber: "123-222222-33",
              hasData: true,
            },
            updatedAt: "2023-03-02T21:52:17.537Z",
            isFavorite: true,
          },
          {
            id: "0eeb0b5f-a312-4169-a663-28da1644094f",
            sku: "SIL-H-VEI-SK-71",
            name: "SPANAC KG",
            category: {
              id: "95527dc5-8687-485d-999b-36a95a6a7b61",
              name: "Voće i povrće",
            },
            supplier: {
              id: "5716a290-f009-4331-a641-949a14a71df2",
              name: "Dobavljač 1",
              pib: "123123222",
              address: "Antifasisticke borbe 12",
              postNumber: "36000",
              city: "Kraljevo",
              email: "nikola@gmail.com",
              isRestaurant: null,
              phone: "01112312323",
              registrationNumber: "12312212",
              bankAccountNumber: "123-222222-33",
              hasData: true,
            },
            updatedAt: "2023-03-02T21:52:17.581Z",
            isFavorite: true,
          },
          {
            id: "016bd326-dda1-4437-8be6-2d0e7a75d5d5",
            sku: "SIL-H-VEI-ZK-66",
            name: "ZELJE KG",
            category: {
              id: "95527dc5-8687-485d-999b-36a95a6a7b61",
              name: "Voće i povrće",
            },
            supplier: {
              id: "5716a290-f009-4331-a641-949a14a71df2",
              name: "Dobavljač 1",
              pib: "123123222",
              address: "Antifasisticke borbe 12",
              postNumber: "36000",
              city: "Kraljevo",
              email: "nikola@gmail.com",
              isRestaurant: null,
              phone: "01112312323",
              registrationNumber: "12312212",
              bankAccountNumber: "123-222222-33",
              hasData: true,
            },
            updatedAt: "2023-03-02T21:52:17.572Z",
            isFavorite: true,
          },
          {
            id: "cdb7c339-00b6-477a-98f5-5996d65825a9",
            sku: "SIL-P-BPE-1GC1-1",
            name: "100% GREJP CRVENI 1L",
            category: {
              id: "27fa7b70-3deb-4709-bc80-72f60dbed02d",
              name: "Bezalkoholna piće",
            },
            supplier: {
              id: "5716a290-f009-4331-a641-949a14a71df2",
              name: "Dobavljač 1",
              pib: "123123222",
              address: "Antifasisticke borbe 12",
              postNumber: "36000",
              city: "Kraljevo",
              email: "nikola@gmail.com",
              isRestaurant: null,
              phone: "01112312323",
              registrationNumber: "12312212",
              bankAccountNumber: "123-222222-33",
              hasData: true,
            },
            updatedAt: "2023-03-02T21:52:17.530Z",
            isFavorite: false,
          },
          {
            id: "f8117ff5-cdd7-4680-8a3f-c37c412628b3",
            sku: "SIL-P-BPE-1L1-3",
            name: "100% LIMETA 1L",
            category: {
              id: "27fa7b70-3deb-4709-bc80-72f60dbed02d",
              name: "Bezalkoholna piće",
            },
            supplier: {
              id: "5716a290-f009-4331-a641-949a14a71df2",
              name: "Dobavljač 1",
              pib: "123123222",
              address: "Antifasisticke borbe 12",
              postNumber: "36000",
              city: "Kraljevo",
              email: "nikola@gmail.com",
              isRestaurant: null,
              phone: "01112312323",
              registrationNumber: "12312212",
              bankAccountNumber: "123-222222-33",
              hasData: true,
            },
            updatedAt: "2023-03-02T21:52:17.546Z",
            isFavorite: false,
          },
          {
            id: "70140c33-d7e4-4bd3-9997-dd3bba75ebd1",
            sku: "SIL-P-BPE-1L1-2",
            name: "100% LIMUN 1L",
            category: {
              id: "27fa7b70-3deb-4709-bc80-72f60dbed02d",
              name: "Bezalkoholna piće",
            },
            supplier: {
              id: "5716a290-f009-4331-a641-949a14a71df2",
              name: "Dobavljač 1",
              pib: "123123222",
              address: "Antifasisticke borbe 12",
              postNumber: "36000",
              city: "Kraljevo",
              email: "nikola@gmail.com",
              isRestaurant: null,
              phone: "01112312323",
              registrationNumber: "12312212",
              bankAccountNumber: "123-222222-33",
              hasData: true,
            },
            updatedAt: "2023-03-02T21:52:17.535Z",
            isFavorite: false,
          },
          {
            id: "fdb8bedb-5047-4b29-a117-cd817b50f4c0",
            sku: "SIL-P-BPE-1P1-0",
            name: "100% POMORANDZA 1L",
            category: {
              id: "27fa7b70-3deb-4709-bc80-72f60dbed02d",
              name: "Bezalkoholna piće",
            },
            supplier: {
              id: "5716a290-f009-4331-a641-949a14a71df2",
              name: "Dobavljač 1",
              pib: "123123222",
              address: "Antifasisticke borbe 12",
              postNumber: "36000",
              city: "Kraljevo",
              email: "nikola@gmail.com",
              isRestaurant: null,
              phone: "01112312323",
              registrationNumber: "12312212",
              bankAccountNumber: "123-222222-33",
              hasData: true,
            },
            updatedAt: "2023-03-02T21:52:17.519Z",
            isFavorite: false,
          },
          {
            id: "e8adbd2c-2421-4ddb-b054-e0a2befdb4a8",
            sku: "SIL-H-VEI-AK-15",
            name: "ANANAS KG",
            category: {
              id: "95527dc5-8687-485d-999b-36a95a6a7b61",
              name: "Voće i povrće",
            },
            supplier: {
              id: "5716a290-f009-4331-a641-949a14a71df2",
              name: "Dobavljač 1",
              pib: "123123222",
              address: "Antifasisticke borbe 12",
              postNumber: "36000",
              city: "Kraljevo",
              email: "nikola@gmail.com",
              isRestaurant: null,
              phone: "01112312323",
              registrationNumber: "12312212",
              bankAccountNumber: "123-222222-33",
              hasData: true,
            },
            updatedAt: "2023-03-02T21:52:17.521Z",
            isFavorite: false,
          },
          {
            id: "d589101e-a06b-4109-ac7c-69dda7729247",
            sku: "SIL-H-VEI-AK-14",
            name: "AVOKADO KG",
            category: {
              id: "95527dc5-8687-485d-999b-36a95a6a7b61",
              name: "Voće i povrće",
            },
            supplier: {
              id: "5716a290-f009-4331-a641-949a14a71df2",
              name: "Dobavljač 1",
              pib: "123123222",
              address: "Antifasisticke borbe 12",
              postNumber: "36000",
              city: "Kraljevo",
              email: "nikola@gmail.com",
              isRestaurant: null,
              phone: "01112312323",
              registrationNumber: "12312212",
              bankAccountNumber: "123-222222-33",
              hasData: true,
            },
            updatedAt: "2023-03-02T21:52:17.516Z",
            isFavorite: false,
          },
          {
            id: "ae2c3982-6be2-45fc-ae1e-aa757414aaba",
            sku: "SIL-H-VEI-BSK-61",
            name: "BABY SPANAC KG",
            category: {
              id: "95527dc5-8687-485d-999b-36a95a6a7b61",
              name: "Voće i povrće",
            },
            supplier: {
              id: "5716a290-f009-4331-a641-949a14a71df2",
              name: "Dobavljač 1",
              pib: "123123222",
              address: "Antifasisticke borbe 12",
              postNumber: "36000",
              city: "Kraljevo",
              email: "nikola@gmail.com",
              isRestaurant: null,
              phone: "01112312323",
              registrationNumber: "12312212",
              bankAccountNumber: "123-222222-33",
              hasData: true,
            },
            updatedAt: "2023-03-02T21:52:17.568Z",
            isFavorite: false,
          },
        ],
      },
    ],
  },
  {
    departmentId: "aa3496fe-b8c3-427e-a55e-3ba458571748",
    suppliers: [
      {
        supplierId: "5716a290-f009-4331-a641-949a14a71df2",
        articles: [
          {
            id: "06b46921-348b-4d5d-9b62-919c0d27156b",
            sku: "SIL-H-VEI-LCK-32",
            name: "LUK CRNI KG",
            category: {
              id: "95527dc5-8687-485d-999b-36a95a6a7b61",
              name: "Voće i povrće",
            },
            supplier: {
              id: "5716a290-f009-4331-a641-949a14a71df2",
              name: "Dobavljač 1",
              pib: "123123222",
              address: "Antifasisticke borbe 12",
              postNumber: "36000",
              city: "Kraljevo",
              email: "nikola@gmail.com",
              isRestaurant: null,
              phone: "01112312323",
              registrationNumber: "12312212",
              bankAccountNumber: "123-222222-33",
              hasData: true,
            },
            updatedAt: "2023-03-02T21:52:17.537Z",
            isFavorite: false,
          },
          {
            id: "0eeb0b5f-a312-4169-a663-28da1644094f",
            sku: "SIL-H-VEI-SK-71",
            name: "SPANAC KG",
            category: {
              id: "95527dc5-8687-485d-999b-36a95a6a7b61",
              name: "Voće i povrće",
            },
            supplier: {
              id: "5716a290-f009-4331-a641-949a14a71df2",
              name: "Dobavljač 1",
              pib: "123123222",
              address: "Antifasisticke borbe 12",
              postNumber: "36000",
              city: "Kraljevo",
              email: "nikola@gmail.com",
              isRestaurant: null,
              phone: "01112312323",
              registrationNumber: "12312212",
              bankAccountNumber: "123-222222-33",
              hasData: true,
            },
            updatedAt: "2023-03-02T21:52:17.581Z",
            isFavorite: false,
          },
          {
            id: "016bd326-dda1-4437-8be6-2d0e7a75d5d5",
            sku: "SIL-H-VEI-ZK-66",
            name: "ZELJE KG",
            category: {
              id: "95527dc5-8687-485d-999b-36a95a6a7b61",
              name: "Voće i povrće",
            },
            supplier: {
              id: "5716a290-f009-4331-a641-949a14a71df2",
              name: "Dobavljač 1",
              pib: "123123222",
              address: "Antifasisticke borbe 12",
              postNumber: "36000",
              city: "Kraljevo",
              email: "nikola@gmail.com",
              isRestaurant: null,
              phone: "01112312323",
              registrationNumber: "12312212",
              bankAccountNumber: "123-222222-33",
              hasData: true,
            },
            updatedAt: "2023-03-02T21:52:17.572Z",
            isFavorite: false,
          },
          {
            id: "cdb7c339-00b6-477a-98f5-5996d65825a9",
            sku: "SIL-P-BPE-1GC1-1",
            name: "100% GREJP CRVENI 1L",
            category: {
              id: "27fa7b70-3deb-4709-bc80-72f60dbed02d",
              name: "Bezalkoholna piće",
            },
            supplier: {
              id: "5716a290-f009-4331-a641-949a14a71df2",
              name: "Dobavljač 1",
              pib: "123123222",
              address: "Antifasisticke borbe 12",
              postNumber: "36000",
              city: "Kraljevo",
              email: "nikola@gmail.com",
              isRestaurant: null,
              phone: "01112312323",
              registrationNumber: "12312212",
              bankAccountNumber: "123-222222-33",
              hasData: true,
            },
            updatedAt: "2023-03-02T21:52:17.530Z",
            isFavorite: false,
          },
          {
            id: "f8117ff5-cdd7-4680-8a3f-c37c412628b3",
            sku: "SIL-P-BPE-1L1-3",
            name: "100% LIMETA 1L",
            category: {
              id: "27fa7b70-3deb-4709-bc80-72f60dbed02d",
              name: "Bezalkoholna piće",
            },
            supplier: {
              id: "5716a290-f009-4331-a641-949a14a71df2",
              name: "Dobavljač 1",
              pib: "123123222",
              address: "Antifasisticke borbe 12",
              postNumber: "36000",
              city: "Kraljevo",
              email: "nikola@gmail.com",
              isRestaurant: null,
              phone: "01112312323",
              registrationNumber: "12312212",
              bankAccountNumber: "123-222222-33",
              hasData: true,
            },
            updatedAt: "2023-03-02T21:52:17.546Z",
            isFavorite: false,
          },
          {
            id: "70140c33-d7e4-4bd3-9997-dd3bba75ebd1",
            sku: "SIL-P-BPE-1L1-2",
            name: "100% LIMUN 1L",
            category: {
              id: "27fa7b70-3deb-4709-bc80-72f60dbed02d",
              name: "Bezalkoholna piće",
            },
            supplier: {
              id: "5716a290-f009-4331-a641-949a14a71df2",
              name: "Dobavljač 1",
              pib: "123123222",
              address: "Antifasisticke borbe 12",
              postNumber: "36000",
              city: "Kraljevo",
              email: "nikola@gmail.com",
              isRestaurant: null,
              phone: "01112312323",
              registrationNumber: "12312212",
              bankAccountNumber: "123-222222-33",
              hasData: true,
            },
            updatedAt: "2023-03-02T21:52:17.535Z",
            isFavorite: false,
          },
          {
            id: "fdb8bedb-5047-4b29-a117-cd817b50f4c0",
            sku: "SIL-P-BPE-1P1-0",
            name: "100% POMORANDZA 1L",
            category: {
              id: "27fa7b70-3deb-4709-bc80-72f60dbed02d",
              name: "Bezalkoholna piće",
            },
            supplier: {
              id: "5716a290-f009-4331-a641-949a14a71df2",
              name: "Dobavljač 1",
              pib: "123123222",
              address: "Antifasisticke borbe 12",
              postNumber: "36000",
              city: "Kraljevo",
              email: "nikola@gmail.com",
              isRestaurant: null,
              phone: "01112312323",
              registrationNumber: "12312212",
              bankAccountNumber: "123-222222-33",
              hasData: true,
            },
            updatedAt: "2023-03-02T21:52:17.519Z",
            isFavorite: false,
          },
          {
            id: "e8adbd2c-2421-4ddb-b054-e0a2befdb4a8",
            sku: "SIL-H-VEI-AK-15",
            name: "ANANAS KG",
            category: {
              id: "95527dc5-8687-485d-999b-36a95a6a7b61",
              name: "Voće i povrće",
            },
            supplier: {
              id: "5716a290-f009-4331-a641-949a14a71df2",
              name: "Dobavljač 1",
              pib: "123123222",
              address: "Antifasisticke borbe 12",
              postNumber: "36000",
              city: "Kraljevo",
              email: "nikola@gmail.com",
              isRestaurant: null,
              phone: "01112312323",
              registrationNumber: "12312212",
              bankAccountNumber: "123-222222-33",
              hasData: true,
            },
            updatedAt: "2023-03-02T21:52:17.521Z",
            isFavorite: false,
          },
          {
            id: "d589101e-a06b-4109-ac7c-69dda7729247",
            sku: "SIL-H-VEI-AK-14",
            name: "AVOKADO KG",
            category: {
              id: "95527dc5-8687-485d-999b-36a95a6a7b61",
              name: "Voće i povrće",
            },
            supplier: {
              id: "5716a290-f009-4331-a641-949a14a71df2",
              name: "Dobavljač 1",
              pib: "123123222",
              address: "Antifasisticke borbe 12",
              postNumber: "36000",
              city: "Kraljevo",
              email: "nikola@gmail.com",
              isRestaurant: null,
              phone: "01112312323",
              registrationNumber: "12312212",
              bankAccountNumber: "123-222222-33",
              hasData: true,
            },
            updatedAt: "2023-03-02T21:52:17.516Z",
            isFavorite: false,
          },
          {
            id: "ae2c3982-6be2-45fc-ae1e-aa757414aaba",
            sku: "SIL-H-VEI-BSK-61",
            name: "BABY SPANAC KG",
            category: {
              id: "95527dc5-8687-485d-999b-36a95a6a7b61",
              name: "Voće i povrće",
            },
            supplier: {
              id: "5716a290-f009-4331-a641-949a14a71df2",
              name: "Dobavljač 1",
              pib: "123123222",
              address: "Antifasisticke borbe 12",
              postNumber: "36000",
              city: "Kraljevo",
              email: "nikola@gmail.com",
              isRestaurant: null,
              phone: "01112312323",
              registrationNumber: "12312212",
              bankAccountNumber: "123-222222-33",
              hasData: true,
            },
            updatedAt: "2023-03-02T21:52:17.568Z",
            isFavorite: false,
          },
          {
            id: "865163e2-ed1b-460d-a380-e60d9ee4a481",
            sku: "SIL-H-VEI-BK-8",
            name: "BANANA KG",
            category: {
              id: "95527dc5-8687-485d-999b-36a95a6a7b61",
              name: "Voće i povrće",
            },
            supplier: {
              id: "5716a290-f009-4331-a641-949a14a71df2",
              name: "Dobavljač 1",
              pib: "123123222",
              address: "Antifasisticke borbe 12",
              postNumber: "36000",
              city: "Kraljevo",
              email: "nikola@gmail.com",
              isRestaurant: null,
              phone: "01112312323",
              registrationNumber: "12312212",
              bankAccountNumber: "123-222222-33",
              hasData: true,
            },
            updatedAt: "2023-03-02T21:52:17.570Z",
            isFavorite: false,
          },
          {
            id: "7688790a-d3c0-4f34-a4fd-1bdbce56d96d",
            sku: "SIL-H-VEI-BK-68",
            name: "BLITVA KG",
            category: {
              id: "95527dc5-8687-485d-999b-36a95a6a7b61",
              name: "Voće i povrće",
            },
            supplier: {
              id: "5716a290-f009-4331-a641-949a14a71df2",
              name: "Dobavljač 1",
              pib: "123123222",
              address: "Antifasisticke borbe 12",
              postNumber: "36000",
              city: "Kraljevo",
              email: "nikola@gmail.com",
              isRestaurant: null,
              phone: "01112312323",
              registrationNumber: "12312212",
              bankAccountNumber: "123-222222-33",
              hasData: true,
            },
            updatedAt: "2023-03-02T21:52:17.577Z",
            isFavorite: false,
          },
          {
            id: "a23e5a82-ebe6-405c-ab60-b57022fa3ebd",
            sku: "SIL-H-VEI-BV-67",
            name: "BLITVA VEZA",
            category: {
              id: "95527dc5-8687-485d-999b-36a95a6a7b61",
              name: "Voće i povrće",
            },
            supplier: {
              id: "5716a290-f009-4331-a641-949a14a71df2",
              name: "Dobavljač 1",
              pib: "123123222",
              address: "Antifasisticke borbe 12",
              postNumber: "36000",
              city: "Kraljevo",
              email: "nikola@gmail.com",
              isRestaurant: null,
              phone: "01112312323",
              registrationNumber: "12312212",
              bankAccountNumber: "123-222222-33",
              hasData: true,
            },
            updatedAt: "2023-03-02T21:52:17.574Z",
            isFavorite: false,
          },
          {
            id: "109aadb3-9305-4d9f-8d13-b3d134310912",
            sku: "SIL-H-VEI-BK-60",
            name: "BOSILJAK KG",
            category: {
              id: "95527dc5-8687-485d-999b-36a95a6a7b61",
              name: "Voće i povrće",
            },
            supplier: {
              id: "5716a290-f009-4331-a641-949a14a71df2",
              name: "Dobavljač 1",
              pib: "123123222",
              address: "Antifasisticke borbe 12",
              postNumber: "36000",
              city: "Kraljevo",
              email: "nikola@gmail.com",
              isRestaurant: null,
              phone: "01112312323",
              registrationNumber: "12312212",
              bankAccountNumber: "123-222222-33",
              hasData: true,
            },
            updatedAt: "2023-03-02T21:52:17.563Z",
            isFavorite: false,
          },
          {
            id: "31f2272a-c7d1-453a-ac5e-ce5032243336",
            sku: "SIL-H-VEI-BK-31",
            name: "BROKOLI KG",
            category: {
              id: "95527dc5-8687-485d-999b-36a95a6a7b61",
              name: "Voće i povrće",
            },
            supplier: {
              id: "5716a290-f009-4331-a641-949a14a71df2",
              name: "Dobavljač 1",
              pib: "123123222",
              address: "Antifasisticke borbe 12",
              postNumber: "36000",
              city: "Kraljevo",
              email: "nikola@gmail.com",
              isRestaurant: null,
              phone: "01112312323",
              registrationNumber: "12312212",
              bankAccountNumber: "123-222222-33",
              hasData: true,
            },
            updatedAt: "2023-03-02T21:52:17.543Z",
            isFavorite: false,
          },
          {
            id: "b2dcbcd0-6ce7-4047-bb9e-ca9668b0a635",
            sku: "SIL-H-VEI-BK-27",
            name: "BUKOVACA KG",
            category: {
              id: "95527dc5-8687-485d-999b-36a95a6a7b61",
              name: "Voće i povrće",
            },
            supplier: {
              id: "5716a290-f009-4331-a641-949a14a71df2",
              name: "Dobavljač 1",
              pib: "123123222",
              address: "Antifasisticke borbe 12",
              postNumber: "36000",
              city: "Kraljevo",
              email: "nikola@gmail.com",
              isRestaurant: null,
              phone: "01112312323",
              registrationNumber: "12312212",
              bankAccountNumber: "123-222222-33",
              hasData: true,
            },
            updatedAt: "2023-03-02T21:52:17.539Z",
            isFavorite: false,
          },
          {
            id: "cc813c6f-64d0-43c7-a006-2c60e79de47d",
            sku: "SIL-H-VEI-CKK-24",
            name: "CELER KOREN KG",
            category: {
              id: "95527dc5-8687-485d-999b-36a95a6a7b61",
              name: "Voće i povrće",
            },
            supplier: {
              id: "5716a290-f009-4331-a641-949a14a71df2",
              name: "Dobavljač 1",
              pib: "123123222",
              address: "Antifasisticke borbe 12",
              postNumber: "36000",
              city: "Kraljevo",
              email: "nikola@gmail.com",
              isRestaurant: null,
              phone: "01112312323",
              registrationNumber: "12312212",
              bankAccountNumber: "123-222222-33",
              hasData: true,
            },
            updatedAt: "2023-03-02T21:52:17.528Z",
            isFavorite: false,
          },
          {
            id: "087578b3-48f4-4f23-9882-26b66a1947cc",
            sku: "SIL-H-VEI-CLK-25",
            name: "CELER LIST KG",
            category: {
              id: "95527dc5-8687-485d-999b-36a95a6a7b61",
              name: "Voće i povrće",
            },
            supplier: {
              id: "5716a290-f009-4331-a641-949a14a71df2",
              name: "Dobavljač 1",
              pib: "123123222",
              address: "Antifasisticke borbe 12",
              postNumber: "36000",
              city: "Kraljevo",
              email: "nikola@gmail.com",
              isRestaurant: null,
              phone: "01112312323",
              registrationNumber: "12312212",
              bankAccountNumber: "123-222222-33",
              hasData: true,
            },
            updatedAt: "2023-03-02T21:52:17.530Z",
            isFavorite: false,
          },
          {
            id: "7559fa9c-7d1d-4f78-baa6-f36b3e6984d4",
            sku: "SIL-H-VEI-CPK-21",
            name: "CHERRY PARADAJZ KG",
            category: {
              id: "95527dc5-8687-485d-999b-36a95a6a7b61",
              name: "Voće i povrće",
            },
            supplier: {
              id: "5716a290-f009-4331-a641-949a14a71df2",
              name: "Dobavljač 1",
              pib: "123123222",
              address: "Antifasisticke borbe 12",
              postNumber: "36000",
              city: "Kraljevo",
              email: "nikola@gmail.com",
              isRestaurant: null,
              phone: "01112312323",
              registrationNumber: "12312212",
              bankAccountNumber: "123-222222-33",
              hasData: true,
            },
            updatedAt: "2023-03-02T21:52:17.530Z",
            isFavorite: false,
          },
          {
            id: "677b3e4f-38f7-4cc4-b231-3798c1796cf4",
            sku: "SIL-H-VEI-CK-23",
            name: "CVEKLA KG",
            category: {
              id: "95527dc5-8687-485d-999b-36a95a6a7b61",
              name: "Voće i povrće",
            },
            supplier: {
              id: "5716a290-f009-4331-a641-949a14a71df2",
              name: "Dobavljač 1",
              pib: "123123222",
              address: "Antifasisticke borbe 12",
              postNumber: "36000",
              city: "Kraljevo",
              email: "nikola@gmail.com",
              isRestaurant: null,
              phone: "01112312323",
              registrationNumber: "12312212",
              bankAccountNumber: "123-222222-33",
              hasData: true,
            },
            updatedAt: "2023-03-02T21:52:17.532Z",
            isFavorite: false,
          },
          {
            id: "ea51522f-d89a-4f63-ac76-b4a0a59626fc",
            sku: "SIL-H-VEI-DK-16",
            name: "DJUMBIR KG",
            category: {
              id: "95527dc5-8687-485d-999b-36a95a6a7b61",
              name: "Voće i povrće",
            },
            supplier: {
              id: "5716a290-f009-4331-a641-949a14a71df2",
              name: "Dobavljač 1",
              pib: "123123222",
              address: "Antifasisticke borbe 12",
              postNumber: "36000",
              city: "Kraljevo",
              email: "nikola@gmail.com",
              isRestaurant: null,
              phone: "01112312323",
              registrationNumber: "12312212",
              bankAccountNumber: "123-222222-33",
              hasData: true,
            },
            updatedAt: "2023-03-02T21:52:17.525Z",
            isFavorite: false,
          },
          {
            id: "45ff9834-2342-4205-815e-fc3734c55264",
            sku: "SIL-H-VEI-GK-6",
            name: "GREJP KG",
            category: {
              id: "95527dc5-8687-485d-999b-36a95a6a7b61",
              name: "Voće i povrće",
            },
            supplier: {
              id: "5716a290-f009-4331-a641-949a14a71df2",
              name: "Dobavljač 1",
              pib: "123123222",
              address: "Antifasisticke borbe 12",
              postNumber: "36000",
              city: "Kraljevo",
              email: "nikola@gmail.com",
              isRestaurant: null,
              phone: "01112312323",
              registrationNumber: "12312212",
              bankAccountNumber: "123-222222-33",
              hasData: true,
            },
            updatedAt: "2023-03-02T21:52:17.545Z",
            isFavorite: false,
          },
          {
            id: "378cf701-59b7-47be-97b5-ba4c9639ae81",
            sku: "SIL-H-VEI-IK-45",
            name: "ICEBERG KG",
            category: {
              id: "95527dc5-8687-485d-999b-36a95a6a7b61",
              name: "Voće i povrće",
            },
            supplier: {
              id: "5716a290-f009-4331-a641-949a14a71df2",
              name: "Dobavljač 1",
              pib: "123123222",
              address: "Antifasisticke borbe 12",
              postNumber: "36000",
              city: "Kraljevo",
              email: "nikola@gmail.com",
              isRestaurant: null,
              phone: "01112312323",
              registrationNumber: "12312212",
              bankAccountNumber: "123-222222-33",
              hasData: true,
            },
            updatedAt: "2023-03-02T21:52:17.549Z",
            isFavorite: false,
          },
          {
            id: "fe93498d-dd24-49b5-b226-7397037949a3",
            sku: "SIL-H-VEI-JAK-9",
            name: "JABUKA AJDARED KG",
            category: {
              id: "95527dc5-8687-485d-999b-36a95a6a7b61",
              name: "Voće i povrće",
            },
            supplier: {
              id: "5716a290-f009-4331-a641-949a14a71df2",
              name: "Dobavljač 1",
              pib: "123123222",
              address: "Antifasisticke borbe 12",
              postNumber: "36000",
              city: "Kraljevo",
              email: "nikola@gmail.com",
              isRestaurant: null,
              phone: "01112312323",
              registrationNumber: "12312212",
              bankAccountNumber: "123-222222-33",
              hasData: true,
            },
            updatedAt: "2023-03-02T21:52:17.538Z",
            isFavorite: false,
          },
          {
            id: "5f59a8a7-3126-4652-8a2c-1f7fb0544b5b",
            sku: "SIL-H-VEI-JGSK-10",
            name: "JABUKA GRENI SMIT KG",
            category: {
              id: "95527dc5-8687-485d-999b-36a95a6a7b61",
              name: "Voće i povrće",
            },
            supplier: {
              id: "5716a290-f009-4331-a641-949a14a71df2",
              name: "Dobavljač 1",
              pib: "123123222",
              address: "Antifasisticke borbe 12",
              postNumber: "36000",
              city: "Kraljevo",
              email: "nikola@gmail.com",
              isRestaurant: null,
              phone: "01112312323",
              registrationNumber: "12312212",
              bankAccountNumber: "123-222222-33",
              hasData: true,
            },
            updatedAt: "2023-03-02T21:52:17.523Z",
            isFavorite: false,
          },
          {
            id: "115366ac-4c62-4c92-8e95-910af52d70eb",
            sku: "SIL-H-VEI-KK-30",
            name: "KARFIOL KG",
            category: {
              id: "95527dc5-8687-485d-999b-36a95a6a7b61",
              name: "Voće i povrće",
            },
            supplier: {
              id: "5716a290-f009-4331-a641-949a14a71df2",
              name: "Dobavljač 1",
              pib: "123123222",
              address: "Antifasisticke borbe 12",
              postNumber: "36000",
              city: "Kraljevo",
              email: "nikola@gmail.com",
              isRestaurant: null,
              phone: "01112312323",
              registrationNumber: "12312212",
              bankAccountNumber: "123-222222-33",
              hasData: true,
            },
            updatedAt: "2023-03-02T21:52:17.537Z",
            isFavorite: false,
          },
          {
            id: "b195ba3d-623c-48e8-a332-7e8dceb0ac8f",
            sku: "SIL-H-VEI-KK-69",
            name: "KELJ KG",
            category: {
              id: "95527dc5-8687-485d-999b-36a95a6a7b61",
              name: "Voće i povrće",
            },
            supplier: {
              id: "5716a290-f009-4331-a641-949a14a71df2",
              name: "Dobavljač 1",
              pib: "123123222",
              address: "Antifasisticke borbe 12",
              postNumber: "36000",
              city: "Kraljevo",
              email: "nikola@gmail.com",
              isRestaurant: null,
              phone: "01112312323",
              registrationNumber: "12312212",
              bankAccountNumber: "123-222222-33",
              hasData: true,
            },
            updatedAt: "2023-03-02T21:52:17.575Z",
            isFavorite: false,
          },
          {
            id: "06239aed-eb67-403f-9967-832cf2ce2efe",
            sku: "SIL-H-VEI-KK-12",
            name: "KIVI KG",
            category: {
              id: "95527dc5-8687-485d-999b-36a95a6a7b61",
              name: "Voće i povrće",
            },
            supplier: {
              id: "5716a290-f009-4331-a641-949a14a71df2",
              name: "Dobavljač 1",
              pib: "123123222",
              address: "Antifasisticke borbe 12",
              postNumber: "36000",
              city: "Kraljevo",
              email: "nikola@gmail.com",
              isRestaurant: null,
              phone: "01112312323",
              registrationNumber: "12312212",
              bankAccountNumber: "123-222222-33",
              hasData: true,
            },
            updatedAt: "2023-03-02T21:52:17.515Z",
            isFavorite: false,
          },
          {
            id: "cbbf73bf-e76e-48c0-adfe-fc70bb604b42",
            sku: "SIL-H-VEI-KK-28",
            name: "KOMORAC KG",
            category: {
              id: "95527dc5-8687-485d-999b-36a95a6a7b61",
              name: "Voće i povrće",
            },
            supplier: {
              id: "5716a290-f009-4331-a641-949a14a71df2",
              name: "Dobavljač 1",
              pib: "123123222",
              address: "Antifasisticke borbe 12",
              postNumber: "36000",
              city: "Kraljevo",
              email: "nikola@gmail.com",
              isRestaurant: null,
              phone: "01112312323",
              registrationNumber: "12312212",
              bankAccountNumber: "123-222222-33",
              hasData: true,
            },
            updatedAt: "2023-03-02T21:52:17.533Z",
            isFavorite: false,
          },
          {
            id: "6f7d62e7-bee9-4e4c-a327-8e3d0f4a10fc",
            sku: "SIL-H-VEI-KK-19",
            name: "KRASTAVAC KG",
            category: {
              id: "95527dc5-8687-485d-999b-36a95a6a7b61",
              name: "Voće i povrće",
            },
            supplier: {
              id: "5716a290-f009-4331-a641-949a14a71df2",
              name: "Dobavljač 1",
              pib: "123123222",
              address: "Antifasisticke borbe 12",
              postNumber: "36000",
              city: "Kraljevo",
              email: "nikola@gmail.com",
              isRestaurant: null,
              phone: "01112312323",
              registrationNumber: "12312212",
              bankAccountNumber: "123-222222-33",
              hasData: true,
            },
            updatedAt: "2023-03-02T21:52:17.520Z",
            isFavorite: false,
          },
        ],
      },
    ],
  },
];

const allCustomArticles = [
  {
    departmentId: "d162cffb-acd5-4f8a-94fe-f5e026f99307",
    articles: [
      {
        id: "065aeaae-04e8-4861-bbab-b8ef2bf7c972",
        sku: "PIJ-P-M-A1K-0.7032806748879787",
        name: "Artikal 1 kuhinja",
        category: "MEso",
        updatedAt: "2023-03-02T21:55:35.214Z",
        isFavorite: false,
        defaultPrice: 123,
        storeName: "Borjak",
        note: "",
      },
      {
        id: "5de1790f-1b74-4ac2-92e3-4aeba577b193",
        sku: "PIJ-P-V-KA2-0.7062783864226891",
        name: "Kuhinja artikal",
        category: "Voce",
        updatedAt: "2023-03-29T10:56:36.891Z",
        isFavorite: false,
        defaultPrice: 333,
        storeName: "Metro",
        note: "",
      },
    ],
    count: 2,
  },
  {
    departmentId: "aa3496fe-b8c3-427e-a55e-3ba458571748",
    articles: [
      {
        id: "8712c2a5-f2cb-4634-bfd9-f5c55c7b62ed",
        sku: "PIJ-P-P-AB-0.5096109522005998",
        name: "ARtikal bar ",
        category: "Povrce",
        updatedAt: "2023-03-02T21:56:09.337Z",
        isFavorite: false,
        defaultPrice: 492,
        storeName: "Gala",
        note: "",
      },
    ],
    count: 1,
  },
];

export default {
  allArticles,
  allCustomArticles,
};
