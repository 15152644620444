<template>
  <v-dialog
    v-model="showDialog"
    width="626"
    class="dialog-style"
    persistent
    :fullscreen="getIsMobile"
  >
    <div class="dialog-style-content">
      <div class="header mb-6">
        <div class="d-flex justify-space-between align-center">
          <span class="text-h5 primaryA--text font-weight-bold">{{
            editArticleData
              ? $t("editArticle.title", {
                  articleName: editArticleData.name,
                })
              : $t("addArticle.title")
          }}</span>
          <v-img
            @click="closeDialog"
            class="close-icon"
            max-width="20px"
            :src="require('../../../assets/img/CloseIcon.svg')"
          >
          </v-img>
        </div>
      </div>
      <v-divider></v-divider>
      <div class="pt-6">
        <v-select
          id="step-38"
          class="select-width"
          :items="getAllDepartments"
          :placeholder="$t('common.stations')"
          v-model="selectedDepartmentId"
          item-text="name"
          item-value="id"
          solo
          required
          :rules="rules.department"
          :disabled="editArticleData !== null"
        ></v-select>
        <v-text-field
          id="step-39"
          name="name"
          type="text"
          :placeholder="$t('editArticle.name')"
          :label="$t('editArticle.name')"
          outlined
          color="accent"
          required
          :rules="rules.name"
          v-model="article.name"
        ></v-text-field>
        <v-select
          id="step-40"
          class="select-width"
          :items="allCategories"
          :placeholder="$t('articles.articlesCategories')"
          v-model="article.category"
          item-text="category"
          item-value="category"
          solo
        ></v-select>
      </div>
      <div class="d-flex">
        <v-btn @click="closeDialog">{{ $t("suppliers.close") }}</v-btn>
        <v-btn
          v-if="editArticleData"
          :disabled="isFormValid"
          class="ml-3"
          @click="updateArticle"
          color="accent"
          >{{ $t("common.save") }}</v-btn
        >
        <v-btn
          v-else
          :disabled="isFormValid"
          class="ml-3"
          @click="addArticle"
          color="accent"
          >{{ $t("common.save") }}</v-btn
        >
      </div>
    </div>
    <v-tour
      name="myToursAddMarketArticle"
      :steps="steps"
      :options="myOptions"
      :myCallbacks="myCallbacks"
    ></v-tour>
  </v-dialog>
</template>
<script>
import { mapGetters } from "vuex";
import LocalStorageService from "../../../services/local-storage.service";
export default {
  name: "EditArticleModal",
  props: { dialog: {}, editArticleData: {}, departmentId: {} },
  data: () => ({
    showDialog: false,
    article: {},
    rules: {},
    isFormValid: true,
    selectedDepartmentId: null,
    steps: [],
    myOptions: {},
    myCallbacks: {},
  }),
  created() {
    this.showDialog = this.dialog;
    this.loadTourSteps();
    this.myCallbacks = { onSkip: this.handleOnSkip };
    this.rules = {
      name: [
        (val) => (val || "").length > 0 || this.$t("common.requiredField"),
      ],
      storeName: [
        (val) => (val || "").length > 0 || this.$t("common.requiredField"),
      ],
      category: [
        (val) => (val || "").length > 0 || this.$t("common.requiredField"),
      ],
      department: [
        (val) => (val || "").length > 0 || this.$t("common.requiredField"),
      ],
    };
    this.$store.dispatch("allState/getAllArticleCategories");
  },
  mounted() {
    if (this.departmentId) {
      this.selectedDepartmentId = this.departmentId;
    }
    if (this.editArticleData) {
      this.article = JSON.parse(JSON.stringify(this.editArticleData));
    } else {
      this.article = {
        name: "",
        storeName: "",
        note: "",
        category: "",
        defaultPrice: 0,
      };
    }
    setTimeout(() => {
      const isDisabled =
        LocalStorageService.getLocalStorageData("tourDisabled");
      this.$store.dispatch("allState/disableTour", isDisabled);
      if (isDisabled) {
        this.$tours["myToursAddMarketArticle"].stop();
      } else {
        this.$tours["myToursAddMarketArticle"].start();
      }
    }, 500);
  },
  methods: {
    closeDialog() {
      this.resetForm();
      this.$emit("onClose");
    },
    resetForm() {
      this.article = {
        name: "",
        storeName: "",
        note: "",
        category: "",
        defaultPrice: 0,
      };
    },
    addArticle() {
      const payload = {
        articleName: this.article.name,
        storeName: this.article.storeName,
        note: this.article.note,
        categoryName: this.article.category,
        price: this.article.defaultPrice,
        departmentId: this.selectedDepartmentId,
      };
      this.$store.dispatch("allState/addNewArticle", payload);

      setTimeout(() => {
        this.$emit("onSuccessAddedArticle", this.selectedDepartmentId);
        window.gtag("event", "created_market_article", {
          event_category: "Articles",
          event_label: "Created market article",
        });
        this.closeDialog();
      }, 500);
    },
    updateArticle() {
      const payload = {
        article: {
          articleName: this.article.name,
          storeName: this.article.storeName,
          note: this.article.note,
          category: this.article.category,
          price: this.article.defaultPrice,
        },
        departmentId: this.selectedDepartmentId,
        articleId: this.editArticleData.id,
      };
      this.$store.dispatch("allState/editCustomArticle", payload);

      setTimeout(() => {
        this.$emit("onSuccessEditArticle", this.selectedDepartmentId);
        this.closeDialog();
      }, 500);
    },
    loadTourSteps() {
      this.steps = [
        {
          target: "#step-38",
          header: {
            title: this.$t("tourSteps.step38Title"),
          },
          content: this.$t("tourSteps.step38Content"),
        },
        {
          target: "#step-39",
          header: {
            title: this.$t("tourSteps.step39Title"),
          },
          content: this.$t("tourSteps.step39Content"),
        },
        {
          target: "#step-40",
          header: {
            title: this.$t("tourSteps.step40Title"),
          },
          content: this.$t("tourSteps.step40Content"),
        },
      ];
      this.myOptions = {
        useKeyboardNavigation: true,
        labels: {
          buttonSkip: this.$t("tourSteps.labelSkip"),
          buttonPrevious: this.$t("tourSteps.labelPrevious"),
          buttonNext: this.$t("tourSteps.labelNext"),
          buttonStop: this.$t("tourSteps.labelStop"),
        },
      };
    },
  },
  computed: {
    ...mapGetters({
      getStations: "allState/getDepartments",
      getAllCategories: "allState/getAllCategories",
      getIsTourDisabled: "allState/getIsTourDisabled",
      user: "allState/getUser",
    }),
    getIsMobile() {
      return this.$isMobile;
    },
    articleName() {
      return this.article.name;
    },
    articleStoreName() {
      return this.article.storeName;
    },
    articleCategory() {
      return this.article.category;
    },
    getAllDepartments() {
      return this.getStations;
    },
    allCategories() {
      return this.getAllCategories;
    },
  },
  watch: {
    dialog() {
      this.showDialog = this.dialog;
    },
    articleName() {
      this.isFormValid = this.articleName === "" ? true : false;
    },
    articleStoreName() {
      this.isFormValid = this.articleStoreName === "" ? true : false;
    },
    articleCategory() {
      this.isFormValid = this.articleCategory === "" ? true : false;
    },
    editArticleData() {
      if (this.editArticleData) {
        this.article = JSON.parse(JSON.stringify(this.editArticleData));
      } else {
        this.article = {
          name: "",
          storeName: "",
          note: "",
          category: "",
          defaultPrice: "",
        };
      }
    },
    departmentId() {
      if (this.departmentId) {
        this.selectedDepartmentId = this.departmentId;
      }
    },
    getIsTourDisabled() {
      if (this.getIsTourDisabled) {
        this.$tours["myToursAddMarketArticle"].stop();
      } else {
        this.$tours["myToursAddMarketArticle"].start();
      }
    },
    user: {
      handler() {
        this.loadTourSteps();
      },
      deep: true,
    },
  },
};
</script>
<style scoped lang="scss">
.dialog-style {
  background-color: #ffffff;
  padding: 24px;
  @media screen and (max-width: 959px) {
    height: 100%;
  }
}
.dialog-style-content {
  background-color: #ffffff;
  padding: 24px;
  height: 100%;
}
.close-icon {
  cursor: pointer;
  width: 18px;
}
.price-input {
  max-width: 200px;
  min-width: 60px;
  position: relative;
}
</style>
