import i18n from "@/i18n";
import moment from "moment";

export const generateSMSText = (
  products,
  restaurantName,
  restaurantAddress,
  date,
  orderNote,
  paymentMethodSelected,
  isOrderEdit,
  onlySelectedArticles,
  isMergingInProgressOrder,
  dataForMerge,
  isMarketSupplier,
  station
) => {
  let listA = [];
  if (dataForMerge && isMergingInProgressOrder) {
    dataForMerge.forEach((a) => {
      onlySelectedArticles
        ? a.selected && listA.push(`\n${a.name} x ${a.amount}`)
        : listA.push(`\n${a.name} x ${a.amount}`);
      if (onlySelectedArticles ? a.selected && a.note : a.note) {
        listA.push(`\nNapomena: ${a.note}`);
      }
    });
  } else {
    products &&
      products.forEach((a) => {
        onlySelectedArticles
          ? a.selected && listA.push(`\n${a.name} x ${a.amount}`)
          : listA.push(`\n${a.name} x ${a.amount}`);
        if (onlySelectedArticles ? a.selected && a.note : a.note) {
          listA.push(`\nNapomena: ${a.note}`);
        }
      });
  }

  let wording = "Porudžbina za:";
  if (isOrderEdit) {
    wording = "IZMENA porudžbine za: ";
  }
  if (isMergingInProgressOrder) {
    wording = "DODATAK porudžbini za: ";
  }
  const restaurantNameAndAddress = isMarketSupplier ? `${station.name}\n` : `${restaurantName}.\n${station.name}\n`;
  return `${restaurantNameAndAddress}${wording} ${date}.
              ${listA.toString()}\n${
    getPaymentMethodSelected(paymentMethodSelected)
      ? "\nNačin plaćanja: " + getPaymentMethodSelected(paymentMethodSelected)
      : ""
  }${orderNote && "\nNapomena: " + orderNote}${
    getPaymentMethodSelected(paymentMethodSelected) || orderNote ? "\n\n" : "\n"
  }Pozdrav!`;
};

export const generateEmailText = (
  articles,
  restaurantName,
  restaurantAddress,
  date,
  orderNote,
  paymentMethodSelected,
  isOrderEdit,
  isMergingInProgressOrder,
  dataForMerge,
  isMarketSupplier,
  station
) => {
  let listA = [];
  if (dataForMerge && isMergingInProgressOrder) {
    dataForMerge.forEach((a) => {
      listA.push(`\n%0D%0A${a.name} x ${a.amount}`);
      if (a.note) {
        listA.push(`\n%0D%0ANapomena: ${a.note}`);
      }
    });
  } else {
    articles &&
      articles.forEach((a) => {
        listA.push(`\n%0D%0A${a.name} x ${a.amount}`);
        if (a.note) {
          listA.push(`\n%0D%0ANapomena: ${a.note}`);
        }
      });
  }

  let wording = "Porudžbina za:";
  if (isOrderEdit) {
    wording = "IZMENA porudžbine za: ";
  }
  if (isMergingInProgressOrder) {
    wording = "DODATAK porudžbini za: ";
  }
  const restaurantNameAndAddress = isMarketSupplier ? `${station.name}%0D%0A` : `${restaurantName}.%0D%0A${station.name}%0D%0A`;
  return `${restaurantNameAndAddress}${wording} ${date}.%0D%0A%0D%0A
                ${listA.toString()}\n\n%0D%0A%0D%0A${
    getPaymentMethodSelected(paymentMethodSelected) &&
    "%0D%0ANačin plaćanja: " + getPaymentMethodSelected(paymentMethodSelected)
  }${orderNote && "%0D%0ANapomena: " + orderNote}${
    getPaymentMethodSelected(paymentMethodSelected) || orderNote
      ? "%0D%0A%0D%0A"
      : "%0D%0A"
  }Pozdrav!`;
};
export const getPaymentMethodSelected = (paymentMethodSelected) => {
  switch (paymentMethodSelected) {
    case "cash":
      return i18n.t("newOrder.paymentCash");
    case "wire_transfer":
      return i18n.t("newOrder.paymentVirman");
    case "credit_card":
      return i18n.t("newOrder.paymentCC");
    case "revers":
      return i18n.t("newOrder.paymentRevers");
    default:
      return "";
  }
};
export const getDifferenceTwoArrays = (array1, array2) => {
  const getDifference = (array1, array2) => {
    return array1.filter((object1) => {
      return !array2.some((object2) => {
        return object1.id === object2.id;
      });
    });
  };

  const difference = [
    ...getDifference(array1, array2),
    ...getDifference(array2, array1),
  ];

  return difference;
};

export const formatDate = (date) => {
  return moment(date).utc().format("DD.MM.YYYY");
};
