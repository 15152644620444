<template>
  <div class="padding-adjust">
    <div class="d-flex align-center justify-space-between">
      <span class="text-h5 font-weight-bold primaryA--text">{{
        $t("articles.listArticles")
      }}</span>
      <v-btn
        v-if="showBackToNewOrder"
        color="accent"
        small
        @click="backToNewOrder"
        >{{ $t("articles.backToOrder") }}</v-btn
      >
    </div>
    <div class="search-filter">
      <v-text-field
        :disabled="!selectedFilterSupplier"
        id="step-34"
        name="search"
        class="search-input"
        :placeholder="$t('articles.searchArticles')"
        v-model="searchValue"
        type="text"
        solo
        prepend-inner-icon="mdi-magnify"
        clearable
        @keyup="onSearchUp"
        @keydown="onSearchDown"
      ></v-text-field>
      <div class="d-flex selects-wrap">
        <v-select
          id="step-23"
          class="select-width"
          :items="getAllDepartments"
          :placeholder="$t('common.stations')"
          v-model="selectedDepartmentFilter"
          item-text="name"
          item-value="id"
          solo
          @change="onDepartmentSelected"
        ></v-select>
        <v-select
          id="step-24"
          class="select-width"
          :items="getAllSuppliers"
          :placeholder="$t('common.suppliers')"
          v-model="selectedFilterSupplier"
          item-text="name"
          item-value="id"
          solo
          @change="onSupplierSelected"
          :disabled="!selectedDepartmentFilter"
        ></v-select>
        <v-select
          id="step-33"
          :disabled="!selectedFilterSupplier"
          class="select-width"
          :items="allCategories"
          :placeholder="$t('articles.articlesCategories')"
          v-model="selectedFilterGroup"
          item-text="category"
          item-value="id"
          solo
          @change="onGroupSelected"
        ></v-select>
      </div>
      <v-checkbox
        id="step-35"
        class="ml-2"
        :label="$t('articles.favoriteOnly')"
        v-model="isFavoriteFilterSelected"
        :disabled="!selectedFilterSupplier || !selectedDepartmentFilter"
      ></v-checkbox>
    </div>

    <div class="mt-11">
      <articles-data-table
        v-if="!noArticles"
        :headers="headers"
        class="elevation-0"
        :hide-default-footer="false"
        :data="allArticles"
        :isDataLoading="isDataLoading"
        @onArticlePriceUpdate="handleArticlePriceUpdated"
        :totalCount="getTotalCount"
        @onPageChange="handlePageChange"
        :pageSize="pageSize"
        @onArticleFavorite="handleOnFavoriteArticle"
        @itemsPerPageChange="onItemsPerPageChange"
      ></articles-data-table>
      <no-results-content
        v-else
        :noResultsText="$t('common.noResuls')"
        :page="'articles'"
      ></no-results-content>
    </div>
    <v-tour
      name="myTourArticles"
      :steps="steps"
      :options="myOptions"
      :myCallbacks="myCallbacks"
    ></v-tour>
  </div>
</template>
<script>
import NoResultsContent from "../../components/NoResultsContent.vue";
import ArticlesDataTable from "./ArticlesDataTable.vue";
//vuex
import { mapGetters } from "vuex";
//Event bus
import EventBus from "../../shared/EventBus";
import LocalStorageService from "../../services/local-storage.service";

export default {
  name: "ArticlesPage",
  components: { NoResultsContent, ArticlesDataTable },
  data: () => ({
    fab: false,
    top: true,
    left: true,
    headers: [],
    articlesData: [],
    isDataLoading: false,
    selectedArticles: [],
    searchValue: "",
    selectedFilterGroup: "",
    selectedFilterSupplier: "",
    selectedDepartmentFilter: "",
    initialArticles: {},
    typingTimer: null,
    doneTypingInterval: 1500,
    pageNumber: 1,
    pageSize: 10,
    isFavoriteFilterSelected: false,
    allArticles: [],
    steps: [],
    myOptions: {},
    myCallbacks: {},
  }),
  mounted() {
    setTimeout(() => {
      const isDisabled =
        LocalStorageService.getLocalStorageData("tourDisabled");
      this.$store.dispatch("allState/disableTour", isDisabled);
      if (isDisabled) {
        this.$tours["myTourArticles"].stop();
      } else {
        this.$tours["myTourArticles"].start();
      }
    }, 500);
  },
  created() {
    this.loadTourSteps();
    this.myCallbacks = { onSkip: this.handleOnSkip };
    this.headers = [
      {
        text: this.$t("articles.favorite"),
        value: "checkbox_action",
        sortable: false,
      },
      {
        text: this.$t("common.name"),
        value: "name",
        sortable: false,
      },
      {
        text: this.$t("common.group"),
        value: "__category__",
        sortable: false,
      },
      // temporarily disabled on client
      // {
      //   text: this.$t("articles.price"),
      //   value: "defaultPrice",
      //   sortable: false,
      // },
    ];
  },
  methods: {
    handleSelectedArticles(article) {
      this.$store
        .dispatch("articles/editProductInArticles", {
          departmentId: this.selectedDepartmentFilter,
          articleID: article.id,
          defaultPrice: article.defaultPrice,
          enabled: article.enabled,
        })
        .then(
          () => {},
          (error) => {
            console.log("article error", error);
          }
        );
    },
    handleCancel() {
      this.selectedArticles = [];
      this.articlesData.forEach((a) => {
        this.$set(a, "selected", false);
      });
      this.$forceUpdate();
    },
    onSupplierSelected(e) {
      this.selectedFilterGroup = null;
      this.$store.dispatch("allState/getAllArticleSupplierCategories", {
        supplierId: e,
      });
      setTimeout(() => {
        this.getAllArticlesSupplier();
      }, 500);
    },
    backToNewOrder() {
      let data = localStorage.getItem("cameFromNewOrder");
      data = JSON.parse(data);
      this.$router.push(`/${data.id}/new-order`);
      localStorage.removeItem("cameFromNewOrder");
    },
    handleArticlePriceUpdated(value) {
      this.$store
        .dispatch("articles/editProductInArticles", {
          departmentId: this.selectedDepartmentFilter,
          articleID: value.item.id,
          defaultPrice: value.newPrice,
          enabled: value.item.enabled,
        })
        .then(
          () => {
            EventBus.dispatch("showSnackbar", {
              text: this.$t("notifications.articleUpdated"),
            });
          },
          (error) => {
            console.log("article update error", error);
          }
        );
    },
    onSearchUp() {
      clearTimeout(this.typingTimer);
      this.typingTimer = setTimeout(
        this.searchArticles,
        this.doneTypingInterval
      );
    },
    onSearchDown() {
      clearTimeout(this.typingTimer);
    },
    searchArticles() {
      this.pageNumber = 1;
      this.pageSize = 10;
      this.getAllArticlesSupplier();
    },
    getAllArticlesSupplier() {
      window.gtag("event", "searched_articles", {
        event_category: "Search",
        event_label: this.searchValue,
      });
      let payload = {
        departmentId: this.selectedDepartmentFilter,
        supplierId: this.selectedFilterSupplier,
        pageNum: this.pageNumber,
        categoryId: this.selectedFilterGroup,
        searchQuery: this.searchValue,
        pageSize: this.pageSize,
        onlyFavorite: this.isFavoriteFilterSelected,
      };
      !this.selectedFilterGroup && delete payload.categoryId;
      this.$store.dispatch("allState/getArticlesSupplier", payload).then(
        () => {},
        (error) => {
          console.log("error", error);
        }
      );
    },
    onGroupSelected() {
      this.pageNumber = 1;
      this.getAllArticlesSupplier();
    },
    handlePageChange(page) {
      this.pageNumber = page;
      this.getAllArticlesSupplier();
    },
    onDepartmentSelected(departmentId) {
      this.selectedFilterSupplier = "";
      this.selectedFilterGroup = null;
      // this.$store.dispatch("articles/clearAllArticlesSupplier");
      this.$store.dispatch("allState/getSuppliersForDepartment", {
        departmentId,
      });
    },
    handleOnFavoriteArticle(item) {
      this.$store.dispatch("allState/toggleFavoriteArticle", {
        departmentId: this.selectedDepartmentFilter,
        articleId: item.id,
      });
    },
    onItemsPerPageChange(event) {
      this.pageSize = event;
      this.getAllArticlesSupplier();
    },
    loadTourSteps() {
      this.steps = [
        {
          target: "#step-23",
          header: {
            title: this.$t("tourSteps.step23Title"),
          },
          content: this.$t("tourSteps.step23Content"),
        },
        {
          target: "#step-24",
          header: {
            title: this.$t("tourSteps.step24Title"),
          },
          content: this.$t("tourSteps.step24Content"),
        },
        {
          target: "#step-33",
          header: {
            title: this.$t("tourSteps.step33Title"),
          },
          content: this.$t("tourSteps.step33Content"),
        },
        {
          target: "#step-34",
          header: {
            title: this.$t("tourSteps.step34Title"),
          },
          content: this.$t("tourSteps.step34Content"),
        },
        {
          target: "#step-35",
          header: {
            title: this.$t("tourSteps.step35Title"),
          },
          content: this.$t("tourSteps.step35Content"),
        },
        {
          target: "#step-36",
          header: {
            title: this.$t("tourSteps.step36Title"),
          },
          content: this.$t("tourSteps.step36Content"),
        },
      ];
      this.myOptions = {
        useKeyboardNavigation: true,
        labels: {
          buttonSkip: this.$t("tourSteps.labelSkip"),
          buttonPrevious: this.$t("tourSteps.labelPrevious"),
          buttonNext: this.$t("tourSteps.labelNext"),
          buttonStop: this.$t("tourSteps.labelStop"),
        },
      };
    },
  },

  computed: {
    ...mapGetters({
      allSuppliers: "allState/getDepartmentSuppliers",
      getAllProducts: "allState/getAllSupplierArticles",
      getAllArticlesCategories: "allState/getAllArticlesCategories",
      getTotalArticlesCount: "allState/getTotalArticlesCount",
      getStations: "allState/getDepartments",
      getVenue: "allState/getVenue",
      isArticleLoading: "allState/getIsArticleLoading",
      getIsTourDisabled: "allState/getIsTourDisabled",
      user: "allState/getUser",
    }),
    getAllArticles() {
      return this.getAllProducts;
    },
    getAllSuppliers() {
      return this.allSuppliers
        ?.filter((supplier) => supplier.id)
        ?.sort((a, b) => {
          return a.name > b.name ? 1 : -1;
        });
    },
    noArticles() {
      return this.allArticles.length === 0 && this.isDataLoading === false;
    },
    getIsMobile() {
      return this.$isMobile;
    },
    getNumArticles() {
      return this.selectedArticles.length;
    },
    showBackToNewOrder() {
      let data = localStorage.getItem("cameFromNewOrder");
      data = JSON.parse(data);
      return data && data.show;
    },
    allCategories() {
      let ctgs = this.getAllArticlesCategories;
      ctgs.unshift({ id: null, category: this.$t("articles.all") });
      return ctgs;
    },
    getTotalCount() {
      return this.getTotalArticlesCount;
    },
    getAllDepartments() {
      return this.getStations;
    },
    getIsArticleLoading() {
      return this.isArticleLoading;
    },
  },
  watch: {
    isFavoriteFilterSelected() {
      this.getAllArticlesSupplier();
    },
    getIsArticleLoading() {
      this.allArticles = this.getAllArticles;
      this.isDataLoading = this.getIsArticleLoading;
    },
    getIsTourDisabled() {
      if (this.getIsTourDisabled) {
        setTimeout(() => {
          this.$tours["myTourArticles"].stop();
        }, 300);
      } else {
        setTimeout(() => {
          this.$tours["myTourArticles"].start();
        }, 300);
      }
    },
    user: {
      handler() {
        this.loadTourSteps();
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.text-fix {
  text-transform: unset !important;
}
.bottom-actions {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  box-shadow: 0px -4px 14px rgba(0, 0, 0, 0.04);
  height: 80px;
  display: flex;
  align-items: center;
  padding-left: 100px;
}
.add-button {
  min-height: 54px;
  width: 189px;
  text-transform: none;
}
.cancel-button {
  min-height: 54px;
  width: 147px;
  margin-left: 16px;
  text-transform: none;
  color: #a6a6a6;
}
.search-input {
  max-width: 315px;
  height: 54px;
  @media screen and (max-width: 575px) {
    max-width: 100%;
  }
}
.search-filter {
  margin-top: 24px;
  display: flex;
  @media screen and (max-width: 575px) {
    flex-direction: column;
  }
}
.select-width {
  max-width: 315px;
  margin-left: 24px;
  @media screen and (max-width: 575px) {
    margin-left: 0;
    justify-content: space-between;
    max-width: 49%;
  }
}
.selects-wrap {
  @media screen and (max-width: 575px) {
    justify-content: space-between;
  }
}
.position-fixed {
  position: fixed;
  top: 50%;
  left: 0;
}
.float-add-btn {
  width: 36px !important;
  border-radius: unset !important;
  border-top-right-radius: 30% !important;
  border-bottom-right-radius: 30% !important;
}
.padding-adjust {
  padding: 32px;
  margin-top: 32px;
  @media screen and (max-width: 575px) {
    padding: 8px;
  }
}
</style>
