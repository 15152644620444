<template>
  <v-row justify="space-around" class="profile-icon-margins">
    <v-menu rounded offset-y>
      <template v-slot:activator="{ attrs, on }">
        <div id="step-5" class="profile-nav-img-wrap" v-bind="attrs" v-on="on">
          <v-img
            max-width="16"
            :src="require('../assets/img/ProfileNav.svg')"
          ></v-img>
        </div>
      </template>

      <v-list>
        <v-list-item
          v-for="(item, index) in getItems"
          :key="item.id"
          link
          :to="item.path"
          :class="{
            borderTop: index === items.length - 2,
          }"
        >
          <v-list-item-title v-text="item.name"></v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-row>
</template>
<script>
export default {
  name: "ProfileMenu",
  props: {
    items: [],
  },
  data() {
    return {};
  },
  computed: {
    getItems() {
      return this.items.filter((item) => item.show);
    },
  },
};
</script>
<style type="scss" scoped>
.profile-nav-img-wrap {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1.5px solid #276ef1;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.profile-nav-img-wrap:hover {
  background: #f7f7f8;
}
.profile-icon-margins {
  margin-top: 11px;
  margin-right: 16px;
}
.borderTop {
  border-top: 1px solid #e0e0e0;
}
.disabled {
  opacity: 0.5;
  pointer-events: none;
}
</style>
