<template>
  <div class="root d-flex align-center justify-center">
    <div class="d-flex align-center justify-center flex-column">
      <v-img
        max-width="80"
        :src="require('../assets/img/NoOrders.svg')"
      ></v-img>
      <span class="text-subtitle-1 primaryDark--text mt-7">{{
        getNoText
      }}</span>
    </div>
  </div>
</template>
<script>
export default {
  name: "NoResultsContent",
  props: { tabName: {}, noResultsText: {}, page: {} },
  data: () => ({}),
  created() {},
  computed: {
    getNoText() {
      switch (this.page) {
        case "orders":
          return this.$t("currentOrders.noOrders");
        default:
          return this.noResultsText;
      }
    },
  },
  watch: {},
};
</script>
<style lang="scss" scoped>
.root {
  height: calc(100vh - 310px);
}
</style>
