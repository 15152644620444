let ordersForDepartmentOne = {
  ordersCompleted: {
    allOrdersCombined: [
      {
        id: "6ce29a9a-95d7-42cc-88c8-085871c1ea48",
        supplier: {
          id: "5716a290-f009-4331-a641-949a14a71df2",
          name: "Dobavljač 1",
          pib: "123123222",
          address: "Antifasisticke borbe 12",
          postNumber: "36000",
          city: "Kraljevo",
          email: "nikola@gmail.com",
          isRestaurant: null,
          phone: "01112312323",
          registrationNumber: "12312212",
          bankAccountNumber: "123-222222-33",
          hasData: true,
        },
        deadline: "2023-12-10T00:00:00.000Z",
        status: "COMPLETED",
        products: [
          {
            id: "cdb7c339-00b6-477a-98f5-5996d65825a9",
            name: "100% GREJP CRVENI 1L",
            amount: "4.00",
            status: "DELIVERED",
            price: null,
            notDeliveredReason: null,
            note: null,
            move_to_order_id: null,
            deliveredAmount: null,
          },
          {
            id: "f8117ff5-cdd7-4680-8a3f-c37c412628b3",
            name: "100% LIMETA 1L",
            amount: "5.00",
            status: "DELIVERED",
            price: null,
            notDeliveredReason: null,
            note: null,
            move_to_order_id: null,
            deliveredAmount: null,
          },
        ],
        department: {
          id: "d162cffb-acd5-4f8a-94fe-f5e026f99307",
          name: "Kuhinja",
        },
        lastUpdatedBy: {
          id: "031af993-a0c4-4035-8eb7-fcd6b1825f0f",
          name: "Nikola Cvetkovic",
          email: "cvelekv@gmail.com",
          phone: "643758137",
        },
        createdBy: {
          id: "031af993-a0c4-4035-8eb7-fcd6b1825f0f",
          name: "Nikola Cvetkovic",
          email: "cvelekv@gmail.com",
          phone: "643758137",
        },
        lastUpdatedAt: "2023-07-11T15:22:41.595Z",
        createdAt: "2023-07-11T15:22:21.499Z",
        orderNote: "TEST",
        paymentMethod: "cash",
        supplierContactNumber: "0112321213",
        order_number: "pa4sw",
      },
      {
        id: "6cffa476-7341-405f-be74-214a9fc5191e",
        supplier: {
          id: "5716a290-f009-4331-a641-949a14a71df2",
          name: "Dobavljač 1",
          pib: "123123222",
          address: "Antifasisticke borbe 12",
          postNumber: "36000",
          city: "Kraljevo",
          email: "nikola@gmail.com",
          isRestaurant: null,
          phone: "01112312323",
          registrationNumber: "12312212",
          bankAccountNumber: "123-222222-33",
          hasData: true,
        },
        deadline: "2023-12-11T00:00:00.000Z",
        status: "COMPLETED",
        products: [],
        department: {
          id: "d162cffb-acd5-4f8a-94fe-f5e026f99307",
          name: "Kuhinja",
        },
        lastUpdatedBy: {
          id: "031af993-a0c4-4035-8eb7-fcd6b1825f0f",
          name: "Nikola Cvetkovic",
          email: "cvelekv@gmail.com",
          phone: "643758137",
        },
        createdBy: {
          id: "031af993-a0c4-4035-8eb7-fcd6b1825f0f",
          name: "Nikola Cvetkovic",
          email: "cvelekv@gmail.com",
          phone: "643758137",
        },
        lastUpdatedAt: "2023-07-11T15:17:09.984Z",
        createdAt: "2023-07-11T15:16:13.087Z",
        orderNote: "",
        paymentMethod: "cash",
        supplierContactNumber: "0112321213",
        order_number: "ivwp8",
      },
      {
        id: "33e2ab9c-f0aa-4ffa-96a3-b483af9f9a71",
        supplier: {
          id: "5716a290-f009-4331-a641-949a14a71df2",
          name: "Dobavljač 1",
          pib: "123123222",
          address: "Antifasisticke borbe 12",
          postNumber: "36000",
          city: "Kraljevo",
          email: "nikola@gmail.com",
          isRestaurant: null,
          phone: "01112312323",
          registrationNumber: "12312212",
          bankAccountNumber: "123-222222-33",
          hasData: true,
        },
        deadline: "2023-12-12T00:00:00.000Z",
        status: "COMPLETED",
        products: [],
        department: {
          id: "d162cffb-acd5-4f8a-94fe-f5e026f99307",
          name: "Kuhinja",
        },
        lastUpdatedBy: {
          id: "031af993-a0c4-4035-8eb7-fcd6b1825f0f",
          name: "Nikola Cvetkovic",
          email: "cvelekv@gmail.com",
          phone: "643758137",
        },
        createdBy: {
          id: "031af993-a0c4-4035-8eb7-fcd6b1825f0f",
          name: "Nikola Cvetkovic",
          email: "cvelekv@gmail.com",
          phone: "643758137",
        },
        lastUpdatedAt: "2023-07-11T15:30:11.565Z",
        createdAt: "2023-07-11T15:29:46.879Z",
        orderNote: "TEST",
        paymentMethod: "cash",
        supplierContactNumber: "0112321213",
        order_number: "9xh8g",
      },
      {
        id: "8edf0e1a-53cd-4284-8fe5-163c51d529eb",
        supplier: {
          id: "5716a290-f009-4331-a641-949a14a71df2",
          name: "Dobavljač 1",
          pib: "123123222",
          address: "Antifasisticke borbe 12",
          postNumber: "36000",
          city: "Kraljevo",
          email: "nikola@gmail.com",
          isRestaurant: null,
          phone: "01112312323",
          registrationNumber: "12312212",
          bankAccountNumber: "123-222222-33",
          hasData: true,
        },
        deadline: "2023-12-13T00:00:00.000Z",
        status: "COMPLETED",
        products: [
          {
            id: "06b46921-348b-4d5d-9b62-919c0d27156b",
            name: "LUK CRNI KG",
            amount: "1.00",
            status: "DELIVERED",
            price: null,
            notDeliveredReason: null,
            note: null,
            move_to_order_id: null,
            deliveredAmount: null,
          },
          {
            id: "0eeb0b5f-a312-4169-a663-28da1644094f",
            name: "SPANAC KG",
            amount: "2.00",
            status: "DELIVERED",
            price: null,
            notDeliveredReason: null,
            note: null,
            move_to_order_id: null,
            deliveredAmount: null,
          },
          {
            id: "016bd326-dda1-4437-8be6-2d0e7a75d5d5",
            name: "ZELJE KG",
            amount: "3.00",
            status: "DELIVERED",
            price: null,
            notDeliveredReason: null,
            note: null,
            move_to_order_id: null,
            deliveredAmount: null,
          },
          {
            id: "cdb7c339-00b6-477a-98f5-5996d65825a9",
            name: "100% GREJP CRVENI 1L",
            amount: "4.00",
            status: "DELIVERED",
            price: null,
            notDeliveredReason: null,
            note: null,
            move_to_order_id: null,
            deliveredAmount: null,
          },
          {
            id: "f8117ff5-cdd7-4680-8a3f-c37c412628b3",
            name: "100% LIMETA 1L",
            amount: "5.00",
            status: "DELIVERED",
            price: null,
            notDeliveredReason: null,
            note: null,
            move_to_order_id: null,
            deliveredAmount: null,
          },
          {
            id: "70140c33-d7e4-4bd3-9997-dd3bba75ebd1",
            name: "100% LIMUN 1L",
            amount: "6.00",
            status: "DELIVERED",
            price: null,
            notDeliveredReason: null,
            note: null,
            move_to_order_id: null,
            deliveredAmount: null,
          },
        ],
        department: {
          id: "d162cffb-acd5-4f8a-94fe-f5e026f99307",
          name: "Kuhinja",
        },
        lastUpdatedBy: {
          id: "031af993-a0c4-4035-8eb7-fcd6b1825f0f",
          name: "Nikola Cvetkovic",
          email: "cvelekv@gmail.com",
          phone: "643758137",
        },
        createdBy: {
          id: "031af993-a0c4-4035-8eb7-fcd6b1825f0f",
          name: "Nikola Cvetkovic",
          email: "cvelekv@gmail.com",
          phone: "643758137",
        },
        lastUpdatedAt: "2023-07-11T15:32:30.473Z",
        createdAt: "2023-07-11T15:31:51.522Z",
        orderNote: "test",
        paymentMethod: "cash",
        supplierContactNumber: "0112321213",
        order_number: "c29mfh",
      },
      {
        id: "1fdd0ee4-acaa-471e-ad2f-030121a266f5",
        supplier: {
          id: "5716a290-f009-4331-a641-949a14a71df2",
          name: "Dobavljač 1",
          pib: "123123222",
          address: "Antifasisticke borbe 12",
          postNumber: "36000",
          city: "Kraljevo",
          email: "nikola@gmail.com",
          isRestaurant: null,
          phone: "01112312323",
          registrationNumber: "12312212",
          bankAccountNumber: "123-222222-33",
          hasData: true,
        },
        deadline: "2023-12-14T00:00:00.000Z",
        status: "COMPLETED",
        products: [],
        department: {
          id: "d162cffb-acd5-4f8a-94fe-f5e026f99307",
          name: "Kuhinja",
        },
        lastUpdatedBy: {
          id: "031af993-a0c4-4035-8eb7-fcd6b1825f0f",
          name: "Nikola Cvetkovic",
          email: "cvelekv@gmail.com",
          phone: "643758137",
        },
        createdBy: {
          id: "031af993-a0c4-4035-8eb7-fcd6b1825f0f",
          name: "Nikola Cvetkovic",
          email: "cvelekv@gmail.com",
          phone: "643758137",
        },
        lastUpdatedAt: "2023-07-11T15:25:15.478Z",
        createdAt: "2023-07-11T15:24:51.345Z",
        orderNote: "TEST",
        paymentMethod: "cash",
        supplierContactNumber: "0112321213",
        order_number: "jrc4n",
      },
      {
        id: "5af2de5d-6a0a-4a02-9ec7-c6225b6c8486",
        supplier: {
          id: "5716a290-f009-4331-a641-949a14a71df2",
          name: "Dobavljač 1",
          pib: "123123222",
          address: "Antifasisticke borbe 12",
          postNumber: "36000",
          city: "Kraljevo",
          email: "nikola@gmail.com",
          isRestaurant: null,
          phone: "01112312323",
          registrationNumber: "12312212",
          bankAccountNumber: "123-222222-33",
          hasData: true,
        },
        deadline: "2023-12-15T00:00:00.000Z",
        status: "COMPLETED",
        products: [
          {
            id: "f8117ff5-cdd7-4680-8a3f-c37c412628b3",
            name: "100% LIMETA 1L",
            amount: "2.00",
            status: "DELIVERED",
            price: null,
            notDeliveredReason: null,
            note: null,
            move_to_order_id: null,
            deliveredAmount: null,
          },
        ],
        department: {
          id: "d162cffb-acd5-4f8a-94fe-f5e026f99307",
          name: "Kuhinja",
        },
        lastUpdatedBy: {
          id: "031af993-a0c4-4035-8eb7-fcd6b1825f0f",
          name: "Nikola Cvetkovic",
          email: "cvelekv@gmail.com",
          phone: "643758137",
        },
        createdBy: {
          id: "031af993-a0c4-4035-8eb7-fcd6b1825f0f",
          name: "Nikola Cvetkovic",
          email: "cvelekv@gmail.com",
          phone: "643758137",
        },
        lastUpdatedAt: "2023-07-10T20:23:27.452Z",
        createdAt: "2023-06-21T09:18:20.015Z",
        orderNote: "",
        paymentMethod: "cash",
        supplierContactNumber: "0112321213",
        order_number: "pzrn9",
      },
      {
        id: "82bb0ad7-2322-4eb4-8cd1-e3d1f98a1570",
        deadline: "2023-12-16T00:00:00.000Z",
        status: "COMPLETED",
        products: [
          {
            id: "065aeaae-04e8-4861-bbab-b8ef2bf7c972",
            name: "Artikal 1 kuhinja",
            amount: "1.00",
            status: "DELIVERED",
            price: null,
            notDeliveredReason: null,
            note: "",
            move_to_order_id: null,
            deliveredAmount: null,
          },
          {
            id: "5de1790f-1b74-4ac2-92e3-4aeba577b193",
            name: "Kuhinja artikal",
            amount: "2.00",
            status: "DELIVERED",
            price: null,
            notDeliveredReason: null,
            note: "",
            move_to_order_id: null,
            deliveredAmount: null,
          },
        ],
        department: {
          id: "d162cffb-acd5-4f8a-94fe-f5e026f99307",
          name: "Kuhinja",
        },
        lastUpdatedBy: {
          id: "031af993-a0c4-4035-8eb7-fcd6b1825f0f",
          name: "Nikola Cvetkovic",
          email: "cvelekv@gmail.com",
          phone: "643758137",
        },
        createdBy: {
          id: "031af993-a0c4-4035-8eb7-fcd6b1825f0f",
          name: "Nikola Cvetkovic",
          email: "cvelekv@gmail.com",
          phone: "643758137",
        },
        lastUpdatedAt: "2023-07-10T20:23:34.857Z",
        createdAt: "2023-06-22T20:47:12.349Z",
        orderNote: "test",
        paymentMethod: "cash",
        order_number: "iqe2i",
      },
      {
        id: "50c9d77f-c64c-4a91-9f16-d99538d9a764",
        deadline: "2023-12-17T00:00:00.000Z",
        status: "COMPLETED",
        products: [],
        department: {
          id: "d162cffb-acd5-4f8a-94fe-f5e026f99307",
          name: "Kuhinja",
        },
        lastUpdatedBy: {
          id: "031af993-a0c4-4035-8eb7-fcd6b1825f0f",
          name: "Nikola Cvetkovic",
          email: "cvelekv@gmail.com",
          phone: "643758137",
        },
        createdBy: {
          id: "031af993-a0c4-4035-8eb7-fcd6b1825f0f",
          name: "Nikola Cvetkovic",
          email: "cvelekv@gmail.com",
          phone: "643758137",
        },
        lastUpdatedAt: "2023-07-10T20:23:31.506Z",
        createdAt: "2023-06-22T20:44:36.841Z",
        orderNote: "test",
        paymentMethod: "cash",
        order_number: "bez77",
      },
      {
        id: "b666ca18-ddec-49e3-86e9-baf5ff7547f5",
        deadline: "2023-12-18T00:00:00.000Z",
        status: "COMPLETED",
        products: [],
        department: {
          id: "d162cffb-acd5-4f8a-94fe-f5e026f99307",
          name: "Kuhinja",
        },
        lastUpdatedBy: {
          id: "031af993-a0c4-4035-8eb7-fcd6b1825f0f",
          name: "Nikola Cvetkovic",
          email: "cvelekv@gmail.com",
          phone: "643758137",
        },
        createdBy: {
          id: "031af993-a0c4-4035-8eb7-fcd6b1825f0f",
          name: "Nikola Cvetkovic",
          email: "cvelekv@gmail.com",
          phone: "643758137",
        },
        lastUpdatedAt: "2023-06-20T20:55:35.421Z",
        createdAt: "2023-06-20T20:55:19.167Z",
        orderNote: "",
        paymentMethod: "cash",
        order_number: "y8nzp",
      },
      {
        id: "0be7fbbf-d5f0-45f7-a8d0-bb828892a329",
        deadline: "2023-12-19T00:00:00.000Z",
        status: "COMPLETED",
        products: [],
        department: {
          id: "d162cffb-acd5-4f8a-94fe-f5e026f99307",
          name: "Kuhinja",
        },
        lastUpdatedBy: {
          id: "031af993-a0c4-4035-8eb7-fcd6b1825f0f",
          name: "Nikola Cvetkovic",
          email: "cvelekv@gmail.com",
          phone: "643758137",
        },
        createdBy: {
          id: "031af993-a0c4-4035-8eb7-fcd6b1825f0f",
          name: "Nikola Cvetkovic",
          email: "cvelekv@gmail.com",
          phone: "643758137",
        },
        lastUpdatedAt: "2023-03-03T08:01:57.387Z",
        createdAt: "2023-03-03T08:01:30.611Z",
        orderNote: "Test",
        paymentMethod: "cash",
        order_number: "q1l8f",
      },
      {
        id: "111f8062-e7a4-4125-8aaf-c4c71a18e8e9",
        deadline: "2023-12-20T00:00:00.000Z",
        status: "COMPLETED",
        products: [],
        department: {
          id: "d162cffb-acd5-4f8a-94fe-f5e026f99307",
          name: "Kuhinja",
        },
        lastUpdatedBy: {
          id: "031af993-a0c4-4035-8eb7-fcd6b1825f0f",
          name: "Nikola Cvetkovic",
          email: "cvelekv@gmail.com",
          phone: "643758137",
        },
        createdBy: {
          id: "031af993-a0c4-4035-8eb7-fcd6b1825f0f",
          name: "Nikola Cvetkovic",
          email: "cvelekv@gmail.com",
          phone: "643758137",
        },
        lastUpdatedAt: "2023-03-15T00:37:55.160Z",
        createdAt: "2023-03-08T21:13:21.257Z",
        orderNote: "",
        paymentMethod: "cash",
        order_number: "h1565",
      },
    ],
    customOrders: [
      {
        id: "82bb0ad7-2322-4eb4-8cd1-e3d1f98a1570",
        deadline: "2023-12-23T00:00:00.000Z",
        status: "COMPLETED",
        products: [
          {
            id: "065aeaae-04e8-4861-bbab-b8ef2bf7c972",
            name: "Artikal 1 kuhinja",
            amount: "1.00",
            status: "DELIVERED",
            price: null,
            notDeliveredReason: null,
            note: "",
            move_to_order_id: null,
            deliveredAmount: null,
          },
          {
            id: "5de1790f-1b74-4ac2-92e3-4aeba577b193",
            name: "Kuhinja artikal",
            amount: "2.00",
            status: "DELIVERED",
            price: null,
            notDeliveredReason: null,
            note: "",
            move_to_order_id: null,
            deliveredAmount: null,
          },
        ],
        department: {
          id: "d162cffb-acd5-4f8a-94fe-f5e026f99307",
          name: "Kuhinja",
        },
        lastUpdatedBy: {
          id: "031af993-a0c4-4035-8eb7-fcd6b1825f0f",
          name: "Nikola Cvetkovic",
          email: "cvelekv@gmail.com",
          phone: "643758137",
        },
        createdBy: {
          id: "031af993-a0c4-4035-8eb7-fcd6b1825f0f",
          name: "Nikola Cvetkovic",
          email: "cvelekv@gmail.com",
          phone: "643758137",
        },
        lastUpdatedAt: "2023-07-10T20:23:34.857Z",
        createdAt: "2023-06-22T20:47:12.349Z",
        orderNote: "test",
        paymentMethod: "cash",
        order_number: "iqe2i",
      },
      {
        id: "50c9d77f-c64c-4a91-9f16-d99538d9a764",
        deadline: "2023-11-11T00:00:00.000Z",
        status: "COMPLETED",
        products: [],
        department: {
          id: "d162cffb-acd5-4f8a-94fe-f5e026f99307",
          name: "Kuhinja",
        },
        lastUpdatedBy: {
          id: "031af993-a0c4-4035-8eb7-fcd6b1825f0f",
          name: "Nikola Cvetkovic",
          email: "cvelekv@gmail.com",
          phone: "643758137",
        },
        createdBy: {
          id: "031af993-a0c4-4035-8eb7-fcd6b1825f0f",
          name: "Nikola Cvetkovic",
          email: "cvelekv@gmail.com",
          phone: "643758137",
        },
        lastUpdatedAt: "2023-07-10T20:23:31.506Z",
        createdAt: "2023-06-22T20:44:36.841Z",
        orderNote: "test",
        paymentMethod: "cash",
        order_number: "bez77",
      },
      {
        id: "b666ca18-ddec-49e3-86e9-baf5ff7547f5",
        deadline: "2023-11-13T00:00:00.000Z",
        status: "COMPLETED",
        products: [],
        department: {
          id: "d162cffb-acd5-4f8a-94fe-f5e026f99307",
          name: "Kuhinja",
        },
        lastUpdatedBy: {
          id: "031af993-a0c4-4035-8eb7-fcd6b1825f0f",
          name: "Nikola Cvetkovic",
          email: "cvelekv@gmail.com",
          phone: "643758137",
        },
        createdBy: {
          id: "031af993-a0c4-4035-8eb7-fcd6b1825f0f",
          name: "Nikola Cvetkovic",
          email: "cvelekv@gmail.com",
          phone: "643758137",
        },
        lastUpdatedAt: "2023-06-20T20:55:35.421Z",
        createdAt: "2023-06-20T20:55:19.167Z",
        orderNote: "",
        paymentMethod: "cash",
        order_number: "y8nzp",
      },
      {
        id: "0be7fbbf-d5f0-45f7-a8d0-bb828892a329",
        deadline: "2023-11-17T00:00:00.000Z",
        status: "COMPLETED",
        products: [],
        department: {
          id: "d162cffb-acd5-4f8a-94fe-f5e026f99307",
          name: "Kuhinja",
        },
        lastUpdatedBy: {
          id: "031af993-a0c4-4035-8eb7-fcd6b1825f0f",
          name: "Nikola Cvetkovic",
          email: "cvelekv@gmail.com",
          phone: "643758137",
        },
        createdBy: {
          id: "031af993-a0c4-4035-8eb7-fcd6b1825f0f",
          name: "Nikola Cvetkovic",
          email: "cvelekv@gmail.com",
          phone: "643758137",
        },
        lastUpdatedAt: "2023-03-03T08:01:57.387Z",
        createdAt: "2023-03-03T08:01:30.611Z",
        orderNote: "Test",
        paymentMethod: "cash",
        order_number: "q1l8f",
      },
      {
        id: "111f8062-e7a4-4125-8aaf-c4c71a18e8e9",
        deadline: "2023-11-16T00:00:00.000Z",
        status: "COMPLETED",
        products: [],
        department: {
          id: "d162cffb-acd5-4f8a-94fe-f5e026f99307",
          name: "Kuhinja",
        },
        lastUpdatedBy: {
          id: "031af993-a0c4-4035-8eb7-fcd6b1825f0f",
          name: "Nikola Cvetkovic",
          email: "cvelekv@gmail.com",
          phone: "643758137",
        },
        createdBy: {
          id: "031af993-a0c4-4035-8eb7-fcd6b1825f0f",
          name: "Nikola Cvetkovic",
          email: "cvelekv@gmail.com",
          phone: "643758137",
        },
        lastUpdatedAt: "2023-03-15T00:37:55.160Z",
        createdAt: "2023-03-08T21:13:21.257Z",
        orderNote: "",
        paymentMethod: "cash",
        order_number: "h1565",
      },
    ],
    orders: [
      {
        id: "6ce29a9a-95d7-42cc-88c8-085871c1ea48",
        supplier: {
          id: "5716a290-f009-4331-a641-949a14a71df2",
          name: "Dobavljač 1",
          pib: "123123222",
          address: "Antifasisticke borbe 12",
          postNumber: "36000",
          city: "Kraljevo",
          email: "nikola@gmail.com",
          isRestaurant: null,
          phone: "01112312323",
          registrationNumber: "12312212",
          bankAccountNumber: "123-222222-33",
          hasData: true,
        },
        deadline: "2023-11-18T00:00:00.000Z",
        status: "COMPLETED",
        products: [
          {
            id: "cdb7c339-00b6-477a-98f5-5996d65825a9",
            name: "100% GREJP CRVENI 1L",
            amount: "4.00",
            status: "DELIVERED",
            price: null,
            notDeliveredReason: null,
            note: null,
            move_to_order_id: null,
            deliveredAmount: null,
          },
          {
            id: "f8117ff5-cdd7-4680-8a3f-c37c412628b3",
            name: "100% LIMETA 1L",
            amount: "5.00",
            status: "DELIVERED",
            price: null,
            notDeliveredReason: null,
            note: null,
            move_to_order_id: null,
            deliveredAmount: null,
          },
        ],
        department: {
          id: "d162cffb-acd5-4f8a-94fe-f5e026f99307",
          name: "Kuhinja",
        },
        lastUpdatedBy: {
          id: "031af993-a0c4-4035-8eb7-fcd6b1825f0f",
          name: "Nikola Cvetkovic",
          email: "cvelekv@gmail.com",
          phone: "643758137",
        },
        createdBy: {
          id: "031af993-a0c4-4035-8eb7-fcd6b1825f0f",
          name: "Nikola Cvetkovic",
          email: "cvelekv@gmail.com",
          phone: "643758137",
        },
        lastUpdatedAt: "2023-07-11T15:22:41.595Z",
        createdAt: "2023-07-11T15:22:21.499Z",
        orderNote: "TEST",
        paymentMethod: "cash",
        supplierContactNumber: "0112321213",
        order_number: "pa4sw",
      },
      {
        id: "6cffa476-7341-405f-be74-214a9fc5191e",
        supplier: {
          id: "5716a290-f009-4331-a641-949a14a71df2",
          name: "Dobavljač 1",
          pib: "123123222",
          address: "Antifasisticke borbe 12",
          postNumber: "36000",
          city: "Kraljevo",
          email: "nikola@gmail.com",
          isRestaurant: null,
          phone: "01112312323",
          registrationNumber: "12312212",
          bankAccountNumber: "123-222222-33",
          hasData: true,
        },
        deadline: "2023-11-19T00:00:00.000Z",
        status: "COMPLETED",
        products: [],
        department: {
          id: "d162cffb-acd5-4f8a-94fe-f5e026f99307",
          name: "Kuhinja",
        },
        lastUpdatedBy: {
          id: "031af993-a0c4-4035-8eb7-fcd6b1825f0f",
          name: "Nikola Cvetkovic",
          email: "cvelekv@gmail.com",
          phone: "643758137",
        },
        createdBy: {
          id: "031af993-a0c4-4035-8eb7-fcd6b1825f0f",
          name: "Nikola Cvetkovic",
          email: "cvelekv@gmail.com",
          phone: "643758137",
        },
        lastUpdatedAt: "2023-07-11T15:17:09.984Z",
        createdAt: "2023-07-11T15:16:13.087Z",
        orderNote: "",
        paymentMethod: "cash",
        supplierContactNumber: "0112321213",
        order_number: "ivwp8",
      },
      {
        id: "33e2ab9c-f0aa-4ffa-96a3-b483af9f9a71",
        supplier: {
          id: "5716a290-f009-4331-a641-949a14a71df2",
          name: "Dobavljač 1",
          pib: "123123222",
          address: "Antifasisticke borbe 12",
          postNumber: "36000",
          city: "Kraljevo",
          email: "nikola@gmail.com",
          isRestaurant: null,
          phone: "01112312323",
          registrationNumber: "12312212",
          bankAccountNumber: "123-222222-33",
          hasData: true,
        },
        deadline: "2023-11-20T00:00:00.000Z",
        status: "COMPLETED",
        products: [],
        department: {
          id: "d162cffb-acd5-4f8a-94fe-f5e026f99307",
          name: "Kuhinja",
        },
        lastUpdatedBy: {
          id: "031af993-a0c4-4035-8eb7-fcd6b1825f0f",
          name: "Nikola Cvetkovic",
          email: "cvelekv@gmail.com",
          phone: "643758137",
        },
        createdBy: {
          id: "031af993-a0c4-4035-8eb7-fcd6b1825f0f",
          name: "Nikola Cvetkovic",
          email: "cvelekv@gmail.com",
          phone: "643758137",
        },
        lastUpdatedAt: "2023-07-11T15:30:11.565Z",
        createdAt: "2023-07-11T15:29:46.879Z",
        orderNote: "TEST",
        paymentMethod: "cash",
        supplierContactNumber: "0112321213",
        order_number: "9xh8g",
      },
      {
        id: "8edf0e1a-53cd-4284-8fe5-163c51d529eb",
        supplier: {
          id: "5716a290-f009-4331-a641-949a14a71df2",
          name: "Dobavljač 1",
          pib: "123123222",
          address: "Antifasisticke borbe 12",
          postNumber: "36000",
          city: "Kraljevo",
          email: "nikola@gmail.com",
          isRestaurant: null,
          phone: "01112312323",
          registrationNumber: "12312212",
          bankAccountNumber: "123-222222-33",
          hasData: true,
        },
        deadline: "2023-11-22T00:00:00.000Z",
        status: "COMPLETED",
        products: [
          {
            id: "06b46921-348b-4d5d-9b62-919c0d27156b",
            name: "LUK CRNI KG",
            amount: "1.00",
            status: "DELIVERED",
            price: null,
            notDeliveredReason: null,
            note: null,
            move_to_order_id: null,
            deliveredAmount: null,
          },
          {
            id: "0eeb0b5f-a312-4169-a663-28da1644094f",
            name: "SPANAC KG",
            amount: "2.00",
            status: "DELIVERED",
            price: null,
            notDeliveredReason: null,
            note: null,
            move_to_order_id: null,
            deliveredAmount: null,
          },
          {
            id: "016bd326-dda1-4437-8be6-2d0e7a75d5d5",
            name: "ZELJE KG",
            amount: "3.00",
            status: "DELIVERED",
            price: null,
            notDeliveredReason: null,
            note: null,
            move_to_order_id: null,
            deliveredAmount: null,
          },
          {
            id: "cdb7c339-00b6-477a-98f5-5996d65825a9",
            name: "100% GREJP CRVENI 1L",
            amount: "4.00",
            status: "DELIVERED",
            price: null,
            notDeliveredReason: null,
            note: null,
            move_to_order_id: null,
            deliveredAmount: null,
          },
          {
            id: "f8117ff5-cdd7-4680-8a3f-c37c412628b3",
            name: "100% LIMETA 1L",
            amount: "5.00",
            status: "DELIVERED",
            price: null,
            notDeliveredReason: null,
            note: null,
            move_to_order_id: null,
            deliveredAmount: null,
          },
          {
            id: "70140c33-d7e4-4bd3-9997-dd3bba75ebd1",
            name: "100% LIMUN 1L",
            amount: "6.00",
            status: "DELIVERED",
            price: null,
            notDeliveredReason: null,
            note: null,
            move_to_order_id: null,
            deliveredAmount: null,
          },
        ],
        department: {
          id: "d162cffb-acd5-4f8a-94fe-f5e026f99307",
          name: "Kuhinja",
        },
        lastUpdatedBy: {
          id: "031af993-a0c4-4035-8eb7-fcd6b1825f0f",
          name: "Nikola Cvetkovic",
          email: "cvelekv@gmail.com",
          phone: "643758137",
        },
        createdBy: {
          id: "031af993-a0c4-4035-8eb7-fcd6b1825f0f",
          name: "Nikola Cvetkovic",
          email: "cvelekv@gmail.com",
          phone: "643758137",
        },
        lastUpdatedAt: "2023-07-11T15:32:30.473Z",
        createdAt: "2023-07-11T15:31:51.522Z",
        orderNote: "test",
        paymentMethod: "cash",
        supplierContactNumber: "0112321213",
        order_number: "c29mfh",
      },
      {
        id: "1fdd0ee4-acaa-471e-ad2f-030121a266f5",
        supplier: {
          id: "5716a290-f009-4331-a641-949a14a71df2",
          name: "Dobavljač 1",
          pib: "123123222",
          address: "Antifasisticke borbe 12",
          postNumber: "36000",
          city: "Kraljevo",
          email: "nikola@gmail.com",
          isRestaurant: null,
          phone: "01112312323",
          registrationNumber: "12312212",
          bankAccountNumber: "123-222222-33",
          hasData: true,
        },
        deadline: "2023-11-23T00:00:00.000Z",
        status: "COMPLETED",
        products: [],
        department: {
          id: "d162cffb-acd5-4f8a-94fe-f5e026f99307",
          name: "Kuhinja",
        },
        lastUpdatedBy: {
          id: "031af993-a0c4-4035-8eb7-fcd6b1825f0f",
          name: "Nikola Cvetkovic",
          email: "cvelekv@gmail.com",
          phone: "643758137",
        },
        createdBy: {
          id: "031af993-a0c4-4035-8eb7-fcd6b1825f0f",
          name: "Nikola Cvetkovic",
          email: "cvelekv@gmail.com",
          phone: "643758137",
        },
        lastUpdatedAt: "2023-07-11T15:25:15.478Z",
        createdAt: "2023-07-11T15:24:51.345Z",
        orderNote: "TEST",
        paymentMethod: "cash",
        supplierContactNumber: "0112321213",
        order_number: "jrc4n",
      },
      {
        id: "5af2de5d-6a0a-4a02-9ec7-c6225b6c8486",
        supplier: {
          id: "5716a290-f009-4331-a641-949a14a71df2",
          name: "Dobavljač 1",
          pib: "123123222",
          address: "Antifasisticke borbe 12",
          postNumber: "36000",
          city: "Kraljevo",
          email: "nikola@gmail.com",
          isRestaurant: null,
          phone: "01112312323",
          registrationNumber: "12312212",
          bankAccountNumber: "123-222222-33",
          hasData: true,
        },
        deadline: "2023-11-24T00:00:00.000Z",
        status: "COMPLETED",
        products: [
          {
            id: "f8117ff5-cdd7-4680-8a3f-c37c412628b3",
            name: "100% LIMETA 1L",
            amount: "2.00",
            status: "DELIVERED",
            price: null,
            notDeliveredReason: null,
            note: null,
            move_to_order_id: null,
            deliveredAmount: null,
          },
        ],
        department: {
          id: "d162cffb-acd5-4f8a-94fe-f5e026f99307",
          name: "Kuhinja",
        },
        lastUpdatedBy: {
          id: "031af993-a0c4-4035-8eb7-fcd6b1825f0f",
          name: "Nikola Cvetkovic",
          email: "cvelekv@gmail.com",
          phone: "643758137",
        },
        createdBy: {
          id: "031af993-a0c4-4035-8eb7-fcd6b1825f0f",
          name: "Nikola Cvetkovic",
          email: "cvelekv@gmail.com",
          phone: "643758137",
        },
        lastUpdatedAt: "2023-07-10T20:23:27.452Z",
        createdAt: "2023-06-21T09:18:20.015Z",
        orderNote: "",
        paymentMethod: "cash",
        supplierContactNumber: "0112321213",
        order_number: "pzrn9",
      },
    ],
    total_order_count: 6,
    total_custom_order_count: 5,
  },
  ordersOpen: {
    allOrdersCombined: [
      {
        id: "58f467e1-2ca0-47b5-bd6c-10e75e063cdf",
        supplier: {
          id: "5716a290-f009-4331-a641-949a14a71df2",
          name: "Dobavljač 1",
          pib: "123123222",
          address: "Antifasisticke borbe 12",
          postNumber: "36000",
          city: "Kraljevo",
          email: "nikola@gmail.com",
          isRestaurant: null,
          phone: "01112312323",
          registrationNumber: "12312212",
          bankAccountNumber: "123-222222-33",
          hasData: true,
        },
        deadline: "2024-01-11T00:00:00.000Z",
        status: "OPEN",
        products: [
          {
            id: "f8117ff5-cdd7-4680-8a3f-c37c412628b3",
            name: "100% LIMETA 1L",
            amount: "4.00",
            status: "PENDING",
            price: null,
            notDeliveredReason: null,
            note: null,
            move_to_order_id: null,
            deliveredAmount: null,
          },
          {
            id: "70140c33-d7e4-4bd3-9997-dd3bba75ebd1",
            name: "100% LIMUN 1L",
            amount: "5.00",
            status: "PENDING",
            price: null,
            notDeliveredReason: null,
            note: null,
            move_to_order_id: null,
            deliveredAmount: null,
          },
        ],
        department: {
          id: "d162cffb-acd5-4f8a-94fe-f5e026f99307",
          name: "Kuhinja",
        },
        lastUpdatedBy: {
          id: "031af993-a0c4-4035-8eb7-fcd6b1825f0f",
          name: "Nikola Cvetkovic",
          email: "cvelekv@gmail.com",
          phone: "643758137",
        },
        createdBy: {
          id: "031af993-a0c4-4035-8eb7-fcd6b1825f0f",
          name: "Nikola Cvetkovic",
          email: "cvelekv@gmail.com",
          phone: "643758137",
        },
        lastUpdatedAt: "2023-06-29T08:49:23.829Z",
        createdAt: "2023-06-29T08:49:23.829Z",
        orderNote: null,
        paymentMethod: "cash",
        supplierContactNumber: null,
        order_number: "yn8ik",
      },
      {
        id: "613404a0-f0b1-4eb6-b858-1ed6e64becea",
        supplier: {
          id: "5716a290-f009-4331-a641-949a14a71df2",
          name: "Dobavljač 1",
          pib: "123123222",
          address: "Antifasisticke borbe 12",
          postNumber: "36000",
          city: "Kraljevo",
          email: "nikola@gmail.com",
          isRestaurant: null,
          phone: "01112312323",
          registrationNumber: "12312212",
          bankAccountNumber: "123-222222-33",
          hasData: true,
        },
        deadline: "2024-01-12T00:00:00.000Z",
        status: "OPEN",
        products: [
          {
            id: "70140c33-d7e4-4bd3-9997-dd3bba75ebd1",
            name: "100% LIMUN 1L",
            amount: "3.00",
            status: "PENDING",
            price: null,
            notDeliveredReason: null,
            note: null,
            move_to_order_id: null,
            deliveredAmount: null,
          },
          {
            id: "f8117ff5-cdd7-4680-8a3f-c37c412628b3",
            name: "100% LIMETA 1L",
            amount: "4.00",
            status: "PENDING",
            price: null,
            notDeliveredReason: null,
            note: null,
            move_to_order_id: null,
            deliveredAmount: null,
          },
          {
            id: "cdb7c339-00b6-477a-98f5-5996d65825a9",
            name: "100% GREJP CRVENI 1L",
            amount: "5.00",
            status: "PENDING",
            price: null,
            notDeliveredReason: null,
            note: null,
            move_to_order_id: null,
            deliveredAmount: null,
          },
        ],
        department: {
          id: "d162cffb-acd5-4f8a-94fe-f5e026f99307",
          name: "Kuhinja",
        },
        lastUpdatedBy: {
          id: "031af993-a0c4-4035-8eb7-fcd6b1825f0f",
          name: "Nikola Cvetkovic",
          email: "cvelekv@gmail.com",
          phone: "643758137",
        },
        createdBy: {
          id: "031af993-a0c4-4035-8eb7-fcd6b1825f0f",
          name: "Nikola Cvetkovic",
          email: "cvelekv@gmail.com",
          phone: "643758137",
        },
        lastUpdatedAt: "2023-06-29T08:49:04.235Z",
        createdAt: "2023-06-29T08:49:04.235Z",
        orderNote: null,
        paymentMethod: "cash",
        supplierContactNumber: null,
        order_number: "q0iaw",
      },
      {
        id: "94876fed-1724-4efc-aeee-7939e9a06be9",
        supplier: {
          id: "5716a290-f009-4331-a641-949a14a71df2",
          name: "Dobavljač 1",
          pib: "123123222",
          address: "Antifasisticke borbe 12",
          postNumber: "36000",
          city: "Kraljevo",
          email: "nikola@gmail.com",
          isRestaurant: null,
          phone: "01112312323",
          registrationNumber: "12312212",
          bankAccountNumber: "123-222222-33",
          hasData: true,
        },
        deadline: "2024-01-13T00:00:00.000Z",
        status: "OPEN",
        products: [
          {
            id: "06b46921-348b-4d5d-9b62-919c0d27156b",
            name: "LUK CRNI KG",
            amount: "1.00",
            status: "PENDING",
            price: null,
            notDeliveredReason: null,
            note: null,
            move_to_order_id: null,
            deliveredAmount: null,
          },
          {
            id: "0eeb0b5f-a312-4169-a663-28da1644094f",
            name: "SPANAC KG",
            amount: "2.00",
            status: "PENDING",
            price: null,
            notDeliveredReason: null,
            note: null,
            move_to_order_id: null,
            deliveredAmount: null,
          },
        ],
        department: {
          id: "d162cffb-acd5-4f8a-94fe-f5e026f99307",
          name: "Kuhinja",
        },
        lastUpdatedBy: {
          id: "031af993-a0c4-4035-8eb7-fcd6b1825f0f",
          name: "Nikola Cvetkovic",
          email: "cvelekv@gmail.com",
          phone: "643758137",
        },
        createdBy: {
          id: "031af993-a0c4-4035-8eb7-fcd6b1825f0f",
          name: "Nikola Cvetkovic",
          email: "cvelekv@gmail.com",
          phone: "643758137",
        },
        lastUpdatedAt: "2023-06-22T20:50:51.362Z",
        createdAt: "2023-06-22T20:50:51.362Z",
        orderNote: "test",
        paymentMethod: "",
        supplierContactNumber: null,
        order_number: "fiz48",
      },
      {
        id: "6a35447a-5b64-4b8d-a1f9-7b398cdfb68c",
        deadline: "2024-01-15T00:00:00.000Z",
        status: "OPEN",
        products: [
          {
            id: "065aeaae-04e8-4861-bbab-b8ef2bf7c972",
            name: "Artikal 1",
            amount: "1.00",
            status: "PENDING",
            price: null,
            notDeliveredReason: null,
            note: "",
            move_to_order_id: null,
            deliveredAmount: null,
          },
          {
            id: "5de1790f-1b74-4ac2-92e3-4aeba577b193",
            name: "Artikal 2",
            amount: "2.00",
            status: "PENDING",
            price: null,
            notDeliveredReason: null,
            note: "",
            move_to_order_id: null,
            deliveredAmount: null,
          },
        ],
        department: {
          id: "d162cffb-acd5-4f8a-94fe-f5e026f99307",
          name: "Kuhinja",
        },
        lastUpdatedBy: {
          id: "031af993-a0c4-4035-8eb7-fcd6b1825f0f",
          name: "Nikola Cvetkovic",
          email: "cvelekv@gmail.com",
          phone: "643758137",
        },
        createdBy: {
          id: "031af993-a0c4-4035-8eb7-fcd6b1825f0f",
          name: "Nikola Cvetkovic",
          email: "cvelekv@gmail.com",
          phone: "643758137",
        },
        lastUpdatedAt: "2023-03-02T21:57:28.972Z",
        createdAt: "2023-03-02T21:57:28.972Z",
        orderNote: null,
        paymentMethod: "cash",
        order_number: "3jfay",
      },
    ],
    customOrders: [
      {
        id: "6a35447a-5b64-4b8d-a1f9-7b398cdfb68c",
        deadline: "2024-01-16T00:00:00.000Z",
        status: "OPEN",
        products: [
          {
            id: "065aeaae-04e8-4861-bbab-b8ef2bf7c972",
            name: "Artikal 1 kuhinja",
            amount: "1.00",
            status: "PENDING",
            price: null,
            notDeliveredReason: null,
            note: "",
            move_to_order_id: null,
            deliveredAmount: null,
          },
          {
            id: "5de1790f-1b74-4ac2-92e3-4aeba577b193",
            name: "Kuhinja artikal",
            amount: "2.00",
            status: "PENDING",
            price: null,
            notDeliveredReason: null,
            note: "",
            move_to_order_id: null,
            deliveredAmount: null,
          },
        ],
        department: {
          id: "d162cffb-acd5-4f8a-94fe-f5e026f99307",
          name: "Kuhinja",
        },
        lastUpdatedBy: {
          id: "031af993-a0c4-4035-8eb7-fcd6b1825f0f",
          name: "Nikola Cvetkovic",
          email: "cvelekv@gmail.com",
          phone: "643758137",
        },
        createdBy: {
          id: "031af993-a0c4-4035-8eb7-fcd6b1825f0f",
          name: "Nikola Cvetkovic",
          email: "cvelekv@gmail.com",
          phone: "643758137",
        },
        lastUpdatedAt: "2023-03-02T21:57:28.972Z",
        createdAt: "2023-03-02T21:57:28.972Z",
        orderNote: null,
        paymentMethod: "cash",
        order_number: "3jfay",
      },
    ],
    orders: [
      {
        id: "58f467e1-2ca0-47b5-bd6c-10e75e063cdf",
        supplier: {
          id: "5716a290-f009-4331-a641-949a14a71df2",
          name: "Dobavljač 1",
          pib: "123123222",
          address: "Antifasisticke borbe 12",
          postNumber: "36000",
          city: "Kraljevo",
          email: "nikola@gmail.com",
          isRestaurant: null,
          phone: "01112312323",
          registrationNumber: "12312212",
          bankAccountNumber: "123-222222-33",
          hasData: true,
        },
        deadline: "2023-12-25T00:00:00.000Z",
        status: "OPEN",
        products: [
          {
            id: "f8117ff5-cdd7-4680-8a3f-c37c412628b3",
            name: "100% LIMETA 1L",
            amount: "4.00",
            status: "PENDING",
            price: null,
            notDeliveredReason: null,
            note: null,
            move_to_order_id: null,
            deliveredAmount: null,
          },
          {
            id: "70140c33-d7e4-4bd3-9997-dd3bba75ebd1",
            name: "100% LIMUN 1L",
            amount: "5.00",
            status: "PENDING",
            price: null,
            notDeliveredReason: null,
            note: null,
            move_to_order_id: null,
            deliveredAmount: null,
          },
        ],
        department: {
          id: "d162cffb-acd5-4f8a-94fe-f5e026f99307",
          name: "Kuhinja",
        },
        lastUpdatedBy: {
          id: "031af993-a0c4-4035-8eb7-fcd6b1825f0f",
          name: "Nikola Cvetkovic",
          email: "cvelekv@gmail.com",
          phone: "643758137",
        },
        createdBy: {
          id: "031af993-a0c4-4035-8eb7-fcd6b1825f0f",
          name: "Nikola Cvetkovic",
          email: "cvelekv@gmail.com",
          phone: "643758137",
        },
        lastUpdatedAt: "2023-06-29T08:49:23.829Z",
        createdAt: "2023-06-29T08:49:23.829Z",
        orderNote: null,
        paymentMethod: "cash",
        supplierContactNumber: null,
        order_number: "yn8ik",
      },
      {
        id: "613404a0-f0b1-4eb6-b858-1ed6e64becea",
        supplier: {
          id: "5716a290-f009-4331-a641-949a14a71df2",
          name: "Dobavljač 1",
          pib: "123123222",
          address: "Antifasisticke borbe 12",
          postNumber: "36000",
          city: "Kraljevo",
          email: "nikola@gmail.com",
          isRestaurant: null,
          phone: "01112312323",
          registrationNumber: "12312212",
          bankAccountNumber: "123-222222-33",
          hasData: true,
        },
        deadline: "2024-01-18T00:00:00.000Z",
        status: "OPEN",
        products: [
          {
            id: "70140c33-d7e4-4bd3-9997-dd3bba75ebd1",
            name: "100% LIMUN 1L",
            amount: "3.00",
            status: "PENDING",
            price: null,
            notDeliveredReason: null,
            note: null,
            move_to_order_id: null,
            deliveredAmount: null,
          },
          {
            id: "f8117ff5-cdd7-4680-8a3f-c37c412628b3",
            name: "100% LIMETA 1L",
            amount: "4.00",
            status: "PENDING",
            price: null,
            notDeliveredReason: null,
            note: null,
            move_to_order_id: null,
            deliveredAmount: null,
          },
          {
            id: "cdb7c339-00b6-477a-98f5-5996d65825a9",
            name: "100% GREJP CRVENI 1L",
            amount: "5.00",
            status: "PENDING",
            price: null,
            notDeliveredReason: null,
            note: null,
            move_to_order_id: null,
            deliveredAmount: null,
          },
        ],
        department: {
          id: "d162cffb-acd5-4f8a-94fe-f5e026f99307",
          name: "Kuhinja",
        },
        lastUpdatedBy: {
          id: "031af993-a0c4-4035-8eb7-fcd6b1825f0f",
          name: "Nikola Cvetkovic",
          email: "cvelekv@gmail.com",
          phone: "643758137",
        },
        createdBy: {
          id: "031af993-a0c4-4035-8eb7-fcd6b1825f0f",
          name: "Nikola Cvetkovic",
          email: "cvelekv@gmail.com",
          phone: "643758137",
        },
        lastUpdatedAt: "2023-06-29T08:49:04.235Z",
        createdAt: "2023-06-29T08:49:04.235Z",
        orderNote: null,
        paymentMethod: "cash",
        supplierContactNumber: null,
        order_number: "q0iaw",
      },
      {
        id: "94876fed-1724-4efc-aeee-7939e9a06be9",
        supplier: {
          id: "5716a290-f009-4331-a641-949a14a71df2",
          name: "Dobavljač 1",
          pib: "123123222",
          address: "Antifasisticke borbe 12",
          postNumber: "36000",
          city: "Kraljevo",
          email: "nikola@gmail.com",
          isRestaurant: null,
          phone: "01112312323",
          registrationNumber: "12312212",
          bankAccountNumber: "123-222222-33",
          hasData: true,
        },
        deadline: "2024-01-19T00:00:00.000Z",
        status: "OPEN",
        products: [
          {
            id: "06b46921-348b-4d5d-9b62-919c0d27156b",
            name: "LUK CRNI KG",
            amount: "1.00",
            status: "PENDING",
            price: null,
            notDeliveredReason: null,
            note: null,
            move_to_order_id: null,
            deliveredAmount: null,
          },
          {
            id: "0eeb0b5f-a312-4169-a663-28da1644094f",
            name: "SPANAC KG",
            amount: "2.00",
            status: "PENDING",
            price: null,
            notDeliveredReason: null,
            note: null,
            move_to_order_id: null,
            deliveredAmount: null,
          },
        ],
        department: {
          id: "d162cffb-acd5-4f8a-94fe-f5e026f99307",
          name: "Kuhinja",
        },
        lastUpdatedBy: {
          id: "031af993-a0c4-4035-8eb7-fcd6b1825f0f",
          name: "Nikola Cvetkovic",
          email: "cvelekv@gmail.com",
          phone: "643758137",
        },
        createdBy: {
          id: "031af993-a0c4-4035-8eb7-fcd6b1825f0f",
          name: "Nikola Cvetkovic",
          email: "cvelekv@gmail.com",
          phone: "643758137",
        },
        lastUpdatedAt: "2023-06-22T20:50:51.362Z",
        createdAt: "2023-06-22T20:50:51.362Z",
        orderNote: "test",
        paymentMethod: "",
        supplierContactNumber: null,
        order_number: "fiz48",
      },
    ],
    total_order_count: 3,
    total_custom_order_count: 1,
  },
  ordersInProgress: {
    allOrdersCombined: [
      {
        id: "22a58093-142c-41e8-938c-67495c393b78",
        supplier: {
          id: "3d927cf4-18cf-48e0-a850-78c23859f56a",
          name: "Dobavljač 2",
          pib: "123412412",
          address: "alfj",
          postNumber: "lkjdf",
          city: "lksjlskdjgl",
          email: "sdsg",
          isRestaurant: null,
          phone: "lksdgjs",
          registrationNumber: "12312314",
          bankAccountNumber: "slkdgjs",
          hasData: true,
        },
        deadline: "2024-01-25T00:00:00.000Z",
        status: "IN_PROGRESS",
        products: [
          {
            id: "71ff3c13-5533-487a-b82d-cb7fedff36a4",
            name: "ANANAS KG",
            amount: "1.00",
            status: "PENDING",
            price: null,
            notDeliveredReason: null,
            note: null,
            move_to_order_id: null,
            deliveredAmount: null,
          },
          {
            id: "fcbcc852-1776-4b8b-be2f-0056d8f38d4d",
            name: "ANIS-KOMORAC KG",
            amount: "2.00",
            status: "PENDING",
            price: null,
            notDeliveredReason: null,
            note: null,
            move_to_order_id: null,
            deliveredAmount: null,
          },
          {
            id: "89c0b751-ef90-4c74-ba4d-735200ac183b",
            name: "AVOKADO KG",
            amount: "4.00",
            status: "PENDING",
            price: null,
            notDeliveredReason: null,
            note: null,
            move_to_order_id: null,
            deliveredAmount: null,
          },
          {
            id: "ca7cd305-5733-4bbd-9780-0bd5d0a4d7a5",
            name: "BABY SPANAC KG",
            amount: "6.00",
            status: "PENDING",
            price: null,
            notDeliveredReason: null,
            note: null,
            move_to_order_id: null,
            deliveredAmount: null,
          },
        ],
        department: {
          id: "d162cffb-acd5-4f8a-94fe-f5e026f99307",
          name: "Kuhinja",
        },
        lastUpdatedBy: {
          id: "031af993-a0c4-4035-8eb7-fcd6b1825f0f",
          name: "Nikola Cvetkovic",
          email: "cvelekv@gmail.com",
          phone: "643758137",
        },
        createdBy: {
          id: "031af993-a0c4-4035-8eb7-fcd6b1825f0f",
          name: "Nikola Cvetkovic",
          email: "cvelekv@gmail.com",
          phone: "643758137",
        },
        lastUpdatedAt: "2023-10-30T11:27:55.529Z",
        createdAt: "2023-10-30T11:27:55.244Z",
        orderNote: "estes",
        paymentMethod: "cash",
        supplierContactNumber: "0643758137",
        order_number: "jjwfr",
      },
    ],
    customOrders: [],
    orders: [
      {
        id: "22a58093-142c-41e8-938c-67495c393b78",
        supplier: {
          id: "3d927cf4-18cf-48e0-a850-78c23859f56a",
          name: "Dobavljač 2",
          pib: "123412412",
          address: "alfj",
          postNumber: "lkjdf",
          city: "lksjlskdjgl",
          email: "sdsg",
          isRestaurant: null,
          phone: "lksdgjs",
          registrationNumber: "12312314",
          bankAccountNumber: "slkdgjs",
          hasData: true,
        },
        deadline: "2024-01-26T00:00:00.000Z",
        status: "IN_PROGRESS",
        products: [
          {
            id: "71ff3c13-5533-487a-b82d-cb7fedff36a4",
            name: "ANANAS KG",
            amount: "1.00",
            status: "PENDING",
            price: null,
            notDeliveredReason: null,
            note: null,
            move_to_order_id: null,
            deliveredAmount: null,
          },
          {
            id: "fcbcc852-1776-4b8b-be2f-0056d8f38d4d",
            name: "ANIS-KOMORAC KG",
            amount: "2.00",
            status: "PENDING",
            price: null,
            notDeliveredReason: null,
            note: null,
            move_to_order_id: null,
            deliveredAmount: null,
          },
          {
            id: "89c0b751-ef90-4c74-ba4d-735200ac183b",
            name: "AVOKADO KG",
            amount: "4.00",
            status: "PENDING",
            price: null,
            notDeliveredReason: null,
            note: null,
            move_to_order_id: null,
            deliveredAmount: null,
          },
          {
            id: "ca7cd305-5733-4bbd-9780-0bd5d0a4d7a5",
            name: "BABY SPANAC KG",
            amount: "6.00",
            status: "PENDING",
            price: null,
            notDeliveredReason: null,
            note: null,
            move_to_order_id: null,
            deliveredAmount: null,
          },
        ],
        department: {
          id: "d162cffb-acd5-4f8a-94fe-f5e026f99307",
          name: "Kuhinja",
        },
        lastUpdatedBy: {
          id: "031af993-a0c4-4035-8eb7-fcd6b1825f0f",
          name: "Nikola Cvetkovic",
          email: "cvelekv@gmail.com",
          phone: "643758137",
        },
        createdBy: {
          id: "031af993-a0c4-4035-8eb7-fcd6b1825f0f",
          name: "Nikola Cvetkovic",
          email: "cvelekv@gmail.com",
          phone: "643758137",
        },
        lastUpdatedAt: "2023-10-30T11:27:55.529Z",
        createdAt: "2023-10-30T11:27:55.244Z",
        orderNote: "estes",
        paymentMethod: "cash",
        supplierContactNumber: "0643758137",
        order_number: "jjwfr",
      },
    ],
    total_order_count: 1,
    total_custom_order_count: 0,
  },
};
let ordersForDepartmentTwo = {
  ordersInProgress: {
    allOrdersCombined: [],
    customOrders: [],
    orders: [],
    total_order_count: 0,
    total_custom_order_count: 0,
  },
  ordersOpen: {
    allOrdersCombined: [
      {
        id: "fe5e42fb-8761-4997-acbc-22f2b8d3c1fb",
        supplier: {
          id: "5716a290-f009-4331-a641-949a14a71df2",
          name: "Dobavljač 1",
          pib: "123123222",
          address: "Antifasisticke borbe 12",
          postNumber: "36000",
          city: "Kraljevo",
          email: "nikola@gmail.com",
          isRestaurant: null,
          phone: "01112312323",
          registrationNumber: "12312212",
          bankAccountNumber: "123-222222-33",
          hasData: true,
        },
        deadline: "2024-01-22T00:00:00.000Z",
        status: "OPEN",
        products: [
          {
            id: "cdb7c339-00b6-477a-98f5-5996d65825a9",
            name: "100% GREJP CRVENI 1L",
            amount: "2.00",
            status: "PENDING",
            price: null,
            notDeliveredReason: null,
            note: null,
            move_to_order_id: null,
            deliveredAmount: null,
          },
        ],
        department: {
          id: "aa3496fe-b8c3-427e-a55e-3ba458571748",
          name: "Bar",
        },
        lastUpdatedBy: {
          id: "031af993-a0c4-4035-8eb7-fcd6b1825f0f",
          name: "Nikola Cvetkovic",
          email: "cvelekv@gmail.com",
          phone: "643758137",
        },
        createdBy: {
          id: "031af993-a0c4-4035-8eb7-fcd6b1825f0f",
          name: "Nikola Cvetkovic",
          email: "cvelekv@gmail.com",
          phone: "643758137",
        },
        lastUpdatedAt: "2023-07-13T15:59:52.471Z",
        createdAt: "2023-07-13T15:59:52.471Z",
        orderNote: "",
        paymentMethod: "",
        supplierContactNumber: null,
        order_number: "etesi",
      },
    ],
    customOrders: [],
    orders: [
      {
        id: "fe5e42fb-8761-4997-acbc-22f2b8d3c1fb",
        supplier: {
          id: "5716a290-f009-4331-a641-949a14a71df2",
          name: "Dobavljač 1",
          pib: "123123222",
          address: "Antifasisticke borbe 12",
          postNumber: "36000",
          city: "Kraljevo",
          email: "nikola@gmail.com",
          isRestaurant: null,
          phone: "01112312323",
          registrationNumber: "12312212",
          bankAccountNumber: "123-222222-33",
          hasData: true,
        },
        deadline: "2024-01-24T00:00:00.000Z",
        status: "OPEN",
        products: [
          {
            id: "cdb7c339-00b6-477a-98f5-5996d65825a9",
            name: "100% GREJP CRVENI 1L",
            amount: "2.00",
            status: "PENDING",
            price: null,
            notDeliveredReason: null,
            note: null,
            move_to_order_id: null,
            deliveredAmount: null,
          },
        ],
        department: {
          id: "aa3496fe-b8c3-427e-a55e-3ba458571748",
          name: "Bar",
        },
        lastUpdatedBy: {
          id: "031af993-a0c4-4035-8eb7-fcd6b1825f0f",
          name: "Nikola Cvetkovic",
          email: "cvelekv@gmail.com",
          phone: "643758137",
        },
        createdBy: {
          id: "031af993-a0c4-4035-8eb7-fcd6b1825f0f",
          name: "Nikola Cvetkovic",
          email: "cvelekv@gmail.com",
          phone: "643758137",
        },
        lastUpdatedAt: "2023-07-13T15:59:52.471Z",
        createdAt: "2023-07-13T15:59:52.471Z",
        orderNote: "",
        paymentMethod: "",
        supplierContactNumber: null,
        order_number: "etesi",
      },
    ],
    total_order_count: 1,
    total_custom_order_count: 0,
  },
  ordersCompleted: {
    allOrdersCombined: [
      {
        id: "95e53189-038a-4a7d-89c1-700921c76d27",
        supplier: {
          id: "5716a290-f009-4331-a641-949a14a71df2",
          name: "Dobavljač 1",
          pib: "123123222",
          address: "Antifasisticke borbe 12",
          postNumber: "36000",
          city: "Kraljevo",
          email: "nikola@gmail.com",
          isRestaurant: null,
          phone: "01112312323",
          registrationNumber: "12312212",
          bankAccountNumber: "123-222222-33",
          hasData: true,
        },
        deadline: "2023-11-25T00:00:00.000Z",
        status: "COMPLETED",
        products: [
          {
            id: "cdb7c339-00b6-477a-98f5-5996d65825a9",
            name: "100% GREJP CRVENI 1L",
            amount: "1.00",
            status: "DELIVERED",
            price: null,
            notDeliveredReason: null,
            note: null,
            move_to_order_id: null,
            deliveredAmount: null,
          },
          {
            id: "f8117ff5-cdd7-4680-8a3f-c37c412628b3",
            name: "100% LIMETA 1L",
            amount: "2.00",
            status: "DELIVERED",
            price: null,
            notDeliveredReason: null,
            note: null,
            move_to_order_id: null,
            deliveredAmount: null,
          },
          {
            id: "70140c33-d7e4-4bd3-9997-dd3bba75ebd1",
            name: "100% LIMUN 1L",
            amount: "3.00",
            status: "DELIVERED",
            price: null,
            notDeliveredReason: null,
            note: null,
            move_to_order_id: null,
            deliveredAmount: "2.50",
          },
        ],
        department: {
          id: "aa3496fe-b8c3-427e-a55e-3ba458571748",
          name: "Bar",
        },
        lastUpdatedBy: {
          id: "031af993-a0c4-4035-8eb7-fcd6b1825f0f",
          name: "Nikola Cvetkovic",
          email: "cvelekv@gmail.com",
          phone: "643758137",
        },
        createdBy: {
          id: "031af993-a0c4-4035-8eb7-fcd6b1825f0f",
          name: "Nikola Cvetkovic",
          email: "cvelekv@gmail.com",
          phone: "643758137",
        },
        lastUpdatedAt: "2023-07-12T08:25:06.393Z",
        createdAt: "2023-07-12T08:24:35.789Z",
        orderNote: "test",
        paymentMethod: "cash",
        supplierContactNumber: "0112321213",
        order_number: "lh0l3",
      },
      {
        id: "60609bb9-dd9d-487d-9e5f-e066dba57ccf",
        deadline: "2023-11-26T00:00:00.000Z",
        status: "COMPLETED",
        products: [
          {
            id: "8712c2a5-f2cb-4634-bfd9-f5c55c7b62ed",
            name: "ARtikal bar ",
            amount: "2.00",
            status: "DELIVERED",
            price: null,
            notDeliveredReason: null,
            note: "",
            move_to_order_id: null,
            deliveredAmount: "1.50",
          },
        ],
        department: {
          id: "aa3496fe-b8c3-427e-a55e-3ba458571748",
          name: "Bar",
        },
        lastUpdatedBy: {
          id: "031af993-a0c4-4035-8eb7-fcd6b1825f0f",
          name: "Nikola Cvetkovic",
          email: "cvelekv@gmail.com",
          phone: "643758137",
        },
        createdBy: {
          id: "031af993-a0c4-4035-8eb7-fcd6b1825f0f",
          name: "Nikola Cvetkovic",
          email: "cvelekv@gmail.com",
          phone: "643758137",
        },
        lastUpdatedAt: "2023-07-12T08:26:10.830Z",
        createdAt: "2023-07-12T08:25:41.778Z",
        orderNote: "",
        paymentMethod: "cash",
        order_number: "3lxl5",
      },
    ],
    customOrders: [
      {
        id: "60609bb9-dd9d-487d-9e5f-e066dba57ccf",
        deadline: "2023-11-27T00:00:00.000Z",
        status: "COMPLETED",
        products: [
          {
            id: "8712c2a5-f2cb-4634-bfd9-f5c55c7b62ed",
            name: "ARtikal bar ",
            amount: "2.00",
            status: "DELIVERED",
            price: null,
            notDeliveredReason: null,
            note: "",
            move_to_order_id: null,
            deliveredAmount: "1.50",
          },
        ],
        department: {
          id: "aa3496fe-b8c3-427e-a55e-3ba458571748",
          name: "Bar",
        },
        lastUpdatedBy: {
          id: "031af993-a0c4-4035-8eb7-fcd6b1825f0f",
          name: "Nikola Cvetkovic",
          email: "cvelekv@gmail.com",
          phone: "643758137",
        },
        createdBy: {
          id: "031af993-a0c4-4035-8eb7-fcd6b1825f0f",
          name: "Nikola Cvetkovic",
          email: "cvelekv@gmail.com",
          phone: "643758137",
        },
        lastUpdatedAt: "2023-07-12T08:26:10.830Z",
        createdAt: "2023-07-12T08:25:41.778Z",
        orderNote: "",
        paymentMethod: "cash",
        order_number: "3lxl5",
      },
    ],
    orders: [
      {
        id: "95e53189-038a-4a7d-89c1-700921c76d27",
        supplier: {
          id: "5716a290-f009-4331-a641-949a14a71df2",
          name: "Dobavljač 1",
          pib: "123123222",
          address: "Antifasisticke borbe 12",
          postNumber: "36000",
          city: "Kraljevo",
          email: "nikola@gmail.com",
          isRestaurant: null,
          phone: "01112312323",
          registrationNumber: "12312212",
          bankAccountNumber: "123-222222-33",
          hasData: true,
        },
        deadline: "2023-11-28T00:00:00.000Z",
        status: "COMPLETED",
        products: [
          {
            id: "cdb7c339-00b6-477a-98f5-5996d65825a9",
            name: "100% GREJP CRVENI 1L",
            amount: "1.00",
            status: "DELIVERED",
            price: null,
            notDeliveredReason: null,
            note: null,
            move_to_order_id: null,
            deliveredAmount: null,
          },
          {
            id: "f8117ff5-cdd7-4680-8a3f-c37c412628b3",
            name: "100% LIMETA 1L",
            amount: "2.00",
            status: "DELIVERED",
            price: null,
            notDeliveredReason: null,
            note: null,
            move_to_order_id: null,
            deliveredAmount: null,
          },
          {
            id: "70140c33-d7e4-4bd3-9997-dd3bba75ebd1",
            name: "100% LIMUN 1L",
            amount: "3.00",
            status: "DELIVERED",
            price: null,
            notDeliveredReason: null,
            note: null,
            move_to_order_id: null,
            deliveredAmount: "2.50",
          },
        ],
        department: {
          id: "aa3496fe-b8c3-427e-a55e-3ba458571748",
          name: "Bar",
        },
        lastUpdatedBy: {
          id: "031af993-a0c4-4035-8eb7-fcd6b1825f0f",
          name: "Nikola Cvetkovic",
          email: "cvelekv@gmail.com",
          phone: "643758137",
        },
        createdBy: {
          id: "031af993-a0c4-4035-8eb7-fcd6b1825f0f",
          name: "Nikola Cvetkovic",
          email: "cvelekv@gmail.com",
          phone: "643758137",
        },
        lastUpdatedAt: "2023-07-12T08:25:06.393Z",
        createdAt: "2023-07-12T08:24:35.789Z",
        orderNote: "test",
        paymentMethod: "cash",
        supplierContactNumber: "0112321213",
        order_number: "lh0l3",
      },
    ],
    total_order_count: 1,
    total_custom_order_count: 1,
  },
};

export default {
  ordersForDepartmentOne,
  ordersForDepartmentTwo,
};
