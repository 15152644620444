import LocalStorageService from "../services/local-storage.service";
import articlesData from "../shared/articlesData";
import ordersData from "../shared/ordersData";
import { orderStatuses } from "../shared/statuses";
import suppliersData from "../shared/suppliersData";

const initialState = {
  venue: {
    id: "123123-3712-55555-66666-asasd21321123",
    name: "Demo Restoran",
    disabled: false,
    bussiness_name: "Demo Business",
    pib: "123123121",
    address: "Ulica bb",
    phoneNumber: "036354494",
    email: "demo@mail.rs",
    venueCategories: [
      {
        id: "123asdds-12aas-asdda11-aads21dsa-123asdads",
        name: "Nacionalna",
      },
      {
        id: "13212123-dasadsads-123-xv233-213adsdasads",
        name: "Mediteranska",
      },
      {
        id: "123123-44444-4ef5-5555-666dgfgdf33",
        name: "Meksička",
      },
    ],
  },
  departments: [
    {
      id: "d162cffb-acd5-4f8a-94fe-f5e026f99307",
      name: "Kuhinja",
      numberOfOpenOrders:
        ordersData.ordersForDepartmentOne.ordersOpen.allOrdersCombined.length,
      numberOfExpectedOrders:
        ordersData.ordersForDepartmentOne.ordersInProgress.allOrdersCombined
          .length,
    },
    {
      id: "aa3496fe-b8c3-427e-a55e-3ba458571748",
      name: "Bar",
      numberOfOpenOrders:
        ordersData.ordersForDepartmentTwo.ordersOpen.total_order_count +
        ordersData.ordersForDepartmentTwo.ordersOpen.total_custom_order_count,
      numberOfExpectedOrders:
        ordersData.ordersForDepartmentTwo.ordersInProgress.total_order_count +
        ordersData.ordersForDepartmentTwo.ordersInProgress
          .total_custom_order_count,
    },
  ],
  ordersForDepartmentOne: ordersData.ordersForDepartmentOne,
  ordersForDepartmentTwo: ordersData.ordersForDepartmentTwo,
  totalOrdersCount: 0,
  totalCustomOrdersCount: 0,
  allOrders: [],
  allCustomOrders: [],
  allOrdersInProgressAndDueDate: [],
  allSuppliersForDepartment: [],
  suppliersForDepartmentOne: suppliersData.suppliersForDepartmentOne,
  suppliersForDepartmentTwo: suppliersData.suppliersForDepartmentTwo,
  articleCategoriesForSupplier: [],
  allArticles: articlesData.allArticles,
  allCustomArticles: articlesData.allCustomArticles,
  allArticlesSupplier: [],
  customArticles: [],
  allCustomArticlesCount: 0,
  totalArticlesCount: 0,
  selectedSupplierNote: null,
  selectedSupplierContacts: null,
  user: {
    id: "6527feb7-7ab1-456c-bd2b-950832f263e9",
    email: "test@gmail.com",
    phone: "641234567",
    name: "Nikola Nikolic",
    language: "ser",
    theme: "light",
    verified: true,
    venueInvites: [],
    departments: [
      {
        id: "d162cffb-acd5-4f8a-94fe-f5e026f99307",
        name: "Kuhinja",
      },
      {
        id: "aa3496fe-b8c3-427e-a55e-3ba458571748",
        name: "Bar",
      },
    ],
    venue: {
      id: "123123-3712-55555-66666-asasd21321123",
      name: "Demo Restoran",
      disabled: false,
      bussiness_name: "Demo Business",
      pib: "123123121",
      address: "Jovana Deroka bb",
      phoneNumber: "036354494",
      email: "demo@supplyme.rs",
      venueCategories: [
        {
          id: "123asdds-12aas-asdda11-aads21dsa-123asdads",
          name: "Nacionalna",
        },
        {
          id: "13212123-dasadsads-123-xv233-213adsdasads",
          name: "Mediteranska",
        },
        {
          id: "123123-44444-4ef5-5555-666dgfgdf33",
          name: "Meksička",
        },
      ],
    },
  },
  isOrdersDataLoading: false,
  isArticleLoading: false,
  isDataLoading: false,
  isTourDisabled: false,
};

export const allState = {
  namespaced: true,
  state: initialState,
  actions: {
    getOrders({ commit }, payload) {
      commit("getOrdersSuccess", payload);
      return Promise.resolve();
    },
    getSuppliersForDepartment({ commit }, payload) {
      commit("getSuppliersForDepartmentSuccess", payload);
      return Promise.resolve();
    },
    getAllArticleSupplierCategories({ commit }, payload) {
      commit("getAllArticleSupplierCategoriesSuccess", payload);
      return Promise.resolve();
    },
    getAllArticleCategories({ commit }, payload) {
      commit("getAllArticleCategoriesSuccess", payload);
      return Promise.resolve();
    },
    getArticlesSupplier({ commit }, payload) {
      commit("getArticlesSupplierSuccess", payload);
      return Promise.resolve();
    },
    getCustomArticles({ commit }, payload) {
      commit("getCustomArticlesSuccess", payload);
      return Promise.resolve();
    },

    getSupplierNotes({ commit }, payload) {
      commit("getSupplierNotesSuccess", payload);
      return Promise.resolve();
    },
    getAllSupplierContacts({ commit }, payload) {
      commit("getSupplierContactsSuccess", payload);
      return Promise.resolve();
    },
    saveOrder({ commit }, payload) {
      commit("saveNewOrderSuccess", payload);
      return Promise.resolve();
    },
    saveCustomOrder({ commit }, payload) {
      commit("saveCustomOrderSuccess", payload);
      return Promise.resolve();
    },
    createOrder({ commit }, payload) {
      commit("createOrderSuccess", payload);
      return Promise.resolve();
    },
    createNewOrder({ commit }, payload) {
      commit("createNewOrderSuccess", payload);
      return Promise.resolve();
    },
    createNewCustomOrder({ commit }, payload) {
      commit("createNewCustomOrderSuccess", payload);
      return Promise.resolve();
    },
    createCustomOrder({ commit }, payload) {
      commit("createCustomOrderSuccess", payload);
      return Promise.resolve();
    },
    sendOrder({ commit }, payload) {
      commit("sendOrderSuccess", payload);
      return Promise.resolve();
    },
    sendCustomOrder({ commit }, payload) {
      commit("sendCustomOrderSuccess", payload);
      return Promise.resolve();
    },
    toggleFavoriteArticle({ commit }, payload) {
      commit("toggleFavoriteArticleSuccess", payload);
      return Promise.resolve();
    },
    toggleCustomArticleFavorite({ commit }, payload) {
      commit("toggleCustomArticleFavoriteSuccess", payload);
      return Promise.resolve();
    },
    addNewArticle({ commit }, payload) {
      commit("addNewArticleSuccess", payload);
      return Promise.resolve();
    },
    deleteCustomArticle({ commit }, payload) {
      commit("deleteCustomArticleSuccess", payload);
      return Promise.resolve();
    },
    editCustomArticle({ commit }, payload) {
      commit("editCustomArticleSuccess", payload);
      return Promise.resolve();
    },
    updateContactSupplier({ commit }, payload) {
      commit("updateContactSupplierSuccess", payload);
      return Promise.resolve();
    },
    deleteContactSupplier({ commit }, payload) {
      commit("deleteContactSupplierSuccess", payload);
      return Promise.resolve();
    },
    updateOrder({ commit }, payload) {
      commit("updateOrderSuccess", payload);
      return Promise.resolve();
    },
    updateCustomOrder({ commit }, payload) {
      commit("updateCustomOrderSuccess", payload);
      return Promise.resolve();
    },
    disableTour({ commit }, payload) {
      commit("disableTourSuccess", payload);
      return Promise.resolve();
    },
    addContactSupplier({ commit }, payload) {
      commit("addContactSupplierSuccess", payload);
      return Promise.resolve();
    },
    addSupplierNotes({ commit }, payload) {
      commit("addSupplierNotesSuccess", payload);
      return Promise.resolve();
    },
    deleteOrder({ commit }, payload) {
      commit("deleteOrderSuccess", payload);
      return Promise.resolve();
    },
    deleteCustomOrder({ commit }, payload) {
      commit("deleteCustomOrderSuccess", payload);
      return Promise.resolve();
    },
    completeOrder({ commit }, payload) {
      commit("completeOrderSuccess", payload);
      return Promise.resolve();
    },
    setSelectedLanguage({ commit }, payload) {
      commit("setSelectedLanguageSuccess", payload);
      return Promise.resolve();
    },
  },
  mutations: {
    getOrdersSuccess(state, payload) {
      // console.log("payload", payload);
      state.isOrdersDataLoading = true;
      let data = null;
      let dataForDepartment =
        payload.departmentId === "d162cffb-acd5-4f8a-94fe-f5e026f99307"
          ? state.ordersForDepartmentOne
          : state.ordersForDepartmentTwo;
      if (payload.status === orderStatuses.OPEN) {
        data = dataForDepartment.ordersOpen;
      } else if (payload.status === orderStatuses.COMPLETED) {
        data = dataForDepartment.ordersCompleted;
      } else if (payload.status === orderStatuses.IN_PROGRESS) {
        data = dataForDepartment.ordersInProgress;
      }

      state.totalOrdersCount = data.total_order_count;
      state.totalCustomOrdersCount = data.total_custom_order_count;
      let tempOrders = data.orders;
      if (tempOrders) {
        tempOrders.forEach((d) => {
          switch (d.status) {
            case orderStatuses.OPEN:
              d.statusText =
                state.user.language === "ser" ? "Otvorena" : "Open";
              break;
            case orderStatuses.COMPLETED:
              d.statusText =
                state.user.language === "ser" ? "Isporučeno" : "Delivered";
              break;
            case orderStatuses.IN_PROGRESS:
              d.statusText =
                state.user.language === "ser" ? "Naručeno" : "Ordered";
              break;
            case orderStatuses.INCOMPLETE:
              d.statusText =
                state.user.language === "ser" ? "NEKOMPLETNA" : "INCOMPLETE";
              break;
          }
        });
      }
      let tempCustomOrders = data.customOrders;
      if (tempCustomOrders) {
        tempCustomOrders.forEach((d) => {
          d.isCustomOrder = true;
          switch (d.status) {
            case orderStatuses.OPEN:
              d.statusText =
                state.user.language === "ser" ? "Otvorena" : "Open";
              break;
            case orderStatuses.COMPLETED:
              d.statusText =
                state.user.language === "ser" ? "Isporučeno" : "Delivered";
              break;
            case orderStatuses.IN_PROGRESS:
              d.statusText =
                state.user.language === "ser" ? "Naručeno" : "Ordered";
              break;
            case orderStatuses.INCOMPLETE:
              d.statusText =
                state.user.language === "ser" ? "NEKOMPLETNA" : "INCOMPLETE";
              break;
          }
        });
      }

      const tempCombinedOrders = data.allOrdersCombined;
      tempCombinedOrders.forEach((d) => {
        d.isCustomOrder = !d.supplier;
        switch (d.status) {
          case orderStatuses.OPEN:
            d.statusText = state.user.language === "ser" ? "Otvorena" : "Open";
            break;
          case orderStatuses.COMPLETED:
            d.statusText =
              state.user.language === "ser" ? "Isporučeno" : "Delivered";
            break;
          case orderStatuses.IN_PROGRESS:
            d.statusText =
              state.user.language === "ser" ? "Naručeno" : "Ordered";
            break;
          case orderStatuses.INCOMPLETE:
            d.statusText =
              state.user.language === "ser" ? "NEKOMPLETNA" : "INCOMPLETE";
            break;
        }
      });

      state.allOrders = tempCombinedOrders;

      const timestamp = new Date().getTime();
      const yesterdayTimeStamp = timestamp - 24 * 60 * 60 * 1000;
      const yesterdayDate = new Date(yesterdayTimeStamp).toISOString();
      state.allOrdersInProgressAndDueDate =
        dataForDepartment.ordersInProgress.allOrdersCombined.filter(
          (order) => yesterdayDate >= order.deadline
        );
      setTimeout(() => {
        state.isOrdersDataLoading = false;
      }, 500);
    },
    getSuppliersForDepartmentSuccess(state, payload) {
      state.isArticleLoading = true;
      state.allArticlesSupplier = [];
      if (payload.departmentId === "d162cffb-acd5-4f8a-94fe-f5e026f99307") {
        state.allSuppliersForDepartment = state.suppliersForDepartmentOne;
      } else {
        state.allSuppliersForDepartment = state.suppliersForDepartmentTwo;
      }
      setTimeout(() => {
        state.isArticleLoading = false;
      }, 500);
    },
    getAllArticleSupplierCategoriesSuccess(state, payload) {
      state.isArticleLoading = true;
      const supplierCategory = suppliersData.allSuppliersCategories.find(
        (supplierCategory) => supplierCategory.supplierId === payload.supplierId
      );
      state.articleCategoriesForSupplier = supplierCategory.categories;
      setTimeout(() => {
        state.isArticleLoading = false;
      }, 500);
    },
    getAllArticleCategoriesSuccess(state, payload) {
      state.allCategories = suppliersData.marketCategories;
    },
    getArticlesSupplierSuccess(state, payload) {
      state.allArticlesSupplier = [];
      state.isArticleLoading = true;
      let supplierArticles = state.allArticles
        .find(
          (departmentArticles) =>
            departmentArticles.departmentId === payload.departmentId
        )
        .suppliers.find(
          (supplier) => supplier.supplierId === payload.supplierId
        ).articles;

      if (payload.onlyFavorite) {
        supplierArticles = supplierArticles.filter(
          (article) => article.isFavorite
        );
      }
      if (payload.categoryId) {
        supplierArticles = supplierArticles.filter(
          (article) => article.category.id === payload.categoryId
        );
      }
      if (payload.searchQuery) {
        supplierArticles = supplierArticles.filter((article) =>
          article.name.toLowerCase().includes(payload.searchQuery.toLowerCase())
        );
      }
      state.allArticlesSupplier = supplierArticles;
      state.totalArticlesCount = supplierArticles?.length;
      setTimeout(() => {
        state.isArticleLoading = false;
      }, 500);
    },
    getCustomArticlesSuccess(state, payload) {
      state.isArticleLoading = true;
      let customArticles = state.allCustomArticles.find(
        (customArticles) => customArticles.departmentId === payload.departmentId
      ).articles;
      if (payload.onlyFavorite) {
        customArticles = customArticles.filter((article) => article.isFavorite);
      }
      state.customArticles = customArticles;
      state.allCustomArticlesCount = customArticles.length;
      setTimeout(() => {
        state.isArticleLoading = false;
      }, 500);
    },
    getSupplierNotesSuccess(state, payload) {
      state.selectedSupplierNote = null;
      state.isDataLoading = true;
      const supplier = state.allSuppliersForDepartment.find(
        (supplier) => supplier.id === payload.supplierId
      );
      state.selectedSupplierNote = supplier.supplierNotes;
      setTimeout(() => {
        state.isDataLoading = false;
      }, 1500);
    },
    getSupplierContactsSuccess(state, payload) {
      state.isDataLoading = true;
      const supplier = state.allSuppliersForDepartment.find(
        (supplier) => supplier.id === payload.supplierId
      );
      state.selectedSupplierContacts = supplier.contacts;

      setTimeout(() => {
        state.isDataLoading = false;
      }, 500);
    },
    saveNewOrderSuccess(state, payload) {
      let dataForDepartment =
        payload.departmentId === "d162cffb-acd5-4f8a-94fe-f5e026f99307"
          ? state.ordersForDepartmentOne
          : state.ordersForDepartmentTwo;
      const orderStatusToUpdate = payload.order.status;
      if (orderStatusToUpdate === orderStatuses.IN_PROGRESS) {
        dataForDepartment.ordersInProgress.orders.forEach((order, index) => {
          if (order.id === payload.order.id) {
            dataForDepartment.ordersInProgress[index] = payload.order;
          }
        });
        dataForDepartment.ordersInProgress.allOrdersCombined.forEach(
          (order, index) => {
            if (order.id === payload.order.id) {
              dataForDepartment.ordersInProgress[index] = payload.order;
            }
          }
        );
      } else if (orderStatusToUpdate === orderStatuses.COMPLETED) {
        dataForDepartment.ordersCompleted.orders.forEach((order, index) => {
          if (order.id === payload.order.id) {
            dataForDepartment.ordersCompleted[index] = payload.order;
          }
        });
        dataForDepartment.ordersCompleted.allOrdersCombined.forEach(
          (order, index) => {
            if (order.id === payload.order.id) {
              dataForDepartment.ordersCompleted[index] = payload.order;
            }
          }
        );
      } else if (orderStatusToUpdate === orderStatuses.OPEN) {
        dataForDepartment.ordersOpen.orders.forEach((order, index) => {
          if (order.id === payload.order.id) {
            dataForDepartment.ordersOpen[index] = payload.order;
          }
        });
        dataForDepartment.ordersOpen.allOrdersCombined.forEach(
          (order, index) => {
            if (order.id === payload.order.id) {
              dataForDepartment.ordersOpen[index] = payload.order;
            }
          }
        );
      }
    },
    saveCustomOrderSuccess(state, payload) {
      let dataForDepartment =
        payload.departmentId === "d162cffb-acd5-4f8a-94fe-f5e026f99307"
          ? state.ordersForDepartmentOne
          : state.ordersForDepartmentTwo;
      const orderStatusToUpdate = payload.order.status;

      if (orderStatusToUpdate === orderStatuses.IN_PROGRESS) {
        dataForDepartment.ordersInProgress.customOrders.forEach(
          (order, index) => {
            if (order.id === payload.order.id) {
              dataForDepartment.ordersInProgress[index] = payload.order;
            }
          }
        );
        dataForDepartment.ordersInProgress.allOrdersCombined.forEach(
          (order, index) => {
            if (order.id === payload.order.id) {
              dataForDepartment.ordersInProgress[index] = payload.order;
            }
          }
        );
      } else if (orderStatusToUpdate === orderStatuses.COMPLETED) {
        dataForDepartment.ordersCompleted.customOrders.forEach(
          (order, index) => {
            if (order.id === payload.order.id) {
              dataForDepartment.ordersCompleted[index] = payload.order;
            }
          }
        );
        dataForDepartment.ordersCompleted.allOrdersCombined.forEach(
          (order, index) => {
            if (order.id === payload.order.id) {
              dataForDepartment.ordersCompleted[index] = payload.order;
            }
          }
        );
      } else if (orderStatusToUpdate === orderStatuses.OPEN) {
        dataForDepartment.ordersOpen.customOrders.forEach((order, index) => {
          if (order.id === payload.order.id) {
            dataForDepartment.ordersOpen[index] = payload.order;
          }
        });
        dataForDepartment.ordersOpen.allOrdersCombined.forEach(
          (order, index) => {
            if (order.id === payload.order.id) {
              dataForDepartment.ordersOpen[index] = payload.order;
            }
          }
        );
      }
    },
    createOrderSuccess(state, payload) {
      state.isOrdersDataLoading = true;
      let dataForDepartment =
        payload.departmentID === "d162cffb-acd5-4f8a-94fe-f5e026f99307"
          ? state.ordersForDepartmentOne
          : state.ordersForDepartmentTwo;
      const order = payload.order;
      order.status = orderStatuses.IN_PROGRESS;
      order.products.forEach((article) => {
        article.status = orderStatuses.PENDING;
      });

      order.createdAt = new Date().toISOString();
      order.lastUpdatedAt = new Date().toISOString();

      order.createdBy = {};
      order.createdBy.name = state.user.name;
      order.createdBy.email = state.user.email;

      order.lastUpdatedBy = {};
      order.lastUpdatedBy.name = state.user.name;

      dataForDepartment.ordersInProgress.orders.push(order);
      dataForDepartment.ordersInProgress.allOrdersCombined.push(order);
      dataForDepartment.ordersInProgress.total_order_count++;
      state.departments.forEach((department) => {
        if (department.id === payload.departmentID) {
          department.numberOfExpectedOrders++;
        }
      });
      setTimeout(() => {
        state.isOrdersDataLoading = true;
      }, 300);
    },
    createNewOrderSuccess(state, payload) {
      state.isOrdersDataLoading = true;
      let dataForDepartment =
        payload.departmentID === "d162cffb-acd5-4f8a-94fe-f5e026f99307"
          ? state.ordersForDepartmentOne
          : state.ordersForDepartmentTwo;
      const order = payload.order;
      order.status = orderStatuses.OPEN;
      order.products.forEach((article) => {
        article.status = orderStatuses.PENDING;
      });

      order.createdAt = new Date().toISOString();
      order.lastUpdatedAt = new Date().toISOString();

      order.createdBy = {};
      order.createdBy.name = state.user.name;
      order.createdBy.email = state.user.email;

      order.lastUpdatedBy = {};
      order.lastUpdatedBy.name = state.user.name;

      const ordersOpen = JSON.parse(
        JSON.stringify(dataForDepartment.ordersOpen)
      );
      ordersOpen.orders.push(order);
      ordersOpen.allOrdersCombined.push(order);
      ordersOpen.total_order_count++;
      dataForDepartment.ordersOpen = ordersOpen;
      state.departments.forEach((department) => {
        if (department.id === payload.departmentID) {
          department.numberOfOpenOrders++;
        }
      });
      setTimeout(() => {
        state.isOrdersDataLoading = true;
      }, 300);
    },
    createCustomOrderSuccess(state, payload) {
      let dataForDepartment =
        payload.departmentID === "d162cffb-acd5-4f8a-94fe-f5e026f99307"
          ? state.ordersForDepartmentOne
          : state.ordersForDepartmentTwo;
      const order = payload.order;
      order.status = orderStatuses.IN_PROGRESS;
      order.products.forEach((article) => {
        article.status = orderStatuses.PENDING;
      });

      order.createdAt = new Date().toISOString();
      order.lastUpdatedAt = new Date().toISOString();

      order.createdBy = {};
      order.createdBy.name = state.user.name;
      order.createdBy.email = state.user.email;

      order.lastUpdatedBy = {};
      order.lastUpdatedBy.name = state.user.name;

      state.departments.forEach((department) => {
        if (department.id === payload.departmentID) {
          department.numberOfExpectedOrders++;
        }
      });
      dataForDepartment.ordersInProgress.customOrders.push(order);
      dataForDepartment.ordersInProgress.allOrdersCombined.push(order);
      dataForDepartment.ordersInProgress.total_custom_order_count++;
    },
    createNewCustomOrderSuccess(state, payload) {
      let dataForDepartment =
        payload.departmentID === "d162cffb-acd5-4f8a-94fe-f5e026f99307"
          ? state.ordersForDepartmentOne
          : state.ordersForDepartmentTwo;
      const order = payload.order;
      order.status = orderStatuses.OPEN;
      order.products.forEach((article) => {
        article.status = orderStatuses.PENDING;
      });

      order.createdAt = new Date().toISOString();
      order.lastUpdatedAt = new Date().toISOString();

      order.createdBy = {};
      order.createdBy.name = state.user.name;
      order.createdBy.email = state.user.email;

      order.lastUpdatedBy = {};
      order.lastUpdatedBy.name = state.user.name;

      state.departments.forEach((department) => {
        if (department.id === payload.departmentID) {
          department.numberOfOpenOrders++;
        }
      });
      dataForDepartment.ordersOpen.customOrders.push(order);
      dataForDepartment.ordersOpen.allOrdersCombined.push(order);
      dataForDepartment.ordersOpen.total_custom_order_count++;
    },
    sendOrderSuccess(state, payload) {
      state.isOrdersDataLoading = true;
      setTimeout(() => {
        state.isOrdersDataLoading = false;
      }, 500);
    },
    sendCustomOrderSuccess(state, payload) {
      state.isOrdersDataLoading = true;
      setTimeout(() => {
        state.isOrdersDataLoading = false;
      }, 500);
    },
    toggleFavoriteArticleSuccess(state, payload) {
      state.isArticleLoading = true;
      let supplierArticles = JSON.parse(
        JSON.stringify(state.allArticlesSupplier)
      );

      supplierArticles.forEach((article) => {
        if (article.id === payload.articleId) {
          article.isFavorite = !article.isFavorite;
        }
      });
      setTimeout(() => {
        state.isArticleLoading = false;
      }, 500);
    },
    toggleCustomArticleFavoriteSuccess(state, payload) {
      const customArticles = JSON.parse(JSON.stringify(state.customArticles));
      customArticles.forEach((article) => {
        if (article.id === payload.articleId) {
          article.isFavorite = !article.isFavorite;
        }
      });
      state.customArticles = customArticles;
    },

    addNewArticleSuccess(state, payload) {
      let allCustomArticlesForDepartment = state.allCustomArticles.find(
        (customArticleDepartment) =>
          customArticleDepartment.departmentId === payload.departmentId
      ).articles;

      const newArticle = {
        name: payload.articleName,
        storeName: payload.storeName,
        note: payload.note,
        category: payload.categoryName,
        defaultPrice: payload.price,
      };
      allCustomArticlesForDepartment.push(newArticle);
    },
    deleteCustomArticleSuccess(state, payload) {
      let allCustomArticlesForDepartment = state.allCustomArticles.find(
        (customArticleDepartment) =>
          customArticleDepartment.departmentId === payload.departmentId
      ).articles;

      allCustomArticlesForDepartment.forEach((article) => {
        if (article.id === payload.articleId) {
          article.isSoftDeleted = true;
        }
      });
    },
    editCustomArticleSuccess(state, payload) {
      let allCustomArticlesForDepartment = state.allCustomArticles.find(
        (customArticleDepartment) =>
          customArticleDepartment.departmentId === payload.departmentId
      ).articles;

      allCustomArticlesForDepartment.forEach((article) => {
        if (article.id === payload.articleId) {
          article.name = payload.article.articleName;
          article.storeName = payload.article.storeName;
          article.note = payload.article.note;
          article.category = payload.article.category;
          article.defaultPrice = payload.article.price;
        }
      });
    },
    updateContactSupplierSuccess(state, payload) {
      const supplier = state.allSuppliersForDepartment.find(
        (supplier) => supplier.id === payload.supplierId
      );
      supplier.contacts.forEach((contact) => {
        if (contact.id === payload.id) {
          contact.name = payload.name;
          contact.email = payload.email;
          contact.phone = payload.phone;
        }
      });
    },
    deleteContactSupplierSuccess(state, payload) {
      const supplier = state.allSuppliersForDepartment.find(
        (supplier) => supplier.id === payload.supplierId
      );
      supplier.contacts.forEach((contact) => {
        if (contact.id === payload.id) {
          contact.isSoftDeleted = true;
        }
      });
    },
    updateOrderSuccess(state, payload) {
      state.isArticleLoading = true;
      let dataForDepartment =
        payload.departmentId === "d162cffb-acd5-4f8a-94fe-f5e026f99307"
          ? state.ordersForDepartmentOne
          : state.ordersForDepartmentTwo;
      const orderStatusToUpdate = payload.order.status;
      payload.order.lastUpdatedAt = new Date().toISOString();
      if (orderStatusToUpdate === orderStatuses.IN_PROGRESS) {
        dataForDepartment.ordersInProgress.orders.forEach((order, index) => {
          if (order.id === payload.orderId) {
            dataForDepartment.ordersInProgress.orders[index] = payload.order;
          }
        });
        dataForDepartment.ordersInProgress.allOrdersCombined.forEach(
          (order, index) => {
            if (order.id === payload.orderId) {
              dataForDepartment.ordersInProgress.allOrdersCombined[index] =
                payload.order;
            }
          }
        );
      } else if (orderStatusToUpdate === orderStatuses.COMPLETED) {
        dataForDepartment.ordersCompleted.orders.forEach((order, index) => {
          if (order.id === payload.orderId) {
            dataForDepartment.ordersCompleted.orders[index] = payload.order;
          }
        });
        dataForDepartment.ordersCompleted.allOrdersCombined.forEach(
          (order, index) => {
            if (order.id === payload.orderId) {
              dataForDepartment.ordersCompleted.allOrdersCombined[index] =
                payload.order;
            }
          }
        );
      } else if (orderStatusToUpdate === orderStatuses.OPEN) {
        dataForDepartment.ordersOpen.orders.forEach((order, index) => {
          if (order.id === payload.orderId) {
            dataForDepartment.ordersOpen.orders[index] = payload.order;
          }
        });
        dataForDepartment.ordersOpen.allOrdersCombined.forEach(
          (order, index) => {
            if (order.id === payload.orderId) {
              dataForDepartment.ordersOpen.allOrdersCombined[index] =
                payload.order;
            }
          }
        );
      }
      setTimeout(() => {
        state.isArticleLoading = false;
      }, 500);
    },
    updateCustomOrderSuccess(state, payload) {
      state.isArticleLoading = true;
      let dataForDepartment =
        payload.departmentId === "d162cffb-acd5-4f8a-94fe-f5e026f99307"
          ? state.ordersForDepartmentOne
          : state.ordersForDepartmentTwo;
      const orderStatusToUpdate = payload.order.status;
      payload.order.lastUpdatedAt = new Date().toISOString();
      if (orderStatusToUpdate === orderStatuses.IN_PROGRESS) {
        dataForDepartment.ordersInProgress.customOrders.forEach(
          (order, index) => {
            if (order.id === payload.orderId) {
              dataForDepartment.ordersInProgress.customOrders[index] =
                payload.order;
            }
          }
        );
        dataForDepartment.ordersInProgress.allOrdersCombined.forEach(
          (order, index) => {
            if (order.id === payload.orderId) {
              dataForDepartment.ordersInProgress.allOrdersCombined[index] =
                payload.order;
            }
          }
        );
      } else if (orderStatusToUpdate === orderStatuses.COMPLETED) {
        dataForDepartment.ordersCompleted.customOrders.forEach(
          (order, index) => {
            if (order.id === payload.orderId) {
              dataForDepartment.ordersCompleted.customOrders[index] =
                payload.order;
            }
          }
        );
        dataForDepartment.ordersCompleted.allOrdersCombined.forEach(
          (order, index) => {
            if (order.id === payload.orderId) {
              dataForDepartment.ordersCompleted.allOrdersCombined[index] =
                payload.order;
            }
          }
        );
      } else if (orderStatusToUpdate === orderStatuses.OPEN) {
        dataForDepartment.ordersOpen.customOrders.forEach((order, index) => {
          if (order.id === payload.orderId) {
            dataForDepartment.ordersOpen.customOrders[index] = payload.order;
          }
        });
        dataForDepartment.ordersOpen.allOrdersCombined.forEach(
          (order, index) => {
            if (order.id === payload.orderId) {
              dataForDepartment.ordersOpen.allOrdersCombined[index] =
                payload.order;
            }
          }
        );
      }
      setTimeout(() => {
        state.isArticleLoading = false;
      }, 500);
    },
    disableTourSuccess(state, payload) {
      LocalStorageService.setLocalStorageData("tourDisabled", payload);
      state.isTourDisabled = payload;
    },
    addContactSupplierSuccess(state, payload) {
      const supplier = state.allSuppliersForDepartment.find(
        (supplier) => supplier.id === payload.id
      );
      supplier.contacts.push(payload.data);
    },
    addSupplierNotesSuccess(state, payload) {
      const supplier = state.allSuppliersForDepartment.find(
        (supplier) => supplier.id === payload.supplierId
      );
      const updatedAt = new Date().toISOString();
      supplier.supplierNotes = {
        daysInWeek: JSON.stringify(payload.daysInWeek),
        note: payload.note,
        timeToDeliver: payload.timeToDeliver,
        timeToOrder: payload.timeToOrder,
        updatedAt: updatedAt,
      };
    },
    deleteOrderSuccess(state, payload) {
      let dataForDepartment =
        payload.departmentId === "d162cffb-acd5-4f8a-94fe-f5e026f99307"
          ? state.ordersForDepartmentOne
          : state.ordersForDepartmentTwo;

      dataForDepartment.ordersOpen.orders.forEach((order, index) => {
        if (order.id === payload.orderId) {
          dataForDepartment.ordersOpen.orders.splice(index, 1);
        }
      });
      dataForDepartment.ordersOpen.allOrdersCombined.forEach((order, index) => {
        if (order.id === payload.orderId) {
          dataForDepartment.ordersOpen.allOrdersCombined.splice(index, 1);
        }
      });
    },
    deleteCustomOrderSuccess(state, payload) {
      let dataForDepartment =
        payload.departmentId === "d162cffb-acd5-4f8a-94fe-f5e026f99307"
          ? state.ordersForDepartmentOne
          : state.ordersForDepartmentTwo;

      dataForDepartment.ordersOpen.customOrders.forEach((order, index) => {
        if (order.id === payload.orderId) {
          dataForDepartment.ordersOpen.customOrders.splice(index, 1);
        }
      });
      dataForDepartment.ordersOpen.allOrdersCombined.forEach((order, index) => {
        if (order.id === payload.orderId) {
          dataForDepartment.ordersOpen.allOrdersCombined.splice(index, 1);
        }
      });
    },
    completeOrderSuccess(state, payload) {
      let dataForDepartment =
        payload.departmentId === "d162cffb-acd5-4f8a-94fe-f5e026f99307"
          ? state.ordersForDepartmentOne
          : state.ordersForDepartmentTwo;

      dataForDepartment.ordersInProgress.orders.forEach((order, index) => {
        if (order.id === payload.orderId) {
          dataForDepartment.ordersInProgress.orders.splice(index, 1);
        }
      });
      dataForDepartment.ordersInProgress.allOrdersCombined.forEach(
        (order, index) => {
          if (order.id === payload.orderId) {
            dataForDepartment.ordersInProgress.allOrdersCombined.splice(
              index,
              1
            );
          }
        }
      );
      const updatedOrder = payload.order;
      updatedOrder.status = orderStatuses.COMPLETED;
      updatedOrder.products = payload.products;
      updatedOrder.lastUpdatedBy.name = state.user.name;
      updatedOrder.lastUpdatedBy.email = state.user.email;
      updatedOrder.lastUpdatedAt = new Date().toISOString();
      dataForDepartment.ordersCompleted.orders.push(updatedOrder);
      dataForDepartment.ordersCompleted.allOrdersCombined.push(updatedOrder);
    },
    setSelectedLanguageSuccess(state, payload) {
      state.user.language = payload;
      LocalStorageService.setLocalStorageData("language", payload);
    },
  },
  getters: {
    getVenue: (state) => {
      return state.venue;
    },
    getDepartments: (state) => {
      return state.departments;
    },
    getAllOrdersState: (state) => {
      return state.allOrders;
    },
    getTotalOrdersCount: (state) => {
      return state.totalOrdersCount + state.totalCustomOrdersCount;
    },
    getAllOrdersInProgressDueDate: (state) => {
      return state.allOrdersInProgressAndDueDate;
    },
    getDepartmentSuppliers: (state) => {
      return state.allSuppliersForDepartment;
    },
    getAllArticlesCategories: (state) => {
      return state.articleCategoriesForSupplier;
    },
    getAllCategories: (state) => {
      return state.allCategories;
    },
    getAllSupplierArticles: (state) => {
      return state.allArticlesSupplier;
    },
    getTotalArticlesCount: (state) => {
      return state.totalArticlesCount;
    },
    getAllCustomArticles: (state) => {
      return state.customArticles;
    },
    getAllCustomArticlesCount: (state) => {
      return state.allCustomArticlesCount;
    },

    getSelectedSupplierNote: (state) => {
      return state.selectedSupplierNote;
    },
    getAllArticles: (state) => {
      return state.allArticlesSupplier;
    },
    allSupplierContacts: (state) => {
      return state?.selectedSupplierContacts;
    },
    getUser: (state) => {
      return state.user;
    },
    getLanguageSelected: (state) => {
      return state.user.language;
    },
    getNumberOfOpenOrdersForDepartmentOne: (state) => {
      return (
        state.ordersForDepartmentOne.ordersOpen.orders.length +
        state.ordersForDepartmentOne.ordersOpen.customOrders.length
      );
    },
    getNumberOfOrdersInProgressForDepartmentOne: (state) => {
      return (
        state.ordersForDepartmentOne.ordersInProgress.orders.length +
        state.ordersForDepartmentOne.ordersInProgress.customOrders.length
      );
    },
    getNumberOfOpenOrdersForDepartmentTwo: (state) => {
      return (
        state.ordersForDepartmentTwo.ordersOpen.orders.length +
        state.ordersForDepartmentTwo.ordersOpen.customOrders.length
      );
    },
    getNumberOfOrdersInProgressForDepartmentTwo: (state) => {
      return (
        state.ordersForDepartmentTwo.ordersInProgress.orders.length +
        state.ordersForDepartmentTwo.ordersInProgress.customOrders.length
      );
    },
    getIsOrdersDataLoading: (state) => {
      return state.isOrdersDataLoading;
    },
    getIsArticleLoading: (state) => {
      return state.isArticleLoading;
    },
    getIsDataLoading: (state) => {
      return state.isDataLoading;
    },
    getIsTourDisabled: (state) => {
      return state.isTourDisabled;
    },
  },
};
