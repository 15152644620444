<template>
  <v-data-table
    :headers="headers"
    :items="getData"
    :sort-by="['name']"
    :sort-desc="[false, true]"
    class="elevation-1"
    :loading="isDataLoading"
    :loading-text="$t('common.loadingTableText')"
    :mobile-breakpoint="mobileBreakpoint || 320"
    :items-per-page="currentPageSize || 10"
    :footer-props="{
      itemsPerPageAllText: $t('common.all'),
      itemsPerPageText: $t('common.itemsPerPage'),
      itemsPerPageOptions: [10, 20, 30],
    }"
    :server-items-length="totalCount"
    :options.sync="options"
    @click:row="onRowClick"
    @update:page="handlePageChange"
    @update:items-per-page="handleItemsPerPageChange"
  >
    <template v-slot:item.deadline="{ item }">
      <span
        class="text-body-1 accent--text order-deadline"
        :class="{ statusMobileText: getIsMobile }"
        >{{ getFormattedDate(item.deadline) }}</span
      >
    </template>

    <template v-slot:item.statusText="{ item }">
      <span
        v-if="item.status === 'OPEN'"
        class="tag-style text-body-1 negative--text"
        :class="{ statusMobileText: getIsMobile }"
        >{{ item.statusText }}</span
      >
      <span
        v-else-if="item.status === 'IN_PROGRESS'"
        class="tag-style text-body-1 positive1--text"
        :class="{ statusMobileText: getIsMobile }"
        >{{ item.statusText }}</span
      >

      <div class="d-flex align-start" v-else>
        <span
          class="tag-style text-body-1 accent--text"
          :class="{ statusMobileText: getIsMobile }"
          >{{ item.statusText }}</span
        >
        <v-icon v-if="isPartiallyDelivered(item)" color="primary" dark>
          mdi-message-alert
        </v-icon>
      </div>
    </template>
    <!-- current orders -->
    <template v-slot:item.supplierOrders="{ item }">
      <span :class="{ statusMobileText: getIsMobile }">
        {{ item.isCustomOrder ? $t("articles.market") : item.supplier.name }}
      </span>
    </template>
    <template v-slot:item.options="{ item }">
      <v-menu bottom left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn id="step-8" icon v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item v-for="(opt, i) in optionsItems" :key="opt.value + i">
            <v-list-item-title
              :class="{
                disabled:
                  opt.value === 'edit' ||
                  (item.status !== 'IN_PROGRESS' &&
                    item.status !== 'INCOMPLETE' &&
                    opt.value === 'receive') ||
                  opt.value === 'repeat' ||
                  (item.status === 'OPEN' && opt.value === 'repeat') ||
                  (item.status === 'OPEN' && opt.value === 'copyOrderSMS') ||
                  (item.status !== 'OPEN' && opt.value === 'delete') ||
                  (opt.value === 'callSupplier' && !item.supplierContactNumber),
              }"
              class="option-item"
              @click="handleOptionsSelection(opt.value, item)"
              >{{ opt.title }}</v-list-item-title
            >
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
    <!-- current orders -->
    <!-- articles list data table -->
    <template v-slot:item.name="{ item }">
      <span>{{ item && item.name }}</span>
    </template>
    <template v-slot:item.__category__="{ item }">
      <span>{{ item && item.category && item.category.name }}</span>
    </template>
    <template v-slot:item.supplierName="{ item }">
      <span>{{ item && item.supplier && item.supplier.name }}</span>
    </template>
    <template v-slot:item.checkbox_action="{ item }">
      <v-checkbox
        @change="handleArticleSelection(item)"
        v-model="item.enabled"
        color="positive1"
      ></v-checkbox>
    </template>
    <template v-slot:item.defaultPrice="{ item }">
      <v-text-field
        class="price-input"
        :placeholder="$t('articles.enterPrice')"
        v-model.lazy="item.defaultPrice"
        type="number"
        solo
        @keyup="onPriceChangedUp($event, item)"
        @keydown="onPriceChangedDown($event, item)"
      ></v-text-field>
    </template>
    <!-- articles list data table -->
    <!-- suppliers data table -->
    <template v-slot:item.supplersName="{ item }">
      <span>{{ item.name }}</span>
    </template>
    <template v-slot:item.add_contact="{ item }">
      <div class="d-flex">
        <v-btn
          id="step-27"
          v-if="!item.isMarket"
          class="mx-2"
          fab
          dark
          color="accent"
          height="35px"
          width="35px"
          @click="addContact(item)"
        >
          <v-icon dark> mdi-plus </v-icon>
        </v-btn>
        <v-btn
          id="step-28"
          v-if="!item.isMarket"
          class="mx-2"
          fab
          dark
          color="warning"
          height="35px"
          width="35px"
          @click="previewContacts(item)"
        >
          <v-icon dark> mdi-format-list-bulleted-square </v-icon>
        </v-btn>
        <v-btn
          id="step-29"
          class="mx-2"
          fab
          dark
          color="positive1"
          height="35px"
          width="35px"
          @click="previewSupplieNotes(item)"
        >
          <v-icon dark> mdi-information-outline </v-icon>
        </v-btn>
      </div>
    </template>
    <!-- suppliers data table -->
  </v-data-table>
</template>
<script>
//services
// import EventBus from "../shared/EventBus";
import { formatDate } from "../helpers/helper";
import TooltipComponent from "../components/TooltipComponent.vue";
import { mapGetters } from "vuex";
export default {
  components: { TooltipComponent },
  props: {
    headers: {},
    data: {},
    isDataLoading: {},
    archive: {},
    pageType: {},
    getIsMobile: {},
    mobileBreakpoint: {},
    isCurrentOrders: {},
    currentPage: {},
    currentPageSize: {},
    totalCount: {},
  },
  name: "DataTable",
  data: () => ({
    loader: null,
    selectedSupplierId: null,
    optionsItems: [],
    typingTimer: null,
    doneTypingInterval: 1500,
    options: {},
  }),
  created() {
    this.setOptionsItems();
  },

  methods: {
    setOptionsItems() {
      this.optionsItems = [
        {
          id: 0,
          title: this.$t("currentOrders.edit"),
          value: "edit",
        },
        {
          id: 1,
          title: this.$t("currentOrders.delete"),
          value: "delete",
        },
        {
          id: 2,
          title: this.$t("currentOrders.view"),
          value: "view",
        },
        {
          id: 3,
          title: this.$t("currentOrders.receiveOrder"),
          value: "receive",
        },
        {
          id: 4,
          title: this.$t("currentOrders.repeatOrder"),
          value: "repeat",
        },
        {
          id: 5,
          title: this.$t("currentOrders.copySMS"),
          value: "copyOrderSMS",
        },
        {
          id: 6,
          title: this.$t("currentOrders.callSupplier"),
          value: "callSupplier",
        },
      ];
    },
    receiveOrder(item) {
      this.$emit("onReceiveOrder", item);
    },
    handleArticleSelection(item) {
      this.$emit("onSelectedArticles", item);
    },
    selectRowId(item) {
      this.selectedSupplierId = item.id;
    },

    handleOptionsSelection(option, value) {
      switch (option) {
        case "edit":
          value.isCustomOrder
            ? this.$store.dispatch("orders/setCustomOrderToEdit", value)
            : this.$store.dispatch("orders/setCustomOrderToEdit", null);
          this.$router.push(
            `/${this.$route.params.departmentID}/edit-order/${value.id}`
          );
          break;
        case "delete":
          this.$emit("onDeleteOrder", value);
          break;
        case "view":
          this.$emit("onPreviewOrderItem", value);
          break;
        case "receive":
          this.receiveOrder(value);
          break;
        case "repeat":
          this.repeatOrder(value);
          break;
        case "copyOrderSMS":
          this.$emit("onCopySMSOrder", value);
          break;
        case "callSupplier":
          document.location.href = "tel:" + value.supplierContactNumber;
          break;
      }
    },
    addContact(supplier) {
      this.$emit("onAddContact", supplier);
    },
    previewContacts(supplier) {
      this.$emit("onPreviewContacts", supplier);
    },
    onPriceChangedUp(event, item) {
      clearTimeout(this.typingTimer);
      this.typingTimer = setTimeout(
        () => this.doneTyping(event, item),
        this.doneTypingInterval
      );
    },
    onPriceChangedDown() {
      clearTimeout(this.typingTimer);
    },
    doneTyping(event, item) {
      this.$emit("onArticlePriceUpdate", {
        newPrice: event.target.value,
        item,
      });
    },
    getFormattedDate(value) {
      return formatDate(value);
    },
    repeatOrder(order) {
      this.$emit("onRepeatOrder", order);
    },
    previewSupplieNotes(supplier) {
      this.$emit("onPreviewSupplierNotes", supplier);
    },
    onRowClick(item) {
      if (this.isCurrentOrders) {
        this.$emit("onPreviewOrderItem", item);
      }
    },
    isPartiallyDelivered(item) {
      return item.products.filter((p) => p.deliveredAmount).length > 0;
    },
    handlePageChange(event) {
      this.$emit("onPageChange", event);
    },
    handleItemsPerPageChange(event) {
      this.$emit("itemsPerPageChange", event);
    },
  },
  computed: {
    ...mapGetters({
      user: "allState/getUser",
    }),
    getData() {
      return this.data;
    },
  },
  watch: {
    user: {
      handler() {
        this.setOptionsItems();
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.tag-style {
  background: #f7f7f8;
  padding: 8px 4px;
  text-transform: uppercase;
  @media screen and (max-width: 959px) {
    font-size: 14px !important;
  }
}
.remove-uppercase {
  span {
    text-decoration: none !important;
  }
}
.price-input {
  max-width: 130px;
  min-width: 60px;
  position: relative;
  top: 15px;
}

.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
.option-item {
  cursor: pointer;
}
.order-deadline {
  @media screen and (max-width: 959px) {
    font-size: 14px !important;
  }
}
.uploadedYes {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #35d2bc;
  display: flex;
  justify-content: center;
  align-items: center;
}
.uploadedNo {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #fd4a34;
  display: flex;
  justify-content: center;
  align-items: center;
}
.disabled {
  pointer-events: none;
  opacity: 0.3;
}
.statusMobileText {
  font-size: 12px !important;
}
</style>
